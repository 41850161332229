import React from "react";
import MaterialButton from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import MaterialTheme from "./MaterialTheme";

type StyledButtonProps = {
  bgstyle: "primary" | "secondary" | "tertiary";
  hoverstyle: "dark" | "light";
  size: "small" | "medium" | "large";
  width?: string;
};

type ButtonProps = {
  size: "small" | "medium" | "large";
  bgStyle: "primary" | "secondary" | "tertiary";
  hoverStyle: "dark" | "light";
  startIcon?: React.ReactNode;
  endIcon?: React.ReactNode;
  children?: React.ReactNode;
  width?: string;
  onClick?: () => void;
  disabled?: boolean;
};

const theme = MaterialTheme;

const StyledButton = styled(MaterialButton)<StyledButtonProps>(
  ({ size, bgstyle, hoverstyle, width, disabled }) => ({
    width: width ? width : "fit-content",
    boxShadow: theme.shadows[4],
    border: `solid 1px ${
      bgstyle === "primary"
        ? theme.palette.primary.light
        : bgstyle === "secondary"
          ? theme.palette.primary.main
          : theme.palette.secondary.main
    }`,
    backgroundColor:
      bgstyle === "primary"
        ? theme.palette.primary.light
        : bgstyle === "secondary"
          ? theme.palette.primary.main
          : theme.palette.secondary.main,
    color:
      bgstyle === "secondary"
        ? theme.palette.secondary.main
        : theme.palette.common.white,
    padding:
      size === "small"
        ? "5px 10px"
        : size === "medium"
          ? "10px 20px"
          : "15px 30px",
    fontSize: size === "small" ? "12px" : size === "medium" ? "14px" : "16px",
    "&:hover": disabled
      ? {
          backgroundColor: theme.palette.primary.main,
        }
      : {
          backgroundColor:
            hoverstyle === "dark" ? theme.palette.primary.main : "#fff",
          color: hoverstyle === "dark" ? "#fff" : theme.palette.primary.main,
          border: "solid 1px gray",
        },
    "&:disabled": {
      cursor: "not-allowed",
      pointerEvents: "all",
      filter: "grayscale(0.4)",
      color: "white",
    },
  })
);

const Button: React.FC<ButtonProps> = ({
  size,
  bgStyle,
  hoverStyle,
  startIcon,
  endIcon,
  width,
  children,
  onClick,
  disabled,
  ...otherProps
}) => {
  return (
    <StyledButton
      {...otherProps}
      size={size}
      bgstyle={bgStyle}
      hoverstyle={hoverStyle}
      startIcon={startIcon}
      endIcon={endIcon}
      width={width}
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </StyledButton>
  );
};

export default Button;
