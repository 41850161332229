import { Box, Grid, Stack, Typography, useMediaQuery } from '@mui/material';
import React from 'react';
import Button from './button';
import MaterialTheme from './MaterialTheme';
import { useNavigate } from 'react-router-dom';

const DuoLinks = () => {
  const theme1 = MaterialTheme;
  const isSm = useMediaQuery(theme1.breakpoints.only('sm'));
  const navigate = useNavigate();
  const handleOpenNewTab = () => {
    // Specify the URL you want to open in the new tab or window
    const newTabURL = '/joinus';

    // Use window.open() to open a new tab or window
    window.open(newTabURL, '_blank');
  };
  return (
    <Stack>
      <Grid
        container
        spacing={{ xs: 6, sm: 2, md: 2, lg: 4, xl: 4 }}
        height={{
          xs: '100vh',
          sm: '360px',
          md: '400px',
          lg: '500px',
          xl: '500px',
        }}
      >
        <Grid item xs={12} sm={6} height={{ xs: '50%', sm: '100%' }}>
          <Stack height={'100%'} alignItems={'center'} spacing={4}>
            <Box
              sx={{
                backgroundImage: 'url(/images/join-the-team.png)',
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
              }}
              borderRadius={2}
              height={'80%'}
              width={'80%'}
            ></Box>
            <Button
              size='large'
              bgStyle='secondary'
              hoverStyle='light'
              onClick={() => handleOpenNewTab()}
            >
              <Typography
                variant={isSm ? 'body1' : 'h3'}
                fontWeight={'bold'}
                align='center'
              >
                Join the effort
              </Typography>
            </Button>
          </Stack>
        </Grid>
        <Grid item xs={12} sm={6} height={{ xs: '50%', sm: '100%' }}>
          <Stack height={'100%'} alignItems={'center'} spacing={4}>
            <Box
              sx={{
                backgroundImage: 'url(/images/support-us.png)',
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
              }}
              borderRadius={2}
              height={'80%'}
              width={'80%'}
            ></Box>
            <Button
              size='large'
              bgStyle='secondary'
              hoverStyle='light'
              onClick={() =>
                navigate('/donate', {
                  replace: true,
                })
              }
            >
              <Typography variant={isSm ? 'body1' : 'h3'} fontWeight={'bold'}>
                Support our work
              </Typography>
            </Button>
          </Stack>
        </Grid>
      </Grid>
    </Stack>
  );
};

export default DuoLinks;
