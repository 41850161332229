import { Endpoints } from "../data/endpoints";
import { SearchRes } from "../utils/fetchData";
import useSWR, { KeyedMutator } from "swr";
import { supabase, User } from "../utils/auth";
import { useSession } from "@supabase/auth-helpers-react";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { enqueueSnackbar } from "notistack";

export const useResults = (query: string) => {
  return useSWR<SearchRes>("/search" + query, {
    refreshInterval: 0,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });
};

export const useMe = () => {
  const session = useSession();
  const navigate = useNavigate();
  const { data, isLoading, error, mutate } = useSWR<User | null>("/me");

  const logout = useCallback(() => {
    supabase.auth.signOut().then(() => {
      enqueueSnackbar("Succesfully logged out", { variant: "success" });
      mutate(null);
      navigate("/");
    });
  }, [navigate, mutate]);

  return { data, isLoading, error, mutate, logout };
};
