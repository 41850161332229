import React, { useEffect } from "react";
import { supabase } from "../utils/auth";
import MetaDataHeader from "../components/MetaDataHeader";
import { Stack, TextField, Typography } from "@mui/material";
import Button from "../components/button";
import { isValidEmail } from "../utils/utils";
import theme from "../components/MaterialTheme";
import { useNavigate } from "react-router-dom";
import { enqueueSnackbar } from "notistack";

const UpdatePassword = () => {
  const navigate = useNavigate();
  const [password, setPassword] = React.useState("");
  const [confirmPassword, setConfirmPassword] = React.useState("");
  const [hidden, setHidden] = React.useState(true);
  const [error, setError] = React.useState<string | null>(null);

  const resetPassword = async () => {
    const { data, error } = await supabase.auth.updateUser({
      password,
    });
    if (error) {
      setError(error.message);
    } else {
      enqueueSnackbar("Password updated", { variant: "success" });
      navigate("/");
    }
  };

  useEffect(() => {
    if (password === confirmPassword) {
      setError(null);
    } else {
      setError("Passwords do not match");
    }
    if (password.length === 0 || confirmPassword.length === 0) {
      setError(null);
    }
  }, [password, confirmPassword]);

  useEffect(() => {
    supabase.auth.onAuthStateChange(async (event, session) => {
      if (event === "PASSWORD_RECOVERY") {
        setHidden(false);
      } else {
        if (!session) {
          navigate("/forgot_password");
        }
      }
    });
  }, []);

  return (
    <>
      <MetaDataHeader title="Reset your password" />
      <Stack
        sx={{
          height: "calc(100vh - 75px)",
        }}
        alignItems={"center"}
        justifyContent={"center"}
      >
        <Stack
          sx={{
            width: { xs: "100%", sm: "24rem" },
            p: { xs: 2, sm: 4 },
            boxShadow: {
              xs: "none",
              sm: "0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1)",
            },
            borderRadius: "12px",
          }}
          spacing={2}
          textAlign={"center"}
          boxSizing={"border-box"}
        >
          <Typography variant={"h2"}>Reset your password</Typography>
          <TextField
            variant="outlined"
            placeholder="New Password"
            fullWidth={true}
            value={password}
            type={"password"}
            onChange={(e) => {
              setPassword(e.target.value);
              setConfirmPassword("");
            }}
            sx={{
              borderRadius: "10px",
              backgroundColor: "#fff",
              input: { color: "black" },
            }}
            error={!!error}
          />
          <TextField
            variant="outlined"
            placeholder="Confirm New Password"
            fullWidth={true}
            value={confirmPassword}
            type={"password"}
            onChange={(e) => {
              setConfirmPassword(e.target.value);
            }}
            sx={{
              borderRadius: "10px",
              backgroundColor: "#fff",
              input: { color: "black" },
            }}
            error={!!error}
          />
          {error && (
            <Typography color={theme.palette.error.main} fontSize={16}>
              {error}
            </Typography>
          )}
          <Button
            size={"large"}
            bgStyle={"primary"}
            hoverStyle={"dark"}
            width={"100%"}
            onClick={resetPassword}
            disabled={confirmPassword !== password}
          >
            Change Password
          </Button>
        </Stack>
      </Stack>
    </>
  );
};

export default UpdatePassword;
