import { 
    Stack,
    Typography, 
 } from '@mui/material';
import React from 'react';
import TestimonialCarousel from './testimonial-carousel';

export const WhyVolunteer: React.FC = () => {

	return (
        <Stack width={'100%'} alignItems={'center'}>
        <Stack
          width={'100%'}
          alignItems={'center'}
          bgcolor={'rgb(255, 253, 208, 0.5)'}
          py={5}
        >
          <Typography variant='h1'>Why Volunteer With Us?</Typography>
        </Stack>
        <Stack
          width={'100%'}
          alignItems={'center'}
          bgcolor={'rgb(255, 253, 208, 0.5)'}
          pb={8}
        >
          <TestimonialCarousel />
        </Stack>
      </Stack>
	);
};