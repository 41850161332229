import MetaDataHeader from "../components/MetaDataHeader";
import React, { useState, useEffect } from "react";
import {
  Stack,
  Typography,
  Tabs,
  Tab,
  Paper,
  Button,
  TextField,
  Divider,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Chip,
  CircularProgress,
  Box,
  Tooltip,
  Collapse,
  IconButton,
} from "@mui/material";
import { useMe, useResults } from "../hooks/useResults";
import { Navigate, useNavigate, useSearchParams } from "react-router-dom";
import SearchResult from "../components/SearchResult";
import { supabase } from "../utils/auth";
import { User } from "../utils/auth";
import dayjs from "dayjs";
import {
  CloseOutlined,
  DeleteOutline,
  EditOutlined,
  ExpandLess,
  ExpandMore,
} from "@mui/icons-material";
import { isValidEmail } from "../utils/utils";
import { AuthError } from "@supabase/supabase-js";
import { enqueueSnackbar } from "notistack";
import { fetcher } from "../utils/fetchData";
import { AddressInput } from "./SignUp";
import { Ellipsis } from "../components/Ellipsis";
import { AccountAvatar } from "../components/navigation-bar";

const FavoritesTab = (props: { savedVOs: User["saved"] }) => {
  const { savedVOs } = props;
  const { data: me } = useMe();

  if (!me) {
    return null;
  }

  const rawQuery = new URLSearchParams({
    keywords: `Interests: ${me.interests_text} (${me.interests.join(", ")})\nSkills: ${me.skills_text} (${me.skills.join(", ")})`,
    location: `${me.city}, ${me.state}`,
    distance: "20",
  });

  const query = "?" + rawQuery.toString();

  return (
    <Stack
      sx={{
        width: { xs: "auto", md: "48rem", lg: "60rem" },
      }}
      spacing={3}
      alignItems="center"
    >
      <Recommendations query={query} />
      {savedVOs
        .sort((a, b) => (dayjs(a.created_at).isAfter(b.created_at) ? -1 : 1))
        .map((vo) => {
          return <SearchResult vo={vo} key={vo.id} />;
        })}
      {savedVOs.length === 0 && (
        <Typography variant={"body1"} color={"gray"} textAlign={"center"}>
          You haven't saved any volunteer opportunities yet.
        </Typography>
      )}
    </Stack>
  );
};

const Recommendations = (props: { query: string }) => {
  const { data: me } = useMe();
  const { data, isLoading } = useResults(props.query);
  const [isCollapsed, setIsCollapsed] = useState(false);

  const results = data?.results;

  if ((!results || results.length === 0) && !isLoading) {
    return null;
  }

  const topResults = results
    ?.filter((result) => !me?.saved.map((s) => s.id).includes(result.id))
    .slice(0, 5);

  return (
    <Box
      sx={{
        backgroundColor: "#e8f5e9",
        borderRadius: "12px",
        padding: 3,
        marginTop: 2,
        marginBottom: 2,
        boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
        width: "100%",
        boxSizing: "border-box",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography
          variant="h5"
          sx={{
            fontWeight: "bold",
            color: "#2e7d32",
            textShadow: "1px 1px 2px rgba(0,0,0,0.1)",
          }}
        >
          ✨ AI Recommendations for You
        </Typography>
        <IconButton onClick={() => setIsCollapsed(!isCollapsed)}>
          {isCollapsed ? <ExpandMore /> : <ExpandLess />}
        </IconButton>
      </Box>
      {isLoading && (
        <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
          <CircularProgress color="success" />
        </Box>
      )}
      {topResults && (
        <Collapse in={!isCollapsed}>
          <Stack spacing={2} sx={{ marginTop: "1rem" }}>
            {topResults
              .sort((a, b) => {
                const aMinDistance = Math.min(
                  ...a.locations.map((l) => l.distance)
                );
                const bMinDistance = Math.min(
                  ...b.locations.map((l) => l.distance)
                );
                return aMinDistance - bMinDistance;
              })

              .map((result) => (
                <SearchResult key={result.id} vo={result} />
              ))}
          </Stack>
        </Collapse>
      )}
    </Box>
  );
};

const ProfileTab = () => {
  const { data: me, isLoading, error, mutate } = useMe();
  const navigate = useNavigate();

  if (isLoading || !me) {
    return "Loading...";
  }

  return (
    <Stack
      spacing={4}
      sx={{
        width: { xs: "100%", md: "48rem", lg: "60rem" },
      }}
    >
      <Stack
        sx={{
          padding: 3,
          boxShadow:
            "0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1)",
          borderRadius: "12px",
        }}
      >
        <Stack spacing={2}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="h6">Interests</Typography>
            <Button
              variant="contained"
              size="small"
              startIcon={<EditOutlined />}
              onClick={() => {
                navigate("/onboarding?page=interests");
              }}
            >
              Edit
            </Button>
          </Stack>

          <Stack direction="row" flexWrap="wrap" gap={1}>
            {me.interests && me.interests.length > 0 ? (
              me.interests.map((interest, index) => (
                <Chip key={index} label={interest} />
              ))
            ) : (
              <Typography variant="body2" color="text.secondary">
                No interests added yet.
              </Typography>
            )}
          </Stack>
          <Divider sx={{ my: 1 }} />
          <Typography variant="body2" color="text.secondary" sx={{ mb: 1 }}>
            {me.interests_text === "" ? (
              <i>No additional interests described</i>
            ) : (
              me.interests_text
            )}
          </Typography>
        </Stack>
      </Stack>

      <Stack
        sx={{
          padding: 3,
          boxShadow:
            "0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1)",
          borderRadius: "12px",
        }}
      >
        <Stack spacing={2}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="h6">Skills</Typography>
            <Button
              variant="contained"
              size="small"
              startIcon={<EditOutlined />}
              onClick={() => {
                navigate("/onboarding?page=skills");
              }}
            >
              Edit
            </Button>
          </Stack>
          <Stack direction="row" flexWrap="wrap" gap={1}>
            {me.skills && me.skills.length > 0 ? (
              me.skills.map((skill, index) => (
                <Chip key={index} label={skill} />
              ))
            ) : (
              <Typography variant="body2" color="text.secondary">
                No skills added yet.
              </Typography>
            )}
          </Stack>
          <Divider sx={{ my: 1 }} />
          <Typography variant="body2" color="text.secondary" sx={{ mb: 1 }}>
            {me.skills_text === "" ? (
              <i>No additional skills described</i>
            ) : (
              me.skills_text
            )}
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  );
};

const AccountTab = () => {
  const [email, setEmail] = useState("");
  const [confirmEmail, setConfirmEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const { data: me, isLoading, error, mutate, logout } = useMe();
  const [showEmailChange, setShowEmailChange] = useState(false);
  const [isUpdatingEmail, setIsUpdatingEmail] = useState(false);
  const [showAddressChange, setShowAddressChange] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [addressValues, setAddressValues] = useState({
    street_address: "",
    city: "",
    state: "",
    zip: "",
  });
  const [showNameChange, setShowNameChange] = useState(false);
  const [nameValues, setNameValues] = useState({
    f_name: "",
    l_name: "",
  });

  useEffect(() => {
    if (me) {
      setAddressValues({
        street_address: me.street_address || "",
        city: me.city || "",
        state: me.state || "",
        zip: me.zip_code || "",
      });
      setNameValues({
        f_name: me.f_name || "",
        l_name: me.l_name || "",
      });
    }
  }, [me]);

  if (!me) {
    return null;
  }

  const handleChangeEmail = async () => {
    if (email !== confirmEmail) {
      setEmailError("Emails do not match");
      return;
    }
    setIsUpdatingEmail(true);
    try {
      const { error } = await supabase.auth.updateUser({ email: email });
      if (error) throw error;
      setEmailError("");
      enqueueSnackbar("Done! Check your email to confirm the change", {
        variant: "success",
      });
    } catch (error) {
      setEmailError((error as AuthError).message);
    } finally {
      setIsUpdatingEmail(false);
    }
  };

  const handleChangeAddress = async () => {
    try {
      const data = await fetcher<
        {
          body: {
            street_address: string;
            city: string;
            state: string;
            zip_code: string;
          };
        },
        | { success: true; data: Omit<User, "saved"> }
        | { success: false; error: string }
      >("PATCH", "/user", {
        street_address: addressValues.street_address,
        city: addressValues.city,
        state: addressValues.state,
        zip_code: addressValues.zip,
      });

      if (!data.success) {
        throw new Error(data.error);
      }

      enqueueSnackbar("Address updated successfully", { variant: "success" });
      mutate((prevData) => {
        if (prevData) {
          return {
            ...prevData,
            street_address: addressValues.street_address,
            city: addressValues.city,
            state: addressValues.state,
            zip_code: addressValues.zip,
          };
        }
        return prevData;
      });
    } catch (error) {
      enqueueSnackbar("Failed to update address", { variant: "error" });
    }
  };

  const handleChangeName = async () => {
    try {
      const data = await fetcher<
        {
          body: {
            f_name: string;
            l_name: string;
          };
        },
        | { success: true; data: Omit<User, "saved"> }
        | { success: false; error: string }
      >("PATCH", "/user", {
        f_name: nameValues.f_name,
        l_name: nameValues.l_name,
      });

      if (!data.success) {
        throw new Error(data.error);
      }

      enqueueSnackbar("Name updated successfully", { variant: "success" });
      mutate((prevData) => {
        if (prevData) {
          return {
            ...prevData,
            f_name: nameValues.f_name,
            l_name: nameValues.l_name,
          };
        }
        return prevData;
      });
    } catch (error) {
      enqueueSnackbar("Failed to update name", { variant: "error" });
    }
  };

  const handleDeleteAccount = async () => {
    await fetcher<
      { body: undefined },
      { success: false; error: string } | { success: true; message: string }
    >("DELETE", "/user")
      .then(async (res) => {
        if (!res.success) {
          throw new Error(res.error);
        }
        setDeleteDialogOpen(false);
        enqueueSnackbar("Your account has been deleted", {
          variant: "success",
        });
        logout();
      })
      .catch(() => {
        enqueueSnackbar("Failed to delete account", { variant: "error" });
      });
  };

  if (isLoading || !me) {
    return "Loading...";
  }

  return (
    <Stack
      spacing={4}
      sx={{
        width: { xs: "100%", md: "48rem", lg: "60rem" },
      }}
    >
      <Stack spacing={3}>
        <Stack
          sx={{
            padding: { xs: 2, sm: 3 },
            boxShadow:
              "0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1)",
            borderRadius: "12px",
          }}
        >
          <Stack spacing={2}>
            <Typography variant="h6">Email Settings</Typography>
            <Stack
              direction={{ xs: "column", sm: "row" }}
              justifyContent="space-between"
              alignItems={{ xs: "flex-start", sm: "center" }}
              spacing={2}
            >
              <Typography variant="body1">
                Current Email: <i>{me.email}</i>
              </Typography>
              <Button
                variant={showEmailChange ? "outlined" : "contained"}
                startIcon={
                  showEmailChange ? <CloseOutlined /> : <EditOutlined />
                }
                onClick={() => setShowEmailChange(!showEmailChange)}
                size="small"
              >
                {showEmailChange ? "Cancel" : "Edit"}
              </Button>
            </Stack>
            {showEmailChange && (
              <>
                <Divider />
                <TextField
                  label="New Email"
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                    setConfirmEmail("");
                  }}
                  fullWidth
                  size="small"
                  margin="dense"
                />
                <TextField
                  label="Confirm New Email"
                  value={confirmEmail}
                  onChange={(e) => setConfirmEmail(e.target.value)}
                  fullWidth
                  size="small"
                  margin="dense"
                />
                <Stack direction="column" spacing={2}>
                  <Button
                    variant="contained"
                    onClick={handleChangeEmail}
                    disabled={
                      email !== confirmEmail ||
                      !email ||
                      !isValidEmail(email) ||
                      isUpdatingEmail
                    }
                    sx={{ width: "fit-content" }}
                  >
                    {isUpdatingEmail ? "Updating..." : "Update Email"}
                  </Button>
                  {emailError && (
                    <Typography variant="body2" color="error">
                      {emailError}
                    </Typography>
                  )}
                </Stack>
              </>
            )}
          </Stack>
        </Stack>

        <Stack
          sx={{
            padding: { xs: 2, sm: 3 },
            boxShadow:
              "0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1)",
            borderRadius: "12px",
          }}
        >
          <Stack spacing={2}>
            <Typography variant="h6">Name Settings</Typography>
            <Stack
              direction={{ xs: "column", sm: "row" }}
              justifyContent="space-between"
              alignItems={{ xs: "flex-start", sm: "center" }}
              spacing={2}
            >
              <Typography variant="body1">
                Current Name: <i>{`${me.f_name} ${me.l_name}`}</i>
              </Typography>
              <Button
                variant={showNameChange ? "outlined" : "contained"}
                startIcon={
                  showNameChange ? <CloseOutlined /> : <EditOutlined />
                }
                onClick={() => setShowNameChange(!showNameChange)}
                size="small"
              >
                {showNameChange ? "Cancel" : "Edit"}
              </Button>
            </Stack>
            {showNameChange && (
              <>
                <Divider />
                <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                  <TextField
                    label="First Name"
                    value={nameValues.f_name}
                    onChange={(e) =>
                      setNameValues({ ...nameValues, f_name: e.target.value })
                    }
                    fullWidth
                    size="small"
                    margin="dense"
                  />
                  <TextField
                    label="Last Name"
                    value={nameValues.l_name}
                    onChange={(e) =>
                      setNameValues({ ...nameValues, l_name: e.target.value })
                    }
                    fullWidth
                    size="small"
                    margin="dense"
                  />
                </Stack>
                <Button
                  variant="contained"
                  onClick={handleChangeName}
                  disabled={!nameValues.f_name || !nameValues.l_name}
                  sx={{ width: "fit-content" }}
                >
                  Update Name
                </Button>
              </>
            )}
          </Stack>
        </Stack>

        <Stack
          sx={{
            padding: { xs: 2, sm: 3 },
            boxShadow:
              "0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1)",
            borderRadius: "12px",
          }}
        >
          <Stack spacing={2}>
            <Typography variant="h6">Address Settings</Typography>
            <Stack
              direction={{ xs: "column", sm: "row" }}
              justifyContent="space-between"
              alignItems={{ xs: "flex-start", sm: "center" }}
              spacing={2}
            >
              <Typography variant="body1">
                Current Address:{" "}
                <i>
                  {[me.street_address, me.city, me.state, me.zip_code]
                    .filter((v) => v !== "")
                    .join(", ")}
                </i>
              </Typography>
              <Button
                variant={showAddressChange ? "outlined" : "contained"}
                startIcon={
                  showAddressChange ? <CloseOutlined /> : <EditOutlined />
                }
                onClick={() => setShowAddressChange(!showAddressChange)}
                size="small"
              >
                {showAddressChange ? "Cancel" : "Edit"}
              </Button>
            </Stack>
            {showAddressChange && (
              <>
                <Divider />
                <AddressInput
                  setValues={setAddressValues}
                  values={addressValues}
                  setError={() => {}}
                />
                <Button
                  variant="contained"
                  onClick={handleChangeAddress}
                  disabled={
                    Object.entries(addressValues).some(
                      ([key, value]) => !value && key !== "street_address"
                    ) ||
                    JSON.stringify(addressValues) ===
                      JSON.stringify({
                        street_address: me.street_address || "",
                        city: me.city || "",
                        state: me.state || "",
                        zip: me.zip_code || "",
                      })
                  }
                  sx={{ width: "fit-content" }}
                >
                  Update Address
                </Button>
              </>
            )}
          </Stack>
        </Stack>
      </Stack>

      <Stack
        sx={{
          padding: 3,
          boxShadow:
            "0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1)",
          borderRadius: "12px",
        }}
      >
        <Stack spacing={2}>
          <Typography variant="h6">Reset Password</Typography>
          <Typography variant="body2">
            Click the button below to receive a password reset link via email.
          </Typography>
          <Button
            variant="contained"
            onClick={() => {
              supabase.auth
                .resetPasswordForEmail(me.email, {
                  redirectTo:
                    process.env.REACT_APP_ROOT_URL + "/reset_password",
                })
                .then(() => {
                  enqueueSnackbar("Check your email for a reset link", {
                    variant: "success",
                  });
                })
                .catch((error) => {
                  enqueueSnackbar(error.message, { variant: "error" });
                });
            }}
            sx={{ width: "fit-content" }}
          >
            Send Reset Link
          </Button>
        </Stack>
      </Stack>

      <Stack
        sx={{
          padding: 3,
          boxShadow:
            "0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1)",
          borderRadius: "12px",
          backgroundColor: "#FFEBEE", // Light red shade
        }}
      >
        <Stack>
          <Typography variant="h6" color="error">
            Danger Zone
          </Typography>
          <Typography variant="body2" color="error" mt={1}>
            Warning: Deleting your account is irreversible and cannot be undone.
          </Typography>
          <Button
            variant="contained"
            color="error"
            onClick={() => setDeleteDialogOpen(true)}
            sx={{ width: "fit-content", mt: 2 }} // Prevents full width
            startIcon={<DeleteOutline />}
          >
            Delete Account
          </Button>
        </Stack>
      </Stack>

      <Dialog
        open={deleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Delete Account?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete your account? This action cannot be
            undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteDialogOpen(false)}>Cancel</Button>
          <Button onClick={handleDeleteAccount} color="error" autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Stack>
  );
};

export const Account = () => {
  const { data: me, isLoading, error, mutate } = useMe();
  const navigate = useNavigate();
  const [tabValue, setTabValue] = useState(0);
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (!me) return;

    const page = searchParams.get("page");
    const pages = ["favorites", "profile", "account"];
    const pageIndex = pages.indexOf(page || "favorites");

    if (pageIndex !== -1) {
      setTabValue(pageIndex);
    } else {
      // If an invalid page is provided, default to favorites
      setSearchParams({ page: "favorites" });
    }
  }, [searchParams, me, setSearchParams]);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    const pages = ["favorites", "profile", "account"];
    setSearchParams({ page: pages[newValue] });
    setTabValue(newValue);
  };

  if (isLoading) {
    return (
      <Stack
        direction="column"
        justifyContent="center"
        alignItems="center"
        height="calc(100vh - 75px)"
      >
        <CircularProgress size={40} />
        <Typography mt={2}>
          Loading
          <Ellipsis />
        </Typography>
      </Stack>
    );
  }

  if (!me || error) {
    return <Navigate to="/" />;
  }

  return (
    <>
      <MetaDataHeader
        title="My Account"
        description="View your saved volunteer opportunities and other account information."
      />
      <Stack
        sx={{
          minHeight: "100%",
        }}
        alignItems={"center"}
        spacing={4}
        padding={2}
        paddingTop={12}
        paddingBottom={6}
      >
        <Stack alignItems={"center"} spacing={2}>
          <Tooltip
            title="Edit your profile picture at gravatar.com"
            arrow
            placement="top"
          >
            <Box>
              <AccountAvatar me={me} size={90} />
            </Box>
          </Tooltip>
          <Typography variant={"h1"}>Hi {me.f_name}!</Typography>
          <Typography
            sx={{
              cursor: "pointer",
              color: "primary",
              textDecoration: "underline",
            }}
            onClick={async () => {
              await supabase.auth.signOut().then(() => {
                mutate(null);
                navigate("/");
              });
            }}
          >
            Logout
          </Typography>
        </Stack>
        <Tabs value={tabValue} onChange={handleTabChange} centered>
          <Tab label="Favourites" />
          <Tab label="Profile" />
          <Tab label="Account" />
        </Tabs>
        {tabValue === 0 && <FavoritesTab savedVOs={me.saved} />}
        {tabValue === 1 && <ProfileTab />}
        {tabValue === 2 && <AccountTab />}
      </Stack>
    </>
  );
};
