import { useEffect, useState } from "react";

export const Ellipsis = () => {
  const [text, setText] = useState<"" | "." | ".." | "...">("");

  useEffect(() => {
    const t = setInterval(() => {
      setText((prev) => {
        switch (prev) {
          case "":
            return ".";
          case ".":
            return "..";
          case "..":
            return "...";
          case "...":
            return "";
        }
      });
    }, 400);
    return () => clearTimeout(t);
  }, [setText]);

  return <>{text}</>;
};
