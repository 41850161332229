import {BrowserRouter, Route, Routes} from "react-router-dom";

import Home from "./pages/Home";
import About from "./pages/About";
import NotFound from "./pages/NotFound";
import People from "./pages/People";
import Search from "./pages/Search";
import Navbar from "./components/navigation-bar";
import Footer from "./components/Footer";
import Donate from "./pages/Donate";
import Contact from "./pages/Contact";
import JoinUs from "./pages/JoinUs";
import Zeffy from "./pages/Zeffy";
import Terms from "./pages/Terms";
import Privacy from "./pages/Privacy";
import {StrictMode} from "react";
import {ThemeProvider} from "@mui/material/styles";
import theme from "./components/MaterialTheme";
import {SWRConfig} from "swr";
import {fetcher} from "./utils/fetchData";
import Login from "./pages/Login";
import {SessionContextProvider} from "@supabase/auth-helpers-react";
import {supabase} from "./utils/auth";
import {Account} from "./pages/Account";
import ForgotPassword from "./pages/ForgotPassword";
import UpdatePassword from "./pages/UpdatePassword";
import SignUp from "./pages/SignUp";
import Onboarding from "./pages/Onboarding";
import {SnackbarProvider} from "notistack";
import {ScrollToTop} from "./components/ScrollToTop";
import { Analytics } from '@vercel/analytics/react';


const App = () => {
  return (
    <StrictMode>
      <ThemeProvider theme={theme}>
        <SessionContextProvider supabaseClient={supabase}>
          <SWRConfig
            value={{
              fetcher: (resource, init) => fetcher("GET", resource, init),
            }}
          >
            <BrowserRouter>
              <SnackbarProvider autoHideDuration={4000} />
              <Navbar />

              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/about" element={<About />} />
                <Route path="/people" element={<People />} />
                <Route path="/login" element={<Login />} />
                <Route path="/signup" element={<SignUp />} />
                {/*<Route path="/callback" element={<AuthCallback />} />*/}
                <Route path="/forgot_password" element={<ForgotPassword />} />
                <Route path="/reset_password" element={<UpdatePassword />} />
                <Route path="/onboarding" element={<Onboarding />} />
                {/* <Route path="/account" element={<Account />} /> */}
                <Route path="/me" element={<Account />} />
                <Route path="/search" element={<Search />} />
                <Route path="/donate" element={<Donate />} />
                <Route path="/contact" element={<Contact />} />
                <Route path="/joinus" element={<JoinUs />} />
                <Route path="/zeffy" element={<Zeffy />} />
                <Route path="/terms" element={<Terms />} />
                <Route path="/privacy" element={<Privacy />} />
                <Route path="*" element={<NotFound />} />

              </Routes>
              <ScrollToTop />

              <Footer />
              <Analytics />
            </BrowserRouter>
          </SWRConfig>
        </SessionContextProvider>
      </ThemeProvider>
    </StrictMode>
  );
};

export default App;
