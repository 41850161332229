import React, { useEffect } from 'react';
import MetaDataHeader from '../components/MetaDataHeader';
import { Container } from '@mui/material';

function NotFound() {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'instant' as ScrollBehavior });
  }, []);

  return (
    <Container
      sx={{
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <MetaDataHeader title='404' description='404 Not Found' />
      <h1>404 Not Found</h1>
    </Container>
  );
}

export default NotFound;
