import { Button, Grid, Link, Stack, Typography } from '@mui/material';
import { useEffect } from 'react';

import CustomSection from '../components/custom-section';
import DonateContactForm from '../components/donate-contact-form';
import MetaDataHeader from '../components/MetaDataHeader';

const Donate = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'instant' as ScrollBehavior });
  }, []);

  return (
    <div className='Donate'>
      <MetaDataHeader title = "Donate" description='Donate to the Good Samaritans' />
      <CustomSection bgStyle='primary' paddingY='small'>
        <Grid bgcolor={'primary.light'} py={3}>
          <Typography
            variant='h1'
            align='center'
            sx={{ textTransform: 'uppercase' }}
          >
            Support Scout
          </Typography>
        </Grid>
        <Grid
          container
          justifyContent='center'
          alignItems='flex-start'
          width={'100%'}
          py={3}
          px={{ xs: 2, sm: 5, md: 5, lg: 10, xl: 10 }}
          spacing={{ xs: 5, sm: 5, md: 5, lg: 5, xl: 5 }}
          bgcolor={'primary.light'}
        >
          <DonateNow />
          <Grid item xs={10} sm={9} md={9} lg={5} xl={5}>
            <Typography
              variant='h3'
              style={{ lineHeight: 1.5 }}
              textAlign={'left'}
            >
              Scaling and maintaining a technology platform requires a significant investment in infrastructure and a small, dedicated software development team. We are currently an all-volunteer team, but to launch a platform that will have a nation-wide impact, we need your help today.
            </Typography>
          </Grid>
        </Grid>
      </CustomSection>
      <Grid
        container
        justifyContent='center'
        alignItems='center'
        width={'100%'}
        py={5}
        bgcolor={'white'}
      >
        <Grid item xs={10} sm={9} md={9} lg={7} xl={7}>
        </Grid>
      </Grid>
      
      <Grid
        container
        justifyContent='center'
        width={'100%'}
        py={5}
        px={{ xs: 2, sm: 5, md: 5, lg: 10, xl: 10 }}
        spacing={{ xs: 5, sm: 5, md: 5, lg: 5, xl: 5 }}
        bgcolor={'white'}
      >
        <Grid item xs={12} sm={12} md={10} lg={8} xl={7}>
          <Stack spacing={5}>
            <Typography
              variant='h1'
              gutterBottom
              sx={{ textTransform: 'uppercase' }}
              textAlign={'center'}
            >
              Let's Talk
            </Typography>
            <Stack pb={{ xs: '40px', sm: '60px', md: '80px' }}>
              <DonateContactForm />
            </Stack>
          </Stack>
        </Grid>
      </Grid>
    </div>
  );
};

function DonateNow() {
  return (
    <Grid item xs={12} sm={12} md={10} lg={5} xl={5}>
      <Stack spacing={5}>
        <Stack
          width={'100%'}
          bgcolor={'secondary.main'}
          borderRadius={2}
          alignItems={'center'}
          py={5}
          boxShadow={2}
        >
          <Stack
            textAlign='center'
            spacing={3}
            width={{
              xs: '250px',
              sm: '400px',
              md: '250px',
              lg: '265px',
              xl: '265px',
            }}
          >
            <Stack>
              <Typography variant='h3' gutterBottom>
                Donate though our
              </Typography>
              <Typography
                variant='h3'
                gutterBottom
                sx={{ textTransform: 'uppercase' }}
              >
                no-fee payment page
              </Typography>
            </Stack>
            <Link href="/zeffy">
              <Button
                size='large'
                variant='contained'
                sx={{
                  bgcolor: 'black',
                  height: '65px',
                  '&:hover': {
                    bgcolor: 'secondary.dark',
                  },
                }}
              >
                <img
                  src='/images/donate-page/zeffy.svg'
                  alt='zeffy'
                  width={'100px'}
                />
              </Button>
            </Link>
            <Stack spacing={3}>
              <Typography variant='h3' gutterBottom>
                We also accept
              </Typography>
              <Button
                size='large'
                variant='contained'
                sx={{
                  bgcolor: 'white',
                  color: 'black',
                  height: '65px',
                  '&:hover': {
                    bgcolor: 'primary.light',
                  },
                }}
                onClick={() => {
                  window.open(
                    'https://account.venmo.com/u/SamaritanScout',
                    '_blank'
                  );
                }}
              >
                <Stack direction={'row'} spacing={2}>
                  <img
                    src='/images/donate-page/venmo.svg'
                    alt='zeffy'
                    width={'45px'}
                  />
                  <Stack justifyContent={'space-between'}>
                    <Typography
                      variant='body1'
                      fontSize={16}
                      textAlign={'left'}
                      textTransform={'none'}
                      fontWeight={'bold'}
                    >
                      Venmo
                    </Typography>
                    <Typography variant='body1' fontSize={14}>
                      Fees [$0.10 + 1.90 %]
                    </Typography>
                  </Stack>
                </Stack>
              </Button>
              <Button
                size='large'
                variant='contained'
                sx={{
                  bgcolor: 'white',
                  color: 'black',
                  height: '65px',
                  '&:hover': {
                    bgcolor: 'primary.light',
                  },
                }}
                onClick={() => {
                  window.open(
                    'https://www.paypal.com/fundraiser/charity/4894555',
                    '_blank'
                  );
                }}
              >
                <Stack direction={'row'} spacing={2}>
                  <img
                    src='/images/donate-page/paypal.svg'
                    alt='zeffy'
                    width={'30px'}
                  />
                  <Stack justifyContent={'space-between'}>
                    <Typography
                      variant='body1'
                      fontSize={14}
                      textAlign={'left'}
                      textTransform={'none'}
                      fontWeight={'bold'}
                    >
                      PayPal
                    </Typography>
                    <Typography variant='body1' fontSize={14}>
                      Fees [$0.10 + 1.90 %]
                    </Typography>
                  </Stack>
                </Stack>
              </Button>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </Grid>
  );
}

export default Donate;
