import { Box, Container, Grid, Stack } from '@mui/material';
import color_theme from './Theme';

function Separator({ primary }: { primary: boolean }) {
  const fontColor = primary
    ? color_theme.primaryFontColor
    : color_theme.fontColor;

  return (
    <Container
      style={{
        paddingBottom: '20px',
        paddingRight: 0,
        paddingLeft: 0,
      }}
      maxWidth={false} // Set maxWidth to 'false' to fill the width of the screen
    >
      <Stack>

      <Grid container alignItems='center' spacing={2}>
        <Grid item xs={4.5} sm={4.5} md={4.5} lg={4.5} xl={4.5} color={fontColor}>
          <Box
            sx={{ width: '100%' }}
            borderBottom={`0.2rem solid ${fontColor}`}
            borderRadius={2}
          ></Box>
        </Grid>
        <Grid item xs={3} sm={3} md={3} lg={3} xl={3} textAlign={'center'}>
          <img
            src={'/images/dog_silhouette.png'}
            alt='dog'
            style={{
              width: '100%',
              height: 'auto',
              maxWidth: '6rem',
              transform: 'scaleX(-1)',
            }}
          ></img>
        </Grid>
        <Grid item xs={4.5} sm={4.5} md={4.5} lg={4.5} xl={4.5} color={fontColor}>
          <Box
            sx={{ width: '100%' }}
            borderBottom={`0.2rem solid ${fontColor}`}
            borderRadius={2}
          ></Box>
        </Grid>
      </Grid>
      </Stack>
    </Container>
  );
}

export default Separator;
