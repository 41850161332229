import {
  IconButton,
  Modal,
  Stack,
  Typography,
  useMediaQuery,
} from '@mui/material';
import MaterialTheme from './MaterialTheme';
import { Icons } from './Icons';
import { useEffect, useRef } from 'react';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
// import LinkRoundedIcon from '@mui/icons-material/LinkRounded';

type PersonModalProps = {
  title: string;
  name: string;
  description: string;
  image: string;
  link?: string;
  open: boolean;
  onClose: () => void;
};

const PersonModal = ({
  title,
  name,
  description,
  image,
  link,
  open,
  onClose,
}: PersonModalProps) => {
  const theme = MaterialTheme;

  const isXs = useMediaQuery(theme.breakpoints.only('xs'));
  const isSm = useMediaQuery(theme.breakpoints.only('sm'));
  const isMd = useMediaQuery(theme.breakpoints.only('md'));
  const isLg = useMediaQuery(theme.breakpoints.only('lg'));
  const isXl = useMediaQuery(theme.breakpoints.only('xl'));

  let imgSize;
  let iconSize;

  if (isXs) {
    imgSize = '150px';
    iconSize = '26px';
  } else if (isSm) {
    imgSize = '150px';
    iconSize = '30px';
  } else if (isMd) {
    imgSize = '200px';
    iconSize = '30px';
  } else if (isLg) {
    imgSize = '220px';
    iconSize = '36px';
  } else if (isXl) {
    imgSize = '250px';
    iconSize = '36px';
  }

  const modalRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        modalRef.current &&
        !modalRef.current.contains(event.target as Node)
      ) {
        onClose(); // Call onClose if the user clicks outside the modal content.
      }
    };

    if (open) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [open, onClose]);
  return (
    <Modal
      open={open}
      onClose={onClose}
      sx={{
        backdropFilter: 'blur(5px)',
        backgroundColor: 'rgba(0, 0, 0, 0.7)',
        color: 'white',
        transition: 'all',
        display: 'flex',
        height: '100%',
        width: '100%',
        overflowY: 'auto',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Stack
        ref={modalRef}
        gap={2}
        direction={{
          xs: 'column',
          sm: 'column',
          md: 'row',
          lg: 'row',
          xl: 'row',
        }}
        width={{
          xs: '320px',
          sm: '550px',
          md: '800px',
          lg: '900px',
          xl: '990px',
        }}
        sx={{ position: 'relative', outline: 'none' }}
      >
        <IconButton
          onClick={onClose}
          sx={{
            position: 'absolute',
            top: '0px',
            right: '0px',
            color: 'white',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            '&:hover': {
              backgroundColor: 'rgba(255, 255, 255, 0.7)',
              transition: 'all',
              color: 'black',
            },
          }}
        >
          <Icons.Close />
        </IconButton>
        <img
          src={image}
          alt={name}
          style={{
            width: imgSize,
            height: imgSize,
            objectFit: 'cover',
            borderRadius: theme.shape.borderRadius,
            aspectRatio: '1/1',
          }}
        />
        <Stack gap={2}>
          <Stack direction={'row'} spacing={1.5}>
            <Typography
              variant={
                useMediaQuery(theme.breakpoints.only('xs')) ? 'h2' : 'h1'
              }
              component='h1'
            >
              {name}
            </Typography>
            <IconButton
              href={link!}
              target='_blank'
              rel='noopener noreferrer'
              color='primary'
              sx={{
                color: 'white',
                '&:hover': { color: `${MaterialTheme.palette.primary.light}` },
                padding: 0,
              }}
            >
              {/* <LinkRoundedIcon sx={{ fontSize: 32 }} /> */}
              <LinkedInIcon sx={{ fontSize: iconSize }} />
            </IconButton>
          </Stack>
          <Typography variant='h3'>{title}</Typography>
          <Typography
            variant='body1'
            fontSize={
              useMediaQuery(theme.breakpoints.only('xs')) ? '1rem' : '1.2rem'
            }
          >
            {description}
          </Typography>
        </Stack>
      </Stack>
    </Modal>
  );
};

export default PersonModal;
