import { Box, Stack, Typography } from '@mui/material';

import JoinTheTeamForm from '../components/join-the-team-form';
import MaterialTheme from '../components/MaterialTheme';
import { useEffect } from 'react';
import Separator from '../components/Separator';

import MetaDataHeader from '../components/MetaDataHeader';
import { WhyVolunteer } from '../components/WhyVolunteer';

const JoinUs = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'instant' as ScrollBehavior });
  }, []);

  return (
    <>
      <MetaDataHeader title = "Join Us" description='Join the Good Samaritans' />
      <Stack py={5} alignItems={'center'}>
        <Stack
          width={{
            xl: '800px',
            lg: '800px',
            md: '600px',
            sm: '70%',
            xs: '90%',
          }}
          spacing={2}
        >
          <Box
            sx={{
              backgroundImage: 'url(/images/join-us-page/join-us.png)',
              backgroundSize: 'contain',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',
            }}
            borderRadius={2}
            height={{
              xs: '200px',
              sm: '200px',
              md: '250px',
              lg: '250px',
              xl: '250px',
            }}
            width={'100%'}
          ></Box>
          <Typography
            variant='h1'
            gutterBottom
            textAlign={'center'}
            sx={{ textTransform: 'uppercase' }}
          >
            Join the effort
          </Typography>
          <Typography
            variant='h3'
            textAlign={'center'}
            color={MaterialTheme.palette.grey.A400}
          >
            Help Us build the platform that will facilitate and accelerate the
            improvements we need in the world around us.
          </Typography>
          <Separator primary={true} />
          <JoinTheTeamForm />
        </Stack>
      </Stack>
      {/* Why volunteer with us? */}
      <WhyVolunteer />
    </>
  );
};

export default JoinUs;
