import MetaDataHeader from "../components/MetaDataHeader";
import {
  Box,
  FormControl,
  InputLabel,
  Link,
  MenuItem,
  NativeSelect,
  OutlinedInput,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import Button from "../components/button";
import { supabase, User } from "../utils/auth";
import { useSession } from "@supabase/auth-helpers-react";
import { useNavigate } from "react-router-dom";
import { useMe } from "../hooks/useResults";
import { Button as MuiButton } from "@mui/material";
import { blue } from "@mui/material/colors";
import { LinkedIn } from "@mui/icons-material";
import theme from "../components/MaterialTheme";
import { Turnstile } from "@marsidev/react-turnstile";
import { fetcher } from "../utils/fetchData";
import { Sign } from "crypto";
import {ScrollToTop} from "../components/ScrollToTop";

type SignUpValues = {
  email: string;
  password: string;
  f_name: string;
  l_name: string;
} & AddressValues;

interface AddressValues {
  street_address: string;
  city: string;
  state: string;
  zip: string;
}

const VerifyEmail = () => {
  return (
    <Stack
      height={"calc(100vh - 75px)"}
      alignItems={"center"}
      justifyContent={"center"}
      spacing={4}
      maxWidth="sm"
      mx="auto"
      px={4}
    >
      <Typography variant="h2" fontWeight="bold" textAlign="center">
        Thanks for signing up!
      </Typography>
      <Typography textAlign="center">
        Please check your email 📧 for a verification link to complete your
        registration.
      </Typography>
      <Link href="/" color="primary" sx={{ mt: 2 }}>
        Return to Homepage
      </Link>
      <ScrollToTop/>
    </Stack>
  );
};

const Login = () => {
  const { data: user, mutate } = useMe();
  const session = useSession();
  const navigate = useNavigate();
  const [values, setValues] = React.useState<SignUpValues>({
    email: "",
    password: "",
    f_name: "",
    l_name: "",
    street_address: "",
    city: "",
    state: "",
    zip: "",
  });
  const [captchaToken, setCaptchaToken] = useState<string | undefined>();
  const [confirmPassword, setConfirmPassword] = React.useState("");
  const { email, password, f_name, l_name } = values;
  const [error, setError] = React.useState<string | null>(null);
  const [verifyEmail, setVerifyEmail] = React.useState<boolean>(false);

  const isFormValid = () => {
    return (
      Object.entries(values).every(
        ([key, value]) => value.trim() !== "" || key === "street_address"
      ) && password === confirmPassword
    );
  };

  useEffect(() => {
    if (user) {
      navigate("/");
    }
  }, [user, navigate, verifyEmail]);

  const signup = async () => {
    await supabase.auth
      .signUp({
        email: email,
        password: password,
        options: {
          data: {
            f_name,
            l_name,
            street_address: values.street_address,
            city: values.city,
            state: values.state,
            zip_code: values.zip,
          },
          emailRedirectTo: `${process.env.REACT_APP_ROOT_URL}/onboarding`,
          captchaToken,
        },
      })
      .then(async ({ data, error }) => {
        if (error) {
          throw error;
        }
        if (data) {
          setVerifyEmail(true);
        } else {
          setError(data);
        }
      })
      .catch((e) => {
        console.error(e);
        setError(e.message);
      });
  };

  if (verifyEmail) {
    return <VerifyEmail />;
  }

  return (
    <>
      <MetaDataHeader
        title="Login"
        description="Login to your Samaritan Scout account"
      />
      <Stack
        sx={{
          minHeight: "calc(100vh - 75px)",
        }}
        alignItems={"center"}
        justifyContent={"center"}
      >
        {" "}
        <Box
          component="img"
          src="/images/dog_silhouette.png"
          alt="Dog Silhouette"
          sx={{ width: "100px", height: "auto" }}
          marginTop={{ xs: 2, sm: 12 }}
          marginBottom={2}
        />
        <Stack
          sx={{
            width: { xs: "100%", sm: "40rem" },
            p: { xs: 2, sm: 4 },
            boxShadow:
              "0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1)",
            borderRadius: "12px",
          }}
          textAlign={"center"}
          spacing={3}
          boxSizing={"border-box"}
          marginBottom={{ xs: 2, sm: 12 }}
        >
          <Stack spacing={2} alignItems="center">
            <Typography variant={"h2"}>Welcome to Samaritan Scout</Typography>
            <Link href={"/login"} color={theme.palette.grey[600]}>
              Already have an account?
            </Link>
          </Stack>
          <Stack spacing={2}>
            <Stack
              direction={{ xs: "column", sm: "row" }}
              spacing={2}
              useFlexGap
              flexWrap="wrap"
            >
              <TextField
                label={"First Name"}
                variant="outlined"
                placeholder="First Name"
                fullWidth={true}
                value={f_name}
                required
                onChange={(e) => {
                  setError(null);
                  setValues((prev) => ({ ...prev, f_name: e.target.value }));
                }}
                sx={{
                  borderRadius: "10px",
                  backgroundColor: "#fff",
                  input: { color: "black" },
                  flexGrow: 1,
                  flexBasis: { xs: "100%", sm: "45%" },
                }}
              />
              <TextField
                label={"Last Name"}
                variant="outlined"
                placeholder="Last Name"
                fullWidth={true}
                value={l_name}
                required
                onChange={(e) => {
                  setError(null);
                  setValues((prev) => ({ ...prev, l_name: e.target.value }));
                }}
                sx={{
                  borderRadius: "10px",
                  backgroundColor: "#fff",
                  input: { color: "black" },
                  flexGrow: 1,
                  flexBasis: { xs: "100%", sm: "45%" },
                }}
              />
            </Stack>
            <TextField
              label={"Email"}
              variant="outlined"
              placeholder="Email"
              fullWidth={true}
              value={email}
              required
              onChange={(e) => {
                setError(null);
                setValues((prev) => ({ ...prev, email: e.target.value }));
              }}
              sx={{
                borderRadius: "10px",
                backgroundColor: "#fff",
                input: { color: "black" },
              }}
            />
            <TextField
              label={"Password"}
              type="password"
              value={password}
              required
              onChange={(e) => {
                setError(null);
                setValues((prev) => ({ ...prev, password: e.target.value }));
                setConfirmPassword("");
              }}
            />
            <TextField
              label={"Confirm Password"}
              type="password"
              value={confirmPassword}
              required
              onChange={(e) => {
                setConfirmPassword(e.target.value);
                setError(null);
              }}
            />
            <AddressInput<SignUpValues>
              setValues={setValues}
              values={values}
              setError={setError}
            />
          </Stack>

          {error && (
            <Typography color={theme.palette.error.main} fontSize={16}>
              {error}
            </Typography>
          )}
          <Turnstile
            siteKey="0x4AAAAAAAf9PH0bWMIyaBkZ"
            onSuccess={(token) => {
              setCaptchaToken(token);
            }}
          />
          <Typography color={theme.palette.grey[600]}>
            By creating an account you are agreeing to our{" "}
            <Link href={"/terms"}>Terms of Service</Link> and{" "}
            <Link href={"/privacy"}>Privacy Policy</Link>.
          </Typography>
          <Button
            size={"large"}
            bgStyle={"primary"}
            hoverStyle={"dark"}
            width={"100%"}
            onClick={signup}
            disabled={!isFormValid()}
          >
            Start saving the world
          </Button>
        </Stack>
      </Stack>
    </>
  );
};

const US_STATES = [
  "Alaska",
  "Alabama",
  "Arkansas",
  "Arizona",
  "California",
  "Colorado",
  "Connecticut",
  "Delaware",
  "Florida",
  "Georgia",
  "Hawaii",
  "Iowa",
  "Idaho",
  "Illinois",
  "Indiana",
  "Kansas",
  "Kentucky",
  "Louisiana",
  "Massachusetts",
  "Maryland",
  "Maine",
  "Michigan",
  "Minnesota",
  "Missouri",
  "Mississippi",
  "Montana",
  "North Carolina",
  "North Dakota",
  "Nebraska",
  "New Hampshire",
  "New Jersey",
  "New Mexico",
  "Nevada",
  "New York",
  "Ohio",
  "Oklahoma",
  "Oregon",
  "Pennsylvania",
  "Rhode Island",
  "South Carolina",
  "South Dakota",
  "Tennessee",
  "Texas",
  "Utah",
  "Virginia",
  "Vermont",
  "Washington",
  "Wisconsin",
  "West Virginia",
  "Wyoming",
  "District of Columbia",
  "American Samoa",
  "Guam",
  "Northern Mariana Islands",
  "Puerto Rico",
  "Virgin Islands",
];

export const AddressInput = <T extends AddressValues>(props: {
  setValues: Dispatch<SetStateAction<T>>;
  values: T;
  setError: Dispatch<SetStateAction<string | null>>;
}) => {
  const { setValues, setError } = props;
  const { street_address, city, state, zip } = props.values;
  return (
    <Stack spacing={2}>
      <TextField
        label={"Street Address"}
        variant="outlined"
        placeholder="Street Address"
        aria-label={"Street Address"}
        fullWidth={true}
        value={street_address}
        onChange={(e) => {
          setError(null);
          setValues((prev) => ({ ...prev, street_address: e.target.value }));
        }}
        sx={{
          borderRadius: "10px",
          backgroundColor: "#fff",
          input: { color: "black" },
        }}
      />
      <TextField
        label={"City"}
        variant="outlined"
        placeholder="City"
        aria-label={"City"}
        fullWidth={true}
        value={city}
        required
        onChange={(e) => {
          setError(null);
          setValues((prev) => ({ ...prev, city: e.target.value }));
        }}
        sx={{
          borderRadius: "10px",
          backgroundColor: "#fff",
          input: { color: "black" },
        }}
      />
      <Stack direction={"row"} spacing={2}>
        <FormControl fullWidth={true} required>
          <NativeSelect
            id={"state-input"}
            onChange={(e) => {
              setError(null);
              setValues((prev) => ({ ...prev, state: e.target.value }));
            }}
            placeholder={"State *"}
            maxRows={10}
            value={state}
            aria-label={"State *"}
            input={
              <OutlinedInput
                fullWidth={true}
                sx={{
                  color: state === "" ? "#666" : undefined,
                }}
              />
            }
          >
            <option value={""} disabled>
              State *
            </option>
            {US_STATES.map((state) => {
              return (
                <option value={state} key={state}>
                  {state}
                </option>
              );
            })}
          </NativeSelect>{" "}
        </FormControl>
        <TextField
          label={"Zip Code"}
          variant="outlined"
          placeholder="Zip Code"
          aria-label={"Zip Code"}
          fullWidth={true}
          value={zip}
          required
          onChange={(e) => {
            setError(null);
            setValues((prev) => ({ ...prev, zip: e.target.value }));
          }}
          sx={{
            borderRadius: "10px",
            backgroundColor: "#fff",
            input: { color: "black" },
          }}
        />
      </Stack>
    </Stack>
  );
};

export default Login;
