interface SiteMap {
  [key: string]: { label: string; link: string; openInNewTab: boolean }[];
}

export const siteMap: SiteMap = {
  'Get Involved': [
    { label: 'Donate', link: '/donate', openInNewTab: false },
    { label: 'Volunteer', link: '/joinus', openInNewTab: true },
    { label: 'Search', link: '/search', openInNewTab: false },
  ],
  'Learn More': [
    { label: 'Contact Us', link: '/contact', openInNewTab: true },
    { label: 'About', link: '/about', openInNewTab: false },
    { label: 'People', link: '/people', openInNewTab: false },
  ],
};

type Quote = {
  imagePath: string;
  title: string;
  author: string;
  quote: string;
};

export const testimonial: Quote[] = [
  {
    imagePath: '/images/people-page/effect-impact.svg',
    title: 'Achieve A Scalable Impact',
    quote:
      'I am immensely grateful for the opportunity to contribute my skills to a cause that has the power of a scalable impact. The prospect of helping people in numerous places around the world, through innovative technology, fills me with hope and optimism for the future.',
    author: 'Volunteer UX Designer',
  },
  {
    imagePath: '/images/people-page/get-inspired.svg',
    title: 'Get Inspired and Inspire Others',
    quote:
      'Working with this remarkable team I have come to appreciate the power of collaboration and the magic that happens when individuals from different backgrounds and cultures come together with a common goal to help people give back to the community.',
    author: 'Volunteer Back-end Software Engineer',
  },
  {
    imagePath: '/images/people-page/develop-skills.svg',
    title: 'Develop Skills To Advance Your Career',
    quote:
      'This project has taught me a lot of new things that I was previously unaware of. I learned about AWS Lambda, EFS, and work in a real Agile Environment. Honestly, the whole experience is amazing. Thank you for believing in me and giving me a chance to be a part of this great team.',
    author: 'Volunteer Back-end Software Engineer',
  },
  {
    imagePath: '/images/people-page/collaborate.svg',
    title: 'Collaborate With Kind People',
    quote:
      'I have met so many wonderful, kind-hearted people from different places who share my goals and my belief in this mission. We discuss ideas to improve the platform, we learn from each other, and even help each other on our career paths – all of which gives me a very strong sense of belonging.',
    author: 'Volunteer Front-end Software Engineer',
  },
];

export const causes = [
  {
    title: 'Agriculture & Rural Areas',
    value: 'Agriculture',
  },
  {
    title: 'Animals',
    value: 'Animals',
  },
  {
    title: 'Art & Culture',
    value: 'Art',
  },
  {
    title: 'Children & Youth',
    value: 'Children',
  },
  {
    title: 'Disability',
    value: 'Disability',
  },
  {
    title: 'Disaster Relief',
    value: 'Disaster',
  },
  {
    title: 'Delivery/Drive',
    value: 'Delivery',
  },
  {
    title: 'Environment & Nature',
    value: 'Environment',
  },
  {
    title: 'Finance & Business',
    value: 'Financial',
  },
  {
    title: 'Immigrants & Refugees',
    value: 'Immigrants',
  },
  {
    title: 'Justice & Legal Assistance',
    value: 'Justice',
  },
  {
    title: 'LGBTQ+',
    value: 'Lgbtq+',
  },
  {
    title: 'Health & Medical Research',
    value: 'health',
  },
  {
    title: 'Poverty',
    value: 'poverty',
  },
  {
    title: 'Race & Ethnicity',
    value: 'race',
  },
  {
    title: 'Religion & Spirituality',
    value: 'religion',
  },
  {
    title: 'Science & Technology',
    value: 'science',
  },
  {
    title: 'Seniors',
    value: 'seniors',
  },
  {
    title: 'Sports & Recreation',
    value: 'sports',
  },
  {
    title: 'Veterans & Military Families',
    value: 'veterans',
  },
  {
    title: 'Women',
    value: 'women',
  },
];

export const skills = [
  {
    title: 'Administrative',
    value: 'administrative',
  },
  {
    title: 'Arts',
    value: 'arts',
  },
  {
    title: 'Finance & Business',
    value: 'financial',
  },
  {
    title: 'Healthcare / Medical',
    value: 'healthcare',
  },
  {
    title: 'Interpersonal',
    value: 'interpersonal',
  },
  {
    title: 'Legal',
    value: 'legal',
  },
  {
    title: 'Communications / Marketing',
    value: 'communications',
  },
  {
    title: 'Nature / Animal (hands-on)',
    value: 'nature',
  }, 
  {
    title: 'Skilled Craft / Trade',
    value: 'craft',
  },
  {
    title: 'Sports & Recreation',
    value: 'sports',
  },
  {
    title: 'Teach / Mentor / Coach',
    value: 'teaching',
  },
  {
    title: 'Technology',
    value: 'technology',
  },
  {
    title: 'Write / Research',
    value: 'writing',
  },
  {
    title: 'Other',
    value: 'other'
  }
];
