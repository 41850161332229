import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import {
  Box,
  Container,
  IconButton,
  Stack,
  useMediaQuery,
} from '@mui/material';
import { Button } from '@mui/material';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import InstagramIcon from '@mui/icons-material/Instagram';
import MaterialTheme from './MaterialTheme';
import { siteMap } from '../data/data';
import { useLocation } from 'react-router-dom';

function Copyright() {
  return (
		<Stack
			color="white"
		>
			<Typography variant="body2">
				{"Copyrights © "}
				{new Date().getFullYear()}{" "}
				<Link
					color="inherit"
					href="https://samaritanscout.org/"
					sx={{
						textDecoration: "none",
						":hover": { color: "white" },
					}}
				>
					Samaritan Scout,&nbsp;
				</Link>
				a 501(c)(3) organization. EIN: 92-3607846
			</Typography>
		</Stack>
	);
}

const socialMediaLinks = [
  {
    icon: LinkedInIcon,
    url: 'https://www.linkedin.com/company/samaritan-scout/',
    color: '#3c6292',
  },
  {
    icon: InstagramIcon,
    url: 'https://www.instagram.com/samaritanscout',
    color: '#3c6292',
  }
];

function SocialMediaIcons() {
  return (
    <Stack direction='row' spacing={2}>
      {socialMediaLinks.map((link) => (
        <IconButton
          key={link.url}
          href={link.url}
          target='_blank'
          rel='noopener noreferrer'
          color='primary'
          sx={{
            color: 'white',
            '&:hover': { color: link.color },
            padding: 0,
          }}
        >
          <link.icon sx={{ fontSize: 32 }} />
        </IconButton>
      ))}
    </Stack>
  );
}

function Footer() {
  const theme = MaterialTheme;
  const browserLocation = useLocation();
  const isSearchPage = browserLocation.pathname === '/search';

  return (
      <Box
        sx={{
          display: isSearchPage ? 'none' : 'block',
          width: '100%',
          backgroundColor: `${theme.palette.primary.main}`,
          color: 'white',
        }}
      >
        <Container maxWidth='lg'>
          <Stack
            spacing={4}
            py={5}
            px={{ xs: 4, sm: 6, md: 5, lg: 5, xl: 0 }}
            alignItems={{
              xs: 'center',
              sm: 'center',
              md: 'normal',
            }}
          >
            <Stack
              spacing={{ xs: 5, sm: 5 }}
              direction={{
                xs: 'column',
                sm: 'column',
                md: 'row',
                lg: 'row',
                xl: 'row',
              }}
              justifyContent={'space-between'}
            >
              {/* Title, Slogan and Donation button */}
              <Stack
                width={{
                  xs: '280px',
                  sm: '470px',
                  md: '345px',
                  lg: '345px',
                  xl: '345px',
                }}
                spacing={3}
              >
                <Typography
                  variant='h5'
                  component={'h1'}
                  fontWeight={'bold'}
                  noWrap
                  textTransform={'uppercase'}
                  letterSpacing={2}
                  color='secondary.main'
                  alignItems={'flex-start'}
                >
                  Samaritan Scout
                </Typography>

                <Typography
                  color='#CBCFCB'
                  component={'h3'}
                  alignItems={'flex-start'}
                >
                  A search engine that leverages cutting-edge technology to 
                  connect people with local volunteer activities that make 
                  our world a better place.
                </Typography>
                <Link href='/donate'>
                  <Button
                    variant='contained'
                    sx={{
                      color: 'primary.main',
                      backgroundColor: 'primary.light',
                      height: '50px',
                      maxWidth: '60%',
                      minWidth: '250px',
                      ':hover': {
                        backgroundColor: 'white',
                      },
                    }}
                    size='large'
                  >
                    <Typography
                      variant='body1'
                      fontSize={'20px'}
                      fontWeight={'bold'}
                    >
                      Support Scout
                    </Typography>
                  </Button>
                </Link>
              </Stack>

              {/* Site Map */}
                <Stack
                  direction={{ xs: 'column', sm: 'row' }}
                  spacing={{ xs: 2, sm: 6 }}
                  justifyContent={'flex-start'}
                >
                  {Object.keys(siteMap).map((topic) => (
                    <Stack
                      direction='column'
                      spacing={2}
                      width={'auto'}
                      justifyContent={'flex-start'}
                      key={topic}
                    >
                      <Typography
                        variant='h6'
                        fontWeight={'bold'}
                        noWrap
                        textTransform={'uppercase'}
                      >
                        {topic}
                      </Typography>
                      <Stack direction='column' spacing={1}>
                        {siteMap[topic].map((link) => (
                          <Link
                            target={link.openInNewTab ? '_blank' : '_self'}
                            href={link.link}
                            key={link.label}
                            underline='hover'
                            color='#CBCFCB'
                            sx={{
                              '&:hover': { color: 'secondary.main' },
                            }}
                          >
                            <Typography
                              variant='body1'
                              color='inherit'
                              noWrap
                              sx={{
                                '&:hover': { color: 'secondary.main' },
                              }}
                            >
                              {link.label}
                            </Typography>
                          </Link>
                        ))}
                      </Stack>
                    </Stack>
                  ))}
                </Stack>
            </Stack>

            {/* Outside Links and Copyright */}
            <Stack
              direction={'column'}
              spacing={2}
              width={{ xs: '280px', sm: '470px', md: '600px' }}
            >
              <Stack
                width={{
                  xs: '280px',
                  sm: '300px',
                  md: '345px',
                }}
                spacing={1}
                direction={'row'}
                alignItems={'center'}
              >
                <Typography
                  variant='body1'
                  textTransform={'uppercase'}
                  noWrap
                  letterSpacing={2}
                >
                  Follow Us
                </Typography>
                <SocialMediaIcons />
              </Stack>

              <Copyright />
            </Stack>
          </Stack>
        </Container>
      </Box>
  );
}

export default Footer;
