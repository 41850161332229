import {
  Box,
  CircularProgress,
  Grid,
  LinearProgress,
  Paper,
  Stack,
  TextareaAutosize,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import MaterialTheme from "../components/MaterialTheme";
import Button from "../components/button";
import { fetcher } from "../utils/fetchData";
import { User } from "../utils/auth";
import { Navigate, useNavigate, useSearchParams } from "react-router-dom";
import { useMe } from "../hooks/useResults";
import MetaDataHeader from "../components/MetaDataHeader";
import { Ellipsis } from "../components/Ellipsis";

interface OnboardingItemProps {
  name: string;
  onClick: (name: string) => void;
  isSelected: boolean;
}

const OnboardingItem: React.FC<OnboardingItemProps> = ({
  name,
  onClick,
  isSelected,
}) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
      <Paper
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          padding: 2,
          boxSizing: "border-box",
          height: 100, // Fixed height for all items
          width: "100%",
          transition: "all 0.1s ease-in-out",
          cursor: "pointer",
          "&:hover": {
            backgroundColor: !isSelected
              ? MaterialTheme.palette.grey[100]
              : undefined,
            filter: isSelected ? "brightness(0.9)" : undefined,
          },
          backgroundColor: isSelected
            ? MaterialTheme.palette.primary.light
            : "inherit",
        }}
        elevation={isHovered ? 6 : 2}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        onClick={() => onClick(name)}
      >
        <Typography align="center">{name}</Typography>
      </Paper>
    </Grid>
  );
};

export const Interests = ({
  onNext,
  buttonText,
}: {
  onNext: () => void;
  buttonText?: string;
}) => {
  const [selectedInterests, setSelectedInterests] = useState<string[]>([]);
  const [otherInterests, setOtherInterests] = useState<string>("");
  const [error, setError] = useState<string | null>(null);
  const { data: me, mutate } = useMe();

  useEffect(() => {
    if (me?.interests) {
      setSelectedInterests(me.interests);
    }
    if (me?.interests_text) {
      setOtherInterests(me.interests_text);
    }
  }, [me]);

  const handleInterestClick = (name: string) => {
    setSelectedInterests((prev) => {
      if (prev.includes(name)) {
        return prev.filter((interest) => interest !== name);
      } else {
        return [...prev, name];
      }
    });
  };

  const handleNext = async () => {
    try {
      const data = await fetcher<
        { body: { interests: string[]; interests_text: string } },
        | { success: true; data: Omit<User, "saved"> }
        | { success: false; error: string }
      >("PATCH", "/user", {
        interests: selectedInterests,
        interests_text: otherInterests,
      });

      if (data.success) {
        mutate((prev) => ({ saved: [], ...prev, ...data.data }));
        onNext();
      } else {
        setError(data.error);
      }
    } catch (e) {
      console.error(e);
      setError("An error occurred while saving your interests.");
    }
  };

  const possibleInterests = [
    { name: "Writing" },
    { name: "Research" },
    { name: "Marketing" },
    { name: "Business (operations, entrepreneurialism)" },
    { name: "Finance (investing, banking)" },
    { name: "Teaching/Tutoring" },
    { name: "Information Technology" },
    { name: "Computer Science/Software Development" },
    { name: "Website development" },
    { name: "Graphic Design & Visual Arts" },
    { name: "Hands-on Trades" },
    { name: "Heavy lifting" },
    { name: "Driving" },
    { name: "Accounting" },
    { name: "Sports/Coaching" },
    { name: "Performing Arts & Music" },
    { name: "Medical services" },
    { name: "Photography & video" },
    { name: "Event Planning" },
    { name: "Legal" },
  ];

  if (!me) {
    return (
      <Stack
        direction="column"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <CircularProgress size={40} />
        <Typography mt={2}>
          Loading
          <Ellipsis />
        </Typography>
      </Stack>
    );
  }

  return (
    <Stack
      spacing={2}
      justifyContent={"center"}
      alignItems={"center"}
      maxWidth={1440}
      paddingX={{
        xs: 2,
        sm: 8,
        md: 16,
        lg: 32,
      }}
    >
      <Typography variant="h2">Tell us about your interests</Typography>
      <Typography>
        This will appear in your profile page and in future will be used to
        generate more personalised recommendations
      </Typography>
      <Grid
        container
        spacing={2}
        paddingTop={1}
        paddingRight={2}
        paddingBottom={3}
      >
        {possibleInterests.map((item, index) => (
          <OnboardingItem
            key={index}
            name={item.name}
            onClick={handleInterestClick}
            isSelected={selectedInterests.includes(item.name)}
          />
        ))}
      </Grid>
      <TextField
        placeholder="Any other interests that aren't listed above?"
        multiline
        rows={3}
        maxRows={6}
        fullWidth
        variant="outlined"
        value={otherInterests}
        onChange={(e) => setOtherInterests(e.target.value)}
      />
      {error && (
        <Typography color="error" fontSize={16}>
          {error}
        </Typography>
      )}
      <Stack direction="row" spacing={2}>
        <Button
          size="large"
          bgStyle="primary"
          hoverStyle="dark"
          disabled={selectedInterests.length === 0}
          onClick={handleNext}
        >
          {buttonText || "Continue"}
        </Button>
      </Stack>
    </Stack>
  );
};

export const Skills = ({
  onNext,
  onBack,
  buttonText,
  single,
}: {
  onNext: () => void;
  onBack: () => void;
  buttonText?: string;
  single?: boolean;
}) => {
  const [selectedSkills, setSelectedSkills] = useState<string[]>([]);
  const [otherSkills, setOtherSkills] = useState<string>("");
  const [error, setError] = useState<string | null>(null);
  const { data: me, mutate } = useMe();

  useEffect(() => {
    if (me?.skills) {
      setSelectedSkills(me.skills);
    }
    if (me?.skills_text) {
      setOtherSkills(me.skills_text);
    }
  }, [me]);

  const handleSkillClick = (name: string) => {
    setSelectedSkills((prev) => {
      if (prev.includes(name)) {
        return prev.filter((skill) => skill !== name);
      } else {
        return [...prev, name];
      }
    });
  };

  const handleNext = async () => {
    try {
      const data = await fetcher<
        { body: { skills: string[]; skills_text: string } },
        | { success: true; data: Omit<User, "saved"> }
        | { success: false; error: string }
      >("PATCH", "/user", {
        skills: selectedSkills,
        skills_text: otherSkills,
      });

      if (data.success) {
        // Hack to stop TypeScript from complaining about the saved property
        mutate((prev) => ({ saved: [], ...prev, ...data.data }));
        onNext();
      } else {
        setError(data.error);
      }
    } catch (e) {
      console.error(e);
      setError("An error occurred while saving your skills.");
    }
  };

  const possibleSkills = [
    { name: "Animal Care" },
    { name: "Nature and gardening" },
    { name: "Homeless Services" },
    { name: "Customer service" },
    { name: "Senior Citizen engagement" },
    { name: "Reading and literacy" },
    { name: "STEM teaching" },
    { name: "Working with other volunteers" },
    { name: "Playing with kids" },
    { name: "Community Events" },
    { name: "The Arts" },
    { name: "Mentoring" },
    { name: "Aiding survivors of violence" },
    { name: "Giving tours or presentations" },
    { name: "Administrative or Clerical" },
    { name: "Getting trained to help people" },
  ];

  if (!me) {
    return (
      <Stack
        direction="column"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <CircularProgress size={40} />
        <Typography mt={2}>
          Loading
          <Ellipsis />
        </Typography>
      </Stack>
    );
  }

  return (
    <Stack
      spacing={2}
      justifyContent={"center"}
      alignItems={"center"}
      maxWidth={1440}
      paddingX={{
        xs: 2,
        sm: 8,
        md: 16,
        lg: 32,
      }}
    >
      <Typography variant="h2">Tell us about your skills</Typography>
      <Typography>
        This will appear in your profile page and in future will be used to
        generate more personalised recommendations
      </Typography>
      <Grid
        container
        spacing={2}
        paddingTop={1}
        paddingRight={2}
        paddingBottom={3}
      >
        {possibleSkills.map((item, index) => (
          <OnboardingItem
            key={index}
            name={item.name}
            onClick={handleSkillClick}
            isSelected={selectedSkills.includes(item.name)}
          />
        ))}
      </Grid>
      <TextField
        placeholder="Any other skills that aren't listed above?"
        multiline
        rows={3}
        maxRows={6}
        fullWidth
        variant="outlined"
        value={otherSkills}
        onChange={(e) => setOtherSkills(e.target.value)}
      />
      {error && (
        <Typography color="error" fontSize={16}>
          {error}
        </Typography>
      )}
      <Stack direction="row" spacing={2}>
        {!single && (
          <Button
            size="large"
            bgStyle="secondary"
            hoverStyle="light"
            onClick={onBack}
          >
            Back
          </Button>
        )}
        <Button
          size="large"
          bgStyle="primary"
          hoverStyle="dark"
          disabled={selectedSkills.length === 0}
          onClick={handleNext}
        >
          {buttonText || "Continue"}
        </Button>
      </Stack>
    </Stack>
  );
};

export const Thanks = () => {
  const navigate = useNavigate();

  return (
    <Stack
      spacing={4}
      alignItems="center"
      maxWidth={600}
      margin="auto"
      textAlign="center"
      paddingTop={6}
      paddingBottom={16}
      justifyContent="center"
    >
      <Typography sx={{ fontSize: "4rem", marginBottom: "1.5rem" }}>
        🎉
      </Typography>
      <Typography variant="h2">Thanks for volunteering your info!</Typography>
      <Typography variant="h5">
        You're all set to make the world a better place, one click at a time.
      </Typography>
      <Typography>
        Remember, volunteering is like a dog's wagging tail - it spreads joy
        wherever it goes, and it's always a pawsitive experience!
      </Typography>
      <Button
        size="large"
        bgStyle="primary"
        hoverStyle="dark"
        onClick={() => navigate("/")}
      >
        Find Some Good to Do!
      </Button>
    </Stack>
  );
};

const Onboarding = () => {
  const [page, setPage] = useState(0);
  const { data: me, isLoading } = useMe();
  const [params] = useSearchParams();
  const navigate = useNavigate();

  useEffect(() => {
    const pageParam = params.get("page");
    const newPage = pageParam
      ? Math.max(0, ["interests", "skills"].indexOf(pageParam))
      : 0;
    setPage(newPage);
  }, [params]);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "instant" as ScrollBehavior });
  }, [page]);

  const progress = me ? [0, 50, 100][page] : 0;
  const showProgress = me && (!me.interests.length || !me.skills.length);
  const isEdit = !!me?.interests.length && !!me?.skills.length;

  const handleNext = () => {
    if (!isEdit) {
      setPage((p) => p + 1);
    } else {
      navigate(`/me?page=profile`);
    }
  };

  const handleBack = () => {
    setPage((p) => p - 1);
  };

  if (!me && !isLoading) {
    return <Navigate to="/" />;
  }

  return (
    <>
      <MetaDataHeader title="Onboarding - Add Interests" />
      <Stack
        paddingBottom={12}
        paddingTop={showProgress ? 12 : 6}
        justifyContent="center"
        alignItems="center"
      >
        {showProgress && (
          <Box
            sx={{
              width: 200,
              maxWidth: "80%",
              marginBottom: 6,
              position: "relative",
            }}
          >
            <LinearProgress
              variant="determinate"
              value={progress}
              sx={{
                height: 8,
                borderRadius: 4,
                backgroundColor: MaterialTheme.palette.grey[200],
                "& .MuiLinearProgress-bar": {
                  backgroundColor: MaterialTheme.palette.primary.light,
                  transition: "none",
                },
              }}
            />
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                position: "absolute",
                top: "50%",
                left: 0,
                right: 0,
                transform: "translateY(-50%)",
                zIndex: 1,
              }}
            >
              {[0, 1, 2].map((pageIndex) => (
                <Box
                  key={pageIndex}
                  sx={{
                    width: 20,
                    height: 20,
                    borderRadius: "50%",
                    backgroundColor:
                      page >= pageIndex
                        ? MaterialTheme.palette.primary.light
                        : MaterialTheme.palette.grey[200],
                  }}
                />
              ))}
            </Box>
          </Box>
        )}

        {page === 0 && (
          <Interests
            onNext={handleNext}
            buttonText={isEdit ? "Save Changes" : "Continue"}
          />
        )}
        {page === 1 && (
          <Skills
            onNext={handleNext}
            onBack={handleBack}
            buttonText={isEdit ? "Save Changes" : "Continue"}
            single={isEdit}
          />
        )}
        {page === 2 && <Thanks />}
      </Stack>
    </>
  );
};

export default Onboarding;
