import MetaDataHeader from "../components/MetaDataHeader";
import { Link, Stack, TextField, Typography } from "@mui/material";
import Button from "../components/button";
import React from "react";
import theme from "../components/MaterialTheme";
import { isValidEmail } from "../utils/utils";
import { supabase } from "../utils/auth";

const ForgotPassword = () => {
  const [email, setEmail] = React.useState("");
  const [sent, setSent] = React.useState(false);

  const resetPassword = async () => {
    const { data, error } = await supabase.auth.resetPasswordForEmail(email, {
      redirectTo: process.env.REACT_APP_ROOT_URL + "/reset_password",
    });
    if (error) {
      console.error(error);
    } else {
      setSent(true);
    }
  };

  if (sent) {
    return (
      <>
        <MetaDataHeader title="Reset your password" />
        <Stack
          sx={{
            height: "calc(100vh - 75px)",
          }}
          alignItems={"center"}
          justifyContent={"center"}
        >
          <Stack
            sx={{
              width: { xs: "100%", sm: "24rem" },
              p: { xs: 2, sm: 4 },
              boxShadow: {
                xs: "none",
                sm: "0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1)",
              },
              borderRadius: "12px",
            }}
            spacing={2}
            textAlign={"center"}
            boxSizing={"border-box"}
          >
            <Typography variant={"h2"}>Email Sent</Typography>
            <Typography>
              If an account associated with this email exists, you should
              receive an email with a link to reset your password.
            </Typography>
            <Link href={"/login"}>Return to login</Link>
          </Stack>
        </Stack>
      </>
    );
  }

  return (
    <>
      <MetaDataHeader title="Reset your password" />
      <Stack
        sx={{
          height: "calc(100vh - 75px)",
        }}
        alignItems={"center"}
        justifyContent={"center"}
      >
        <Stack
          sx={{
            width: { xs: "100%", sm: "24rem" },
            p: { xs: 2, sm: 4 },
            boxShadow: {
              xs: "none",
              sm: "0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1)",
            },
            borderRadius: "12px",
          }}
          spacing={2}
          textAlign={"center"}
          boxSizing={"border-box"}
        >
          <Typography variant={"h2"}>Reset your password</Typography>
          <TextField
            variant="outlined"
            placeholder="Email"
            fullWidth={true}
            value={email}
            type={"email"}
            onChange={(e) => {
              setEmail(e.target.value);
            }}
            sx={{
              borderRadius: "10px",
              backgroundColor: "#fff",
              input: { color: "black" },
            }}
          />
          <Button
            size={"large"}
            bgStyle={"primary"}
            hoverStyle={"dark"}
            width={"100%"}
            onClick={resetPassword}
            disabled={!isValidEmail(email)}
          >
            Send reset link
          </Button>
          <Typography textAlign={"left"} color={theme.palette.grey[600]}>
            If an account associated with this email exists, we'll send you an
            email with a link to reset your password.
          </Typography>
        </Stack>
      </Stack>
    </>
  );
};

export default ForgotPassword;
