import { Stack, Typography, useMediaQuery } from '@mui/material';
import { testimonial } from '../data/data';
import { Icons } from './Icons';
import MaterialTheme from './MaterialTheme';
import Carousel from 'react-material-ui-carousel';

const TestimonialCarousel = () => {
  const isMobile = useMediaQuery(MaterialTheme.breakpoints.down("md"));

  return (
    <Carousel
      interval={7000}
      navButtonsAlwaysVisible={isMobile ? false : true}
      navButtonsAlwaysInvisible={isMobile ? true : false}
      animation='slide'
      navButtonsProps={{
        style: {
          backgroundColor: 'white',
          border: '2px solid grey',
          color: 'grey',
          opacity: 0.4,
        },
      }}
      indicatorContainerProps={{
        style: {
          marginTop: '0px',
          marginBottom: '5px',
        },
      }}
      sx={{
        backgroundColor: 'white',
        boxShadow:
          '0 0px 15px -3px rgb(0 0 0 / 0.1), 0 0px 6px -4px rgb(0 0 0 / 0.1);',
        borderRadius: '15px',
        width: {
          xs: '90%',
          sm: '90%',
          md: '90%',
          lg: '90%',
          xl: '70%',
        },
      }}
    >
      {testimonial.map((item) => (
        <Stack
          width={'100%'}
          height={'100%'}
          alignItems={'center'}
          key={item.title}
        >
          <Stack
            width={{
              xs: '100%',
              sm: '90%',
            }}
            direction={{
              xs: 'column',
              sm: 'column',
              md: 'row',
            }}
            height={{
              xs: '520px',
              sm: '550px',
              md: '400px',
            }}
            spacing={{ xs: 2, sm: 2, md: 4, lg: 6, xl: 8 }}
            py={{ xs: 2, sm: 2, md: 4, lg: 6 }}
            px={{ xs: 3, sm: 6 }}
          >
            <Stack
              width={{
                xs: '100%',
                sm: '100%',
                md: '100%',
                lg: '50%',
              }}
              alignItems={'center'}
            >
              <Typography
                variant='h2'
                align='center'
                height={{
                  xs: '50px',
                  sm: '70px',
                  md: '80px',
                }}
              >
                {item.title}
              </Typography>
              <Stack
                sx={{
                  backgroundColor: 'primary.light',
                  borderRadius: '50%',
                }}
                display={{
                  xs: 'none',
                  sm: 'block',
                }}
                width={{
                  xs: '150px',
                  sm: '150px',
                  md: '200px',
                }}
                height={{
                  xs: '150px',
                  sm: '150px',
                  md: '200px',
                }}
                p={{ xs: 4, sm: 4, md: 5 }}
              >
                <img
                  src={item.imagePath}
                  alt=''
                  width={'100%'}
                  height={'100%'}
                ></img>
              </Stack>
            </Stack>

            <Stack
              width={{
                xs: '100%',
                sm: '100%',
                md: '100%',
                lg: '50%',
              }}
              spacing={2}
            >
              <Stack alignItems={'center'}>
                <Icons.Quote
                  size={48}
                  color={MaterialTheme.palette.primary.light}
                ></Icons.Quote>
              </Stack>
              <hr></hr>
              <Stack 
                alignSelf={'center'} 
                alignItems={'left'}
                width={isMobile ? '90%' : '100%'}
                spacing={2}
              >
                <Typography variant='body1' fontStyle={'italic'}>
                  "{item.quote}"
                </Typography>
                <Typography variant='body1' fontSize={17}>
                  - {item.author}
                </Typography>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      ))}
    </Carousel>
  );
};

export default TestimonialCarousel;
