import { Box, ButtonBase, useMediaQuery } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import MaterialTheme from "../components/MaterialTheme";
import MetaDataHeader from "../components/MetaDataHeader";
import Button from "@mui/material/Button";
import Filter from "../components/filter";
import SearchResultList from "../components/SearchResultList";
import useSWRInfinite from "swr/infinite";
import {
  SearchRes,
  fetcher,
  getFilterQueryObj,
  getFilterQueryString,
} from "../utils/fetchData";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Endpoints } from "../data/endpoints";
import { useCoordinatesCache } from "../hooks/useCoordinatesCache";
import { CleanedFilterRequest, UserCoordinates } from "../data/type";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import { removeEmpty, SearchBar } from "../components/SearchBar";
import useSWR from "swr";
import { useMe, useResults } from "../hooks/useResults";

const SET_SIZE = 20;

/**
 *  Manages the search page business logic and UI.
 */
const Search = () => {
  const isMobile = useMediaQuery(MaterialTheme.breakpoints.down("md"));
  const [parentKeywords, setParentKeywords] = useState<string>("");
  const [filterEnabled, setFilterEnabled] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [location, setLocation] = useState<string | null>(null);
  const { data: me } = useMe();

  const navigate = useNavigate();
  useEffect(() => {
    const fetchCoordinates = async () => {
      const inputLocation = searchParams.get("location");
      if (inputLocation !== location && inputLocation) {
        setLocation(inputLocation);
      }
    };

    fetchCoordinates();
  }, [searchParams]);

  useEffect(() => {
    if (!searchParams.has("location") || !searchParams.has("distance")) {
      const userAddress = me ? `${me.city}, ${me.state}` : "Springfield, NJ";
      setSearchParams({
        location: userAddress,
        distance: "20",
      });
    }
  }, [searchParams, me]);

  const getKey = (excludeMinAge?: boolean) => {
    // Get the location from the URL query params
    const inputLocation = searchParams.get("location");

    // If the location is provided and the coordinates are not found in the cache,
    // check to see if the last response has them

    const filterObj = removeEmpty(
      getFilterQueryObj({
        searchParams,
      })
    ) as CleanedFilterRequest;

    const filter = new URLSearchParams(
      getFilterQueryString(filterObj) as unknown as Record<string, string>
    );

    if (excludeMinAge) {
      filter.delete("min_age");
    }

    return "?" + filter.toString();
  };

  if (!location) {
    return null;
  }

  return (
    <>
      <MetaDataHeader title="Search" description="Search for Good Samaritans" />

      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          overscrollBehaviorY: "contain",
          height: "calc(100% - 75px)",
        }}
      >
        <Filter
          filterEnabled={filterEnabled}
          setFilterEnabled={setFilterEnabled}
          // Confused about why this is here
          queryKey={() => getKey(true)}
          additionalQuery={{
            keywords: parentKeywords,
            location: location,
          }}
        />
        <Box
          sx={{
            width: isMobile ? "100%" : "75%",
            right: 0,
            marginLeft: isMobile ? 0 : "auto",
            zIndex: 0,
            display: "flex",
            flexDirection: "column",
            overflow: isMobile ? "scroll" : "unset",
          }}
        >
          <SearchBar setKeywords={setParentKeywords} />
          <Box
            sx={{
              padding: { xs: 1, sm: 2, md: 4 },
              overflow: isMobile ? "unset" : "scroll",
            }}
          >
            <SearchResultList queryKey={getKey} />
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          position: "fixed",
          width: "100vw",
          bottom: "32px",
          justifyContent: "center",
          zIndex: 50,
          display: "flex",
        }}
      >
        <Button
          onClick={() => {
            setFilterEnabled(!filterEnabled);
          }}
          sx={{
            display: isMobile ? "block" : "none",
            backgroundColor: "primary.main",
            transition: "opacity .3s",
            "&:hover": {
              backgroundColor: "primary.main",
              opacity: 0.8,
            },
            color: "secondary.main",
            fontSize: "1em",
            padding: "0.5rem 1rem",
            boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
            textAlign: "center",
          }}
        >
          {"Filters"}
        </Button>
      </Box>
    </>
  );
};

export default Search;
