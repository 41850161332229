import CloseIcon from "@mui/icons-material/Close";
import { BiSolidQuoteAltLeft } from "react-icons/bi";
import { MdLocationPin } from "react-icons/md";
import { Warning } from "@mui/icons-material";

export const Icons = {
  Close: CloseIcon,
  Quote: BiSolidQuoteAltLeft,
  Pin: MdLocationPin,
  Warning: Warning,
};
