import useSWR from "swr";
import { Endpoints } from "../data/endpoints";

interface FetchedFilterObject {
  [key: string]: string;
}

interface FetchedFilterObjects {
  cause: string[];
  skill: string[];
}

export interface FilterMaps {
  cause: string[];
  skill: string[];
}

/**
 * React hook that fetches enums to ensure that our UI matches what is available
 * as options in the database.
 * @returns object holding Maps for location preference, skills, and causes.
 */
export function useFilterMaps(): {
  filterMaps?: FilterMaps;
} {
  const causesList = [
    "Agriculture & Rural Areas",
    "Animals",
    "Arts & Culture",
    "Children & Youth",
    "Disability",
    "Disaster Relief",
    "Education",
    "Environment & Nature",
    "Finance & Business",
    "Immigrants & Refugees",
    "Justice & Legal Assistance",
    "LGBTQ+",
    "Health & Medical Research",
    "Poverty",
    "Race & Ethnicity",
    "Religion & Spirituality",
    "Science & Technology",
    "Seniors",
    "Sports & Recreation",
    "Veterans & Military Families",
    "Women",
  ];

  const skillsList = [
    "Administrative",
    "Arts",
    "Cooking",
    "Delivery/Driving",
    "Financial",
    "Healthcare/Medical",
    "Interpersonal",
    "Legal",
    "Communications/Marketing",
    "Nature/Animal",
    "Skilled Craft or Trade",
    "Sports & Recreation",
    "Teach, Mentor, Speak",
    "Technology",
    "Writing/Research",
    "Other",
  ];

  return {
    filterMaps: {
      cause: causesList,
      skill: skillsList,
    },
  };
}
