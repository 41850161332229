import React, { useEffect, useState } from "react";
import { EventData, UserCoordinates } from "../data/type";
import SearchResult from "./SearchResult";
import { NoResults } from "./NoResults";
import InfiniteScroll from "react-infinite-scroll-component";
import Separator from "./Separator";
import {
  CircularProgress,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  NativeSelect,
  Select,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import Box from "@mui/material/Box";
import Pagination from "@mui/material/Pagination";
import theme from "../components/MaterialTheme";
import useSWR from "swr";
import { useResults } from "../hooks/useResults";
import MaterialTheme from "./MaterialTheme";
import { VO } from "../utils/fetchData";
import { Ellipsis } from "./Ellipsis";
import { useAtom } from "jotai";
import { atomWithStorage } from "jotai/utils";

interface SearchResultListProps {
  queryKey: () => string;
}

type SortVal = "distance" | "relevance" | "alphabetical";

const sortAtom = atomWithStorage<SortVal | null>("sort", null);

/**
 * Display of search results only. Business logic for fetching data
 * is handled by the parent component, making state management easier.
 */
const SearchResultList: React.FC<SearchResultListProps> = ({ queryKey }) => {
  const isMobile = useMediaQuery(MaterialTheme.breakpoints.down("md"));
  const { data, isLoading, error } = useResults(queryKey());
  const [sort, setSort] = useAtom(sortAtom);
  const [page, setPage] = useState(1);

  const results = data?.results ?? [];

  useEffect(() => {
    if (!sort && data) {
      if (data.query.keywords) {
        setSort("relevance");
      } else {
        setSort("distance");
      }
    }
    setPage(1);
  }, [data, sort]);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [page]);

  const sortFn = (a: VO, b: VO) => {
    switch (sort) {
      case "distance":
        const aMinDistance = Math.min(...a.locations.map((l) => l.distance));
        const bMinDistance = Math.min(...b.locations.map((l) => l.distance));
        return aMinDistance - bMinDistance;
      case "relevance":
        return b.similarity - a.similarity;
      case "alphabetical":
        return a.org_name.localeCompare(b.org_name);
      default:
        return 0;
    }
  };

  return (
    <div style={{ overflow: "auto", zIndex: -4 }}>
      <Stack
        sx={{
          mb: 2,
          display: "flex",
          width: "100%",
          justifyContent: "space-between",
          alignItems: "center",
          paddingX: { xs: 2, sm: 0 },
          paddingTop: { xs: 1, sm: 0 },
          boxSizing: "border-box",
          flexDirection: {
            xs: "column",
            sm: "row",
          },

        }}
        spacing={1}
      >
        <Typography color={(theme) => theme.palette.grey["600"]}>
          {isLoading || !data
            ? ""
            : `${results.length} result${results.length === 1 ? "" : "s"} (out of ${data.count})`}
        </Typography>
        {results.length > 0 && (
          <Box
            sx={{
              minWidth: 120,
              display: "flex",
            }}
          >
            <Typography pr={1} color={(theme) => theme.palette.grey["600"]}>
              Sort by:
            </Typography>
            <NativeSelect
              inputProps={{
                name: "sort",
                id: "sort-by",
              }}
              value={sort}
              size="small"
              onChange={(e) => {
                setSort(e.target.value as SortVal);
              }}
              sx={{
                "&:before": {
                  borderBottom: "none",
                },
                "&:after": {
                  borderBottom: "none",
                },
                "&:hover:not(.Mui-disabled):before": {
                  borderBottom: "none",
                },
                "& > select": {
                  paddingLeft: "4px"
                }
              }}
            >
              <option value={"distance"}>Distance</option>
              {data?.query.keywords && (
                <option value={"relevance"}>Relevance</option>
              )}
              <option value={"alphabetical"}>Org. Name</option>
            </NativeSelect>
          </Box>
        )}
      </Stack>
      <Box
        // Padding here prevents box shadow horizontal clipping on search result card
        sx={{
          padding: "4px",
          alignItems: "center",
          display: "flex",
          flexDirection: "column",
        }}
      >
        {results
          .sort(sortFn)
          .slice(page * 20 - 20, page * 20)
          .map((result) => (
            <SearchResult vo={result} key={result.id} />
          ))}
        {!isLoading && results.length === 0 && <NoResults />}
        {isLoading && (
          <Stack
            height={"100%"}
            display={"flex"}
            flexDirection={"column"}
            justifyContent={"center"}
            alignItems={"center"}
            spacing={4}
            py={"25vh"}
            color={theme.palette.grey["600"]}
          >
            <CircularProgress color={"inherit"} />
            <Typography color={(theme) => theme.palette.grey["600"]}>
              Looking for opportunities near you
              <Ellipsis />
            </Typography>
          </Stack>
        )}
        {!isLoading && results.length > 0 && (
          <Pagination
            count={Math.ceil(results.length / 20)}
            page={page}
            size={"large" as "large"}
            onChange={(e, p) => setPage(p)}
            sx={{
              marginTop: 2,
              marginBottom: isMobile ? 10 : 0,
            }}
          />
        )}
      </Box>
    </div>
  );
};

export default SearchResultList;
