import { Box, Container, Grid, Stack, Typography } from '@mui/material';
import PersonCard from '../components/person-card';
import PeoplePageData from '../data/people.json';
import DuoLinks from '../components/duo-links';

import { useEffect } from 'react';

import MetaDataHeader from '../components/MetaDataHeader';
import { WhyVolunteer } from '../components/WhyVolunteer';

interface PersonInfo {
  title: string;
  linkedin: string;
  image: string;
  description: string;
}

const boardMembersObject: Record<string, PersonInfo> =
  PeoplePageData['boardMembers'];
const currentVolunteersObject: Record<string, PersonInfo> =
  PeoplePageData['currentVolunteers'];
const aiCreatorsObject: Record<string, PersonInfo> =
  PeoplePageData['aiCreators'];
const pastVolunteersObject: Record<string, PersonInfo> =
	PeoplePageData["pastVolunteers"];

function People() {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'instant' as ScrollBehavior });
  }, []);

  return (
		<>
			<MetaDataHeader title="People" description="the team building search engine Scout" />
			<Stack>
				<Stack
					py={10}
					px={4}
					alignItems={"center"}
					justifyContent={"center"}
					bgcolor={"primary.light"}
				>
					<Typography
						variant="subtitle2"
						gutterBottom
						sx={{
							textAlign: "center",
						}}
					>
						Committed to building a better tomorrow.
					</Typography>
				</Stack>
				<Container
					maxWidth="xl"
					sx={{ paddingBottom: "100px", paddingTop: "50px" }}
				>
					<Stack>
						{/* Board Members & Developing Team Members */}
						<Stack gap={{ xs: 10, sm: 10, md: 15, lg: 15, xl: 15 }}>
							{/* AI Creators */}
							<Stack alignItems={"center"} spacing={4}>
								<Typography
									variant="h1"
									gutterBottom
									sx={{
										textAlign: "center",
									}}
								>
									Meet The Team That Built Scout’s AI-Powered Search!
								</Typography>
								<Box>
									<Grid container spacing={5} justifyContent={{ md: "center" }}>
										{Object.keys(aiCreatorsObject).map((key) => (
											<Grid
												item
												xs={6}
												sm={4}
												md={3}
												lg={2.5}
												xl={2.5}
												key={key}
											>
												<PersonCard
													type={"boardMembers"}
													title={aiCreatorsObject[key].title}
													name={key}
													description={aiCreatorsObject[key].description}
													image={aiCreatorsObject[key].image}
													link={aiCreatorsObject[key].linkedin}
												/>
											</Grid>
										))}
									</Grid>
								</Box>
							</Stack>

							{/* Current Interns */}
							<Stack alignItems={"center"} gap={4}>
								<Typography
									variant="h1"
									gutterBottom
									sx={{
										textAlign: "center",
										marginTop: "-70px",
									}}
								>
									Meet Our Volunteer Team
								</Typography>
								<Box>
									<Grid
										container
										spacing={1}
										justifyContent={{ md: "center" }}
										rowSpacing={3}
									>
										{Object.keys(currentVolunteersObject).map((key) => (
											<Grid item xs={6} sm={4} md={3} lg={2.1} xl={2.1} key={key}>
												<PersonCard
													type={"volunteers"}
													title={currentVolunteersObject[key].title}
													name={key}
													description={currentVolunteersObject[key].description}
													image={currentVolunteersObject[key].image}
													link={currentVolunteersObject[key].linkedin}
												/>
											</Grid>
										))}
									</Grid>
								</Box>
							</Stack>
							{/* Past Interns */}
							<Stack alignItems={"center"} gap={4}>
								<Typography
									variant="h1"
									gutterBottom
									sx={{
										textAlign: "center",
										marginTop: "-70px",
									}}
								>
									Past Volunteers
								</Typography>
								<Box>
									<Grid container spacing={1} sx={{}} rowSpacing={3}>
										{Object.keys(pastVolunteersObject).map((key) => (
											<Grid item xs={6} sm={4} md={3} lg={2} xl={2} key={key}>
												<PersonCard
													type={"volunteers"}
													title={pastVolunteersObject[key].title}
													name={key}
													description={pastVolunteersObject[key].description}
													image={pastVolunteersObject[key].image}
													link={pastVolunteersObject[key].linkedin}
												/>
											</Grid>
										))}
									</Grid>
								</Box>
							</Stack>
							{/* Board Members */}
							<Stack alignItems={"center"} spacing={4}>
								<Typography
									variant="h1"
									gutterBottom

									sx={{
										textAlign: "center",
										marginTop: "-70px",
									}}
								>
									Meet Our Board Members
								</Typography>
								<Box>
									<Grid container spacing={5} justifyContent={{ md: "center" }}>
										{Object.keys(boardMembersObject).map((key) => (
											<Grid
												item
												xs={6}
												sm={4}
												md={3}
												lg={2.5}
												xl={2.5}
												key={key}
											>
												<PersonCard
													type={"boardMembers"}
													title={boardMembersObject[key].title}
													name={key}
													description={boardMembersObject[key].description}
													image={boardMembersObject[key].image}
													link={boardMembersObject[key].linkedin}
												/>
											</Grid>
										))}
									</Grid>
								</Box>
							</Stack>
						</Stack>
					</Stack>
				</Container>

				{/* Why volunteer with us? */}
				<WhyVolunteer />

				<Container
					maxWidth="xl"
					sx={{ paddingBottom: "100px", paddingTop: "50px" }}
				>
					{/* Join the Team & Support Us Links */}
					<DuoLinks />
				</Container>
			</Stack>
		</>
	);
}

export default People;
