import { useEffect } from 'react';
import Carousel from 'react-material-ui-carousel';

import {
  Avatar,
  Box,
  Container,
  Grid,
  Link,
  Stack,
  Typography,
  useMediaQuery,
} from '@mui/material';

import SearchIcon from '@mui/icons-material/Search';
import VolunteerActivismIcon from '@mui/icons-material/VolunteerActivism';
import SouthAmericaIcon from '@mui/icons-material/SouthAmerica';
import SmartToyIcon from '@mui/icons-material/SmartToy';

import theme from '../components/MaterialTheme';
import CustomSection from '../components/custom-section';
import Button from '../components/button';
import color_theme from '../components/Theme';
import CustomContainer from '../components/custom-section';
import DuoLinks from '../components/duo-links';

import PhaseData from '../data/phases.json';
import MetaDataHeader from '../components/MetaDataHeader';

type Phase = {
  phase?: string;
  title: string;
  points: string[];
  done?: boolean;
};

const phasesObject: Record<string, Phase> = PhaseData;

function About() {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'instant' as ScrollBehavior });
  }, []);

  let imgSize;

  const isXs = useMediaQuery(theme.breakpoints.only('xs'));
  const isSm = useMediaQuery(theme.breakpoints.only('sm'));
  const isMd = useMediaQuery(theme.breakpoints.only('md'));
  const isLg = useMediaQuery(theme.breakpoints.only('lg'));
  const isXl = useMediaQuery(theme.breakpoints.only('xl'));

  const isSmBreakpointOrBelow = useMediaQuery(theme.breakpoints.down('sm'));

  if (isXs) {
    imgSize = '200px';
  } else if (isSm) {
    imgSize = '230px';
  } else if (isMd) {
    imgSize = '250px';
  } else if (isLg) {
    imgSize = '300px';
  } else if (isXl) {
    imgSize = '350px';
  }

  return (
    <>
      <MetaDataHeader title = "About" description='About the Good Samaritans' />
        <Grid
          container
          width={'100%'}
          height={{
            xs: '520px',
            sm: '550px',
            md: '370px',
            lg: '440px',
            xl: '520px',
          }}
        >
          <Grid
            item
            height={{ xs: '52%', sm: '65%', md: '100%' }}
            xs={12}
            sm={12}
            md={7}
            lg={8}
          >
            <img
              src='/images/about-page/hero-about.jpg'
              alt=''
              height={'100%'}
              width={'100%'}
              style={{
                objectFit: 'cover',
              }}
            ></img>
          </Grid>
          <Grid
            item
            height={{ xs: '48%', sm: '44%', md: '100%' }}
            bgcolor={theme.palette.primary.light}
            xs={12}
            sm={12}
            md={5}
            lg={4}
            px={{
              xs: 2,
              sm: 4,
            }}
          >
            <Stack
              height={'100%'}
              width={'100%'}
              alignItems={'center'}
              justifyContent={'center'}
              spacing={{
                xs: 3,
                sm: 3,
                md: 5,
              }}
            >
              <Typography variant='h1' align='center' gutterBottom>
                We live in a world filled with kind people who have enthusiasm
                and skills to share.
              </Typography>
              <Typography
                align='center'
                gutterBottom
                fontSize={'36px'}
                fontWeight={900}
              >
                WE'RE HERE TO EMPOWER THEM
              </Typography>
            </Stack>
          </Grid>
        </Grid>

        <CustomSection bgStyle='white' marginStyle='normal'>
          <Stack>
            <Grid container alignItems='center'>
              <Grid item xs={12} sm={6} justifyContent={'left'}>
                <Typography
                  variant='h2'
                  style={{ lineHeight: 1.5 }}
                  align='left'
                  marginBottom={5}
                >
                  <span style={{ color: theme.palette.text.secondary }}>
                    Modern technology suggests products to{' '}
                  </span>
                  <span style={{ color: color_theme.fontColor }}>buy</span>
                  <span style={{ color: theme.palette.text.secondary }}>
                    , media to{' '}
                  </span>
                  <span style={{ color: color_theme.fontColor }}>consume</span>
                  <span style={{ color: theme.palette.text.secondary }}>
                    , and people to{' '}
                  </span>
                  <span style={{ color: color_theme.fontColor }}>follow</span>
                  <span style={{ color: theme.palette.text.secondary }}>.</span>
                </Typography>
              </Grid>
              <Grid item xs={0} sm={1}></Grid>
              <Grid item xs={12} sm={5}>
                <Stack width={'100%'} height={'100%'} alignItems={'center'}>
                  <img
                    src='/images/about-page/logos.png'
                    alt=''
                    width={imgSize}
                  />
                </Stack>
              </Grid>
            </Grid>
            <Grid container alignItems='center'>
              <Grid
                item
                xs={12}
                sm={5}
                order={{
                  xs: 3,
                  sm: 1,
                }}
              >
                <Stack width={'100%'} height={'100%'} alignItems={'center'}>
                  <img
                    src='/images/about-page/overwhelmed-volunteer.png'
                    alt=''
                    width={imgSize}
                  />
                </Stack>
              </Grid>
              <Grid item xs={0} sm={1} order={2}></Grid>
              <Grid
                item
                xs={12}
                sm={6}
                justifyContent={'right'}
                order={{
                  xs: 1,
                  sm: 3,
                }}
              >
                <Typography
                  variant='h2'
                  style={{ lineHeight: 1.5 }}
                  align='left'
                  marginBottom={5}
                >
                  <span style={{ color: theme.palette.text.secondary }}>
                    But it has not been harnessed to suggest{' '}
                  </span>
                  <span style={{ color: color_theme.fontColor }}>
                    volunteer experiences
                  </span>
                  <span style={{ color: theme.palette.text.secondary }}>
                    {' '}
                    that best fit our abilities and interests...{' '}
                  </span>
                </Typography>
              </Grid>
            </Grid>
            <Typography variant='h2' align='center' gutterBottom>
              <strong>UNTIL NOW.</strong>
            </Typography>
          </Stack>
        </CustomSection>

        <Stack
          spacing={{
            xs: 3,
            sm: 5,
          }}
          bgcolor={color_theme.accentBgColor}
          py={6}
          px={{
            xs: 5,
            sm: 5,
            md: 13,
            lg: 25,
            xl: 30,
          }}
        >
          <Typography
            variant='h1'
            color={color_theme.primaryFontColor}
            align='left'
          >
            OUR VISION
          </Typography>
          <Typography
            variant='h3'
            style={{ lineHeight: 1.5 }}
            color='text.secondary'
            align='left'
          >
            We aim to create a better world by inspiring a culture of service
            and enabling individuals everywhere to contribute positively to
            their community and beyond.
          </Typography>
        </Stack>
        <Stack
          spacing={{
            xs: 3,
            sm: 5,
          }}
          bgcolor={'white'}
          py={6}
          px={{
            xs: 5,
            sm: 5,
            md: 13,
            lg: 25,
            xl: 30,
          }}
        >
          <Typography
            variant='h1'
            color={color_theme.primaryFontColor}
            align='left'
            marginBottom={5}
          >
            OUR MISSION
          </Typography>
          <Typography
            variant='h3'
            style={{ lineHeight: 1.5 }}
            color='text.secondary'
            align='left'
          >
            We harness cutting-edge technology to connect individuals with
            meaningful and impactful service activities.
          </Typography>
        </Stack>

        <PhaseCarousel
          isXs={isXs}
          isSmBreakpointOrBelow={isSmBreakpointOrBelow}
        />
        <OurIntention />
        <Container maxWidth={'xl'} sx={{ paddingY: 10 }}>
          <DuoLinks />
        </Container>

        <CustomContainer bgStyle='primary' paddingY='large'>
          <Typography
            variant='h2'
            color={color_theme.primaryFontColor}
            align='center'
          >
            Meet the People making this Vision a Reality
          </Typography>
          <Box sx={{ height: '20px ' }}></Box>
          <Link href='/people'>
            <Button
              size='medium'
              bgStyle='secondary'
              hoverStyle='light'
              // startIcon={<AddIcon />}
            >
              <Typography fontWeight={'bold'}>Our Team</Typography>
            </Button>
          </Link>
        </CustomContainer>
        <CustomContainer bgStyle='paper' paddingY='medium' marginStyle='narrow'>
        
          <Stack
            spacing={{ xs: 2, sm: 5 }}
            px={{
              xs: 3,
              sm: 0,
              md: 0,
              lg: 12,
              xl: 17,
            }}
          >
            <Typography
              variant='h1'
              color={color_theme.primaryFontColor}
              align='left'
              marginBottom={5}
            >
              OUR STORY
            </Typography>
            <Typography
              variant='h3'
              style={{ lineHeight: 1.5 }}
              color='text.secondary'
              align='left'
            >
              Samaritan Scout's co-founders, Dvora and Will, have long recognized the challenges of finding volunteer opportunities. Directory websites have limited offerings since they require manual data entry, and premiere search engines ignore small local nonprofit websites. With volunteerism rates decreasing every year and technological innovations improving every day, they agreed it was long overdue that technology made it easier for people to positively impact their communities. 
            </Typography>
            <Typography
              variant='h3'
              style={{ lineHeight: 1.5 }}
              color='text.secondary'
              align='left'
            >
              It wasn't until Dvora took time off from her career in edtech to battle a stage IV cancer diagnosis that she could consider this problem more fully. In the summer of 2023, she and Will recruited volunteers to set their ideas in motion. While the backend team researched paths forward, Dvora collaborated with developers and UX experts to design and build a user-friendly front end. In the summer of 2024, Will fully defined the Scout architecture. He brought on a few of his brightest peers to develop and launch an AI-guided search engine platform with an automated data pipeline.
            </Typography>
            <Typography
              variant='h3'
              style={{ lineHeight: 1.5 }}
              color='text.secondary'
              align='left'
            >
              As the team gradually scales Scout to serve the entire United States and additional regions worldwide, we look forward to developing a community of volunteers, organizations, and agencies who can unite and make a positive difference in their communities.
            </Typography>
          </Stack>
        </CustomContainer>
    </>
  );
}

function returnIcon(index: number) {
  switch (index) {
    case 0:
      return <SearchIcon fontSize='inherit' />;
    case 1:
      return <VolunteerActivismIcon fontSize='inherit' />;
    case 2:
      return <SmartToyIcon fontSize='inherit' />;
    case 3:
      return <SouthAmericaIcon fontSize='inherit' />;
    default:
      return <SearchIcon fontSize='inherit' />;
  }
}

const PhaseCarousel = ({
  isXs,
  isSmBreakpointOrBelow,
}: {
  isXs: boolean;
  isSmBreakpointOrBelow: boolean;
}) => {
  return (
    <CustomContainer bgStyle='paper' paddingY='medium' marginStyle='narrow'>
      <Stack
        spacing={{
          xs: 5,
          sm: 10,
        }}
      >
        <Stack
          spacing={{ xs: 2, sm: 5 }}
          px={{
            xs: 3,
            sm: 0,
            md: 0,
            lg: 12,
            xl: 17,
          }}
        >
          <Typography variant='h1' color={color_theme.fontColor} align='left'>
            OUR PLAN
          </Typography>
          <Typography
            variant='h3'
            style={{ lineHeight: 1.5 }}
            color='text.secondary'
            align='left'
          >
            We’re building a new kind of search engine that applies cutting edge AI techniques on top of state of the art technologies.
          </Typography>
        </Stack>

        <Stack width={'100%'} alignItems={'center'} pb={5}>
          <Carousel
            interval={7000}
            navButtonsAlwaysVisible={isXs ? false : true}
            navButtonsAlwaysInvisible={isXs ? true : false}
            animation='slide'
            navButtonsProps={{
              style: {
                backgroundColor: 'white',
                border: '2px solid grey',
                color: 'grey',
                opacity: 0.4,
              },
            }}
            indicatorContainerProps={{
              style: {
                marginTop: '0px',
                marginBottom: '5px',
              },
            }}
            sx={{
              backgroundColor: 'white',
              boxShadow:
                '0 0px 15px -3px rgb(0 0 0 / 0.1), 0 0px 6px -4px rgb(0 0 0 / 0.1);',
              borderRadius: '15px',
              width: {
                xs: '100%',
                sm: '550px',
                md: '900px',
              },
            }}
          >
            {Object.keys(phasesObject).map((key) => (
              <Grid
                container
                key={key}
                width={'100%'}
                height={{
                  xs: '500px',
                  sm: '550px',
                  md: '350px',
                }}
                py={{ xs: 2, sm: 2, md: 7 }}
                px={{ xs: 2, sm: 10, md: 8 }}
              >
                {/* Left/Up Part */}
                <Grid item xs={12} md={5}>
                  <Stack alignItems={'center'} spacing={5}>
                    {/* Phase */}
                    <Typography variant='h2' align='center'>
                      {phasesObject[key].phase}
                    </Typography>
                    {/* Icon */}
                    <Stack
                      sx={{
                        backgroundColor: 'primary.light',
                        borderRadius: '50%',
                      }}
                      display={'block'}
                      p={1}
                      width={{
                        xs: '100px',
                        sm: '120px',
                        md: '160px',
                      }}
                      height={{
                        xs: '100px',
                        sm: '120px',
                        md: '160px',
                      }}
                    >
                      <Avatar
                        sx={{
                          backgroundColor: 'transparent',
                          width: '100%',
                          height: '100%',
                          color: 'rgb(255, 255, 255, 0.9)',
                          fontSize: {
                            xs: '90px',
                            sm: '90px',
                            md: '130px',
                          },
                        }}
                      >
                        {returnIcon(Number(key) - 1)}
                      </Avatar>
                    </Stack>
                  </Stack>
                </Grid>
                {/* Right/Bottom Part */}
                <Grid item xs={12} md={7}>
                  <Stack>
                    <Typography
                      variant='h2'
                      align='center'
                      paddingBottom={{
                        xs: 1,
                        md: 2,
                      }}
                    >
                      {phasesObject[key].title}
                    </Typography>
                    {/* if the phase is done draw a rounded green box with 'DONE' */}
                    {phasesObject[key].done && (
                      /* make the stack fill the text */
                      <Stack
                        bgcolor={'#62B16E'}
                        borderRadius={2}
                        justifyContent={'center'}
                        alignItems={'center'}
                        padding={1}
                        
                      >
                        <Typography
                          variant='h3'
                          color={'white'}
                          align='center'
                        >
                          DONE!
                        </Typography>
                      </Stack>
                    )}
                    <ul
                      style={{
                        paddingLeft: 18,
                      }}
                    >
                      {phasesObject[key].points.map((point, index) => (
                        <li
                          key={index}
                          style={{
                            wordWrap: 'break-word',
                            paddingBottom: 10,
                          }}
                        >
                          <Typography variant='h3' align='left'>
                            {point}
                          </Typography>
                        </li>
                      ))}
                    </ul>
                  </Stack>
                </Grid>
              </Grid>
            ))}
          </Carousel>
        </Stack>
      </Stack>
    </CustomContainer>
  );
};

const OurIntention = () => {
  return (
    <CustomContainer bgStyle='primary' paddingY='medium' marginStyle='narrow'>
      <Stack
        spacing={{
          xs: 5,
          sm: 10,
        }}
      >
        <Stack
          spacing={{ xs: 2, sm: 5 }}
          px={{
            xs: 3,
            sm: 0,
            md: 0,
            lg: 12,
            xl: 17,
          }}
        >
          <Typography variant='h1' color={color_theme.fontColor} align='left'>
            OUR INTENTION
          </Typography>
          <Typography
            variant='h3'
            style={{ lineHeight: 1.5 }}
            color='text.secondary'
            align='left'
          >
            We seek to promote in-person activities that focus on helping the people, flora, and fauna in our local communities. We also endeavor to only surface opportunities that do not request monetary donations from volunteers.
          </Typography>
        </Stack>
      </Stack>
    </CustomContainer>
  );
};

export default About;
