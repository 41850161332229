// input-validation.ts

// Function to check if input is null or undefined
const isNullOrUndefined = (input: any): boolean => {
  return input === null || input === undefined || input === "";
};

// Function to check if input is a valid email address
const isEmailValid = (
  email: string,
  isRequired: boolean
): {
  result: boolean;
  message: string;
} => {
  if (isRequired && isNullOrUndefined(email)) {
    return {
      result: false,
      message: "Email address is required",
    };
  }

  if (!isRequired && isNullOrUndefined(email)) {
    return {
      result: true,
      message: "",
    };
  }

  const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
  if (!emailRegex.test(email)) {
    return {
      result: false,
      message: "Email address format is invalid",
    };
  }

  return {
    result: true,
    message: "",
  };
};

// Function to check if input is a valid phone number
const isPhoneNumberValid = (
  phoneNumber: string,
  isRequired: boolean
): {
  result: boolean;
  message: string;
} => {
  if (isRequired && isNullOrUndefined(phoneNumber)) {
    return {
      result: false,
      message: "Phone number is required",
    };
  }

  if (!isRequired && isNullOrUndefined(phoneNumber)) {
    return {
      result: true,
      message: "",
    };
  }

  const phoneRegex = /^\d{10}$/; // Change this regex according to your phone number format
  if (!phoneRegex.test(phoneNumber)) {
    return {
      result: false,
      message: "Phone number format is invalid",
    };
  }

  return {
    result: true,
    message: "",
  };
};

// Function to check if input is a valid name
const isNameValid = (
  name: string,
  isRequired: boolean
): {
  result: boolean;
  message: string;
} => {
  if (isRequired && isNullOrUndefined(name)) {
    return {
      result: false,
      message: "First/Last name is required",
    };
  }

  if (!isRequired && isNullOrUndefined(name)) {
    return {
      result: true,
      message: "",
    };
  }

  const nameRegex = /^[A-Za-z\s]+$/;
  if (!nameRegex.test(name)) {
    return {
      result: false,
      message: "First/Last name format is invalid",
    };
  }

  return {
    result: true,
    message: "",
  };
};

const isWebsiteURLValid = (
  websiteURL: string,
  isRequired: boolean
): {
  result: boolean;
  message: string;
} => {
  if (isRequired && isNullOrUndefined(websiteURL)) {
    return {
      result: false,
      message: "Website URL is required",
    };
  }

  if (!isRequired && isNullOrUndefined(websiteURL)) {
    return {
      result: true,
      message: "",
    };
  }

  const urlRegex =
    /^(https?:\/\/)?([\da-z\.-]+)\.([a-z\.]{2,6})([\/\w \.-]*)*\/?$/;
  if (!urlRegex.test(websiteURL)) {
    return {
      result: false,
      message: "Website URL format is invalid",
    };
  }

  return {
    result: true,
    message: "",
  };
};

const isOrganizationNameValid = (
  organizationName: string,
  isRequired: boolean
): {
  result: boolean;
  message: string;
} => {
  if (isRequired && isNullOrUndefined(organizationName)) {
    return {
      result: false,
      message: "Organization name is required",
    };
  }

  if (!isRequired && isNullOrUndefined(organizationName)) {
    return {
      result: true,
      message: "",
    };
  }

  // Check for minimum length (e.g., 2 characters)
  if (organizationName.trim().length < 2) {
    return {
      result: false,
      message: "Organization name must be at least 2 characters long",
    };
  }

  return {
    result: true,
    message: "",
  };
};

// Validation function for a generic field
export const isFieldValid = (
  fieldName: string,
  value: any,
  isRequired: boolean
): {
  result: boolean;
  message: string;
} => {
  switch (fieldName) {
    case "firstName":
      return isNameValid(value, isRequired);
    case "lastName":
      return isNameValid(value, isRequired);
    case "emailAddress":
      return isEmailValid(value, isRequired);
    case "phoneNumber":
      return isPhoneNumberValid(value, isRequired);
    case "websiteURL":
      return isWebsiteURLValid(value, isRequired);
    case "organizationName":
      return isOrganizationNameValid(value, isRequired);
    default:
      return {
        result: true,
        message: "",
      }; // If the field is not one of the expected ones, consider it valid
  }
};
