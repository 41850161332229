export const EmailJSConfig = {
  serviceId: 'service_cd38qoj',
  template1Id: 'template_7mwlcmz',
  template2Id: 'template_e0yqj8m',
  publicKey: 'pixBuRbaL0yJ_8vw-',
};

// // Testing
// export const EmailJSConfig = {
//   serviceId: 'service_7hb961t',
//   template1Id: 'template_2k68lwd',
//   template2Id: 'template_u14evym',
//   publicKey: 'VaEVwYChpZumGkIkr',
// };

export const ReCaptchaConfig = {
  publicKey: '6LdlVcUoAAAAAFtdX7NgGUU6mdrUToWCFPdQan_5',
};


export const GOOGLE_API_KEY = 'AIzaSyBNAGBxy7W82NU-GUf9vLmTM6weW_Qi4TY'