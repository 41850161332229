import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import MetaDataHeader from './components/MetaDataHeader';
import { Endpoints } from './data/endpoints';

const metaRoot = ReactDOM.createRoot(
  document.getElementById('header_meta') as HTMLElement
);

// Pre-fetch to keep the server warm. Putting here was the easiest way to ensure
// that this gets fired before a search request. (Also tried as a meta tags but
// it was not firing in the correct order.)
// TODO: investigate hard-coding this into the build to ensure it is not held up
// by JavaScript loading and execution.
fetch(Endpoints.WARM);

metaRoot.render(
  <React.StrictMode>
    <MetaDataHeader title='Samaritan Scout' />
  </React.StrictMode>
);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
