import dummyData from "../data/new_dummy_data.json";
import {
  FilterOptions,
  EventData,
  FilterRequest,
  UserCoordinates,
  CleanedFilterRequest,
} from "../data/type";
import { supabase } from "./auth";

export type SearchRes = {
  results: VO[];
  query: {
    location: string;
    distance: number;
    keywords?: string;
  };
  count: number;
};

export type VO = {
  id: string;
  org_id: string;
  vo_title: string;
  vo_description: string;
  vo_min_age: number;
  vo_skills: string[];
  org_name: string;
  vo_url: string;
  domain_url: string;
  org_causes: string[];
  org_desc: string;
  logo_url: string;
  locations: {
    street_address: string;
    city: string;
    zip_code: string;
    state: string;
    county: string;
    distance: number;
  }[];
  similarity: number;
};

function isNumber(value: string | null): boolean {
  return value !== null && !isNaN(Number(value));
}

interface FilterObjParams {
  searchParams: URLSearchParams;
  userCoordinates?: UserCoordinates | null;
}

export function getFilterQueryObj({
  searchParams,
  userCoordinates,
}: FilterObjParams): FilterRequest {
  const maybeDistance = searchParams.get("distance");
  const maybeMinAge = searchParams.get("minAge");

  const latLon =
    userCoordinates && userCoordinates !== "INVALID_LOCATION"
      ? userCoordinates
      : [null, null];
  const [lat, lon] = latLon;

  return {
    causes: searchParams.getAll("causes") ?? null,
    skills: searchParams.getAll("skills") ?? null,
    keywords: searchParams.get("keywords"),
    location: searchParams.get("location")!,
    distance: isNumber(maybeDistance) ? Number(maybeDistance) : 20,
    min_age: isNumber(maybeMinAge) ? Number(maybeMinAge) : null,
  };
}

/**
 * Converts a FilterRequest object into a query string.
 * TODO Prioritize adding test coverage for this function.
 */
export function getFilterQueryString(
  requestObject: FilterRequest | CleanedFilterRequest
) {
  for (const key in requestObject) {
    // casting is safe because we know that the key is a key of FilterRequest
    if (
      requestObject[key as keyof FilterRequest] === null ||
      requestObject[key as keyof FilterRequest] === ""
    ) {
      delete requestObject[key as keyof FilterRequest];
    } else {
      requestObject[key as keyof FilterRequest] =
        requestObject[key as keyof FilterRequest]!.toString();
    }
  }
  return requestObject;
}

export async function fetcher<Req extends { body: unknown }, Res>(
  method: "GET" | "POST" | "DELETE" | "PATCH",
  endpoint: string,
  body?: Req["body"]
): Promise<Res> {
  const { data, error } = await supabase.auth.getSession();
  const request = await fetch(`${process.env.REACT_APP_API_URL}${endpoint}`, {
    method,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${data.session?.access_token}`,
    },
    credentials: "include",
    ...(body
      ? {
          body: JSON.stringify(body),
        }
      : {}),
  });

  const json = await request.json();

  if (request.status >= 400) {
    throw new Error(`${json}`);
  }

  return json;
}

/**
 * This is a mock function of a backend controller, to be used for
 * testing and development purposes.
 * TODO: Update fake data to match latest backend reponses.
 * Commented out until we have types fixed.
 */
// export async function fetchData({options, setSize, pageNumber}: {
//   options: FilterOptions,
//   setSize: number,
//   pageNumber: number
// }): Promise<ResponseObject> {
//   try {
//     const data = dummyData as EventData[];

//     const searchKeywords = options.keywords?.toLowerCase().split(' ') || [];

//     const allSearchTerms = [
//       ...searchKeywords,
//       ...options.causes,
//       ...options.skills,
//     ];

//     // Apply filters based on provided arguments

//     let filteredData = data.filter((record) => {
//       const locationMatch = !options.location ||
//           record.address_1.toLowerCase().includes(options.location.toLowerCase()) ||
//           record.city.toLowerCase().includes(options.location.toLowerCase()) ||
//           record.state.toLowerCase().includes(options.location.toLowerCase()) ||
//           record.zip_code.includes(options.location);

//       const skills = record.skills.map(s => s.toLowerCase().split(' ')).flat();
//       const causes = record.o_causes.map(c => c.toLowerCase().split(' ')).flat();
//       const descriptions = record.description.toLowerCase();

//       // Does the record include any of our search terms?
//       const searchMatch = allSearchTerms.every((w) => {
//         const word = w.toLowerCase();
//         return skills.includes(word) || causes.includes(word) || descriptions.includes(word);
//       });

//       return (
//         locationMatch &&
//         searchMatch &&
//         (!options.startDate || (record.start_date && record.start_date >= options.startDate)) &&
//         (!options.endDate || (record.end_date && record.end_date <= options.endDate)));
//     });

//     const newStartIndex = setSize * (pageNumber);
//     // Remember that end is not included in Array.slice
//     const newEndIndex = setSize * (pageNumber + 1);
//     const paginatedData = filteredData.slice(
//       newStartIndex, newEndIndex
//     );

//     const nextPage = filteredData.length > newEndIndex ? pageNumber + 1 : null;
//     // Simulate response object similar to axios
//     const response: ResponseObject = {
//       results: paginatedData,
//       status: 200,
//       statusText: 'OK',
//       headers: {},
//       config: {},
//       // nextPage should return the next page number, if there are remaining records
//       nextPage,
//     };

//     return response;
//   } catch (error) {
//     // Simulate an error response
//     const errorResponse: { response: ResponseObject } = {
//       response: {
//         results: [],
//         status: 500,
//         statusText: 'Internal Server Error',
//         headers: {},
//         config: {},
//         nextPage: null,
//       },
//     };

//     throw errorResponse;
//   }
// }
