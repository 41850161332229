import { Box, Stack, Typography } from '@mui/material';
import MaterialTheme from '../components/MaterialTheme';
import ContactForm from '../components/general-contact-form';
import Separator from '../components/Separator';
import MetaDataHeader from '../components/MetaDataHeader';

const Contact = () => {
  return (
    <>
      <MetaDataHeader title = "Contact Us" description='Contact the Good Samaritans' />
      <Stack py={5} alignItems={'center'}>
        <Stack
          width={{
            xl: '800px',
            lg: '800px',
            md: '800px',
            sm: '550px',
            xs: '90%',
          }}
          spacing={2}
        >
          <Box
            sx={{
              backgroundImage: 'url(/images/contact-page/contact-us.png)',
              backgroundSize: 'contain',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',
            }}
            borderRadius={2}
            height={{
              xs: '200px',
              sm: '200px',
              md: '250px',
              lg: '250px',
              xl: '250px',
            }}
            width={'100%'}
          ></Box>
          <Typography
            variant='h1'
            gutterBottom
            textAlign={'center'}
            sx={{ textTransform: 'uppercase' }}
          >
            Contact us
          </Typography>

          <Typography
            variant='h3'
            textAlign={'center'}
            color={MaterialTheme.palette.grey.A700}
          >
            Thank you for your interest in learning more about Samaritan Scout!
          </Typography>
          <Separator primary={true} />
          <Stack pb={{ xs: '40px', sm: '60px', md: '80px' }}>
            <ContactForm />
          </Stack>
        </Stack>
      </Stack>
    </>
  );
};

export default Contact;
