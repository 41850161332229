import { useMediaQuery } from "@mui/material";
import MaterialTheme from "../components/MaterialTheme";
import { useEffect, useState } from "react";
import MetaDataHeader from "../components/MetaDataHeader";

const Zeffy = () => {
  const isSmallScreen = useMediaQuery(MaterialTheme.breakpoints.only("xs"));
  const isMediumScreen = useMediaQuery(MaterialTheme.breakpoints.only("md"));
  const isLargeScreen = useMediaQuery(MaterialTheme.breakpoints.only("lg"));
  const isExtraLargeScreen = useMediaQuery(
    MaterialTheme.breakpoints.only("xl"),
  );

  const getHeight = () => {
    if (isSmallScreen) return "2300rem";
    if (isMediumScreen) return "1700rem";
    if (isLargeScreen) return "1700rem";
    if (isExtraLargeScreen) return "1700rem";
    return "2200rem"; // Default height
  };

  const [iframeHeight, setIframeHeight] = useState(getHeight()); // Default height

  useEffect(() => {
    const handleResize = () => {
      setIframeHeight(getHeight());
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [isSmallScreen, isMediumScreen, isLargeScreen, isExtraLargeScreen]);

  const iframeContainerStyle = {
    marginTop: "-64px",
  };

  return (
    <>
      <MetaDataHeader
        title="Zeffy"
        description="Donate to the Good Samaritans"
      />
      <div style={iframeContainerStyle}>
        <iframe
          title="Embedded Website"
          src="https://www.zeffy.com/en-US/donation-form/28fb055c-8b0c-42f1-a610-f70537f08ceb"
          width="100%"
          height={iframeHeight}
          frameBorder="0"
          allowFullScreen
        />
      </div>
    </>
  );
};

export default Zeffy;
