import MetaDataHeader from "../components/MetaDataHeader";
import { Box, Link, Stack, TextField, Typography } from "@mui/material";
import React, { useEffect } from "react";
import Button from "../components/button";
import { supabase } from "../utils/auth";
import { useSession } from "@supabase/auth-helpers-react";
import { useNavigate } from "react-router-dom";
import { useMe } from "../hooks/useResults";
import { Button as MuiButton } from "@mui/material";
import { blue } from "@mui/material/colors";
import { LinkedIn } from "@mui/icons-material";
import theme from "../components/MaterialTheme";

const Login = () => {
  const { data: user, mutate } = useMe();
  const session = useSession();
  const navigate = useNavigate();
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [error, setError] = React.useState<string | null>(null);

  useEffect(() => {
    const {
      data: { subscription },
    } = supabase.auth.onAuthStateChange(async (event, session) => {
      if (event === "SIGNED_IN") {
        await mutate();
        navigate("/");
      }
    });

    return () => subscription.unsubscribe();
  }, []);

  useEffect(() => {
    if (user) {
      navigate("/");
    }
  }, [user, navigate]);

  const login = async () => {
    const { data, error } = await supabase.auth.signInWithPassword({
      email,
      password,
    });

    if (error) {
      setError(error.message);
    } else if (data) {
      await mutate();
      navigate("/");
    }
  };

  async function signInWithLinkedIn() {
    const { data, error } = await supabase.auth.signInWithOAuth({
      provider: "linkedin_oidc",
      options: {
        redirectTo: process.env.REACT_APP_ROOT_URL + "/callback",
      },
    });

    console.log(data, error);
  }

  return (
    <>
      <MetaDataHeader
        title="Login"
        description="Login to your Samaritan Scout account"
      />
      <Stack
        sx={{
          height: "calc(100vh - 75px)",
        }}
        alignItems={"center"}
        justifyContent={"center"}
      >
        <Box
          component="img"
          src="/images/dog_silhouette.png"
          alt="Dog Silhouette"
          sx={{ width: "100px", height: "auto", marginBottom: 2 }}
        />
        <Stack
          sx={{
            width: { xs: "100%", sm: "34rem" },
            p: { xs: 2, sm: 4 },
            boxShadow: {
              xs: "none",
              sm: "0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1)",
            },
            borderRadius: "12px",
            background: "white",
          }}
          spacing={4}
          textAlign={"center"}
          boxSizing={"border-box"}
        >
          <Stack spacing={2} alignItems={"center"}>
            <Typography variant={"h2"}>Welcome Back!</Typography>
            <Link color={theme.palette.grey[600]} href={"/signup"}>
              Need an account?
            </Link>
          </Stack>
          <Stack spacing={2}>
            <TextField
              label={error ?? "Email"}
              variant="outlined"
              placeholder="Email"
              fullWidth={true}
              value={email}
              error={!!error}
              onChange={(e) => {
                setError(null);
                setEmail(e.target.value);
              }}
              sx={{
                borderRadius: "10px",
                backgroundColor: "#fff",
                input: { color: "black" },
              }}
            />
            <TextField
              label={error ?? "Password"}
              type="password"
              autoComplete="current-password"
              value={password}
              error={!!error}
              onChange={(e) => {
                setError(null);
                setPassword(e.target.value);
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter" && email && password) {
                  login();
                }
              }}
            />
          </Stack>
          <Button
            size={"large"}
            bgStyle={"primary"}
            hoverStyle={"dark"}
            width={"100%"}
            onClick={login}
            disabled={!email || !password}
          >
            Login
          </Button>
          <Link href={"/forgot_password"}>Forgot your password?</Link>
        </Stack>
      </Stack>
    </>
  );
};

export default Login;
