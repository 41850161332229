import { useMediaQuery } from "@mui/material";
import MaterialTheme from "../components/MaterialTheme";
import MetaDataHeader from "../components/MetaDataHeader";

function Privacy() {
	const isMobile = useMediaQuery(MaterialTheme.breakpoints.down("md"));
	return (
		<>
			<MetaDataHeader
				title="Privacy Policy"
				description="Read the terms of services"
			/>
			<div
				style={{
					display: "flex",
					justifyContent: "center",
					marginLeft: isMobile ? "1rem" : "0",
					marginRight: isMobile ? "1rem" : "0",
				}}
			>
				<div
					style={{
						maxWidth: isMobile ? "100%" : "50rem",
						marginBottom: "2rem",
					}}
				>
					<h1>Privacy Policy</h1>
					<h2>Introduction</h2>{" "}
					<p>
						We collect information to provide better services to all our users —
						from figuring out basic stuff like which language you speak, to more
						complex things like which volunteer activities you find most
						relevant. The information Scout collects, and how that information
						is used, depends on how you use our services and how you manage your
						privacy controls.{" "}
					</p>{" "}
					<p>
						When you’re not signed in to a Scout Account, we store the
						information we collect with unique identifiers tied to the browser,
						application, or device you’re using. This allows us to do things
						like maintain your preferences across browsing sessions, such as
						your preferred location or whether to show you more relevant search
						results based on your activity.
					</p>
					<p>
						When you’re signed in, we also collect information that we store
						with your Scout Account, which we treat as personal information.
					</p>{" "}
					<h2>Things you create or provide to us</h2>{" "}
					<p>
						When you create a Scout Account, you provide us with personal
						information that includes your name, password, city/state/zip. You
						can also choose to add a street address to your account. Even if you
						aren’t signed in to a Scout Account, you might choose to provide us
						with information — like an email address to communicate with Scout
						or receive updates about our services.
					</p>
					<h2>Information we collect as you use our services</h2>{" "}
					<p>
						{" "}
						When you search for volunteer opportunities, we collect your search
						terms and location preferences.{" "}
					</p>{" "}
					<h2>We use data to build better services</h2>{" "}
					<p>
						{" "}
						We use the information we collect from our services for the
						following purposes:
					</p>{" "}
					<p>
						<u style={{ display: "block" }}>Provide our services </u>
						We use your information to deliver our services, like processing the
						terms you search for in order to return results or the details in
						your profile in order to suggest volunteer opportunities.
					</p>{" "}
					<p>
						<u style={{ display: "block" }}>Maintain & improve our services</u>{" "}
						We also use your information to ensure our services are working as
						intended, such as tracking outages or troubleshooting issues that
						you report to us. And we use your information to make improvements
						to our services — for example, understanding which search terms are
						most frequently misspelled helps us improve spell-check features
						used across our services.
					</p>{" "}
					<p>
						<u style={{ display: "block" }}>Develop new services</u> We use the
						information we collect in existing services to help us develop new
						ones. For example, understanding the keywords people pair with
						filters can help us adjust our filter taxonomies.
					</p>{" "}
					<p>
						<u style={{ display: "block" }}>
							Provide personalized services, including content and ads
						</u>{" "}
						We use the information we collect to customize our services for you,
						including providing recommendations, personalized content, and
						customized search results.
					</p>{" "}
					<p>
						<u style={{ display: "block" }}>Measure performance</u> We use data
						for analytics and measurement to understand how our services are
						used. For example, we analyze data about your visits to our sites to
						do things like optimize product design.
					</p>
					<p>
						<u style={{ display: "block" }}>Communicate with you</u> We use
						information we collect, like your email address, to interact with
						you directly. For example, we may send you a notification if we
						detect suspicious activity, like an attempt to sign in to your
						Account from an unusual location. Or we may let you know about
						upcoming changes or improvements to our services. And if you contact
						Scout, we’ll keep a record of your request in order to help solve
						any issues you might be facing.
					</p>{" "}
					<p>
						<u style={{ display: "block" }}>
							Protect Scout, our users, and the public
						</u>{" "}
						We use information to help improve the safety and reliability of our
						services. This includes detecting, preventing, and responding to
						fraud, abuse, security risks, and technical issues that could harm
						Scout, our users, or the public.
					</p>
					<h2>Categories of information we collect</h2>{" "}
					<p>
						Identifiers and similar information such as your name and password,
						email, and address, as well as unique identifiers tied to the
						browser, application, or device you’re using.
					</p>{" "}
					<p>
						Internet, network, and other activity information such as your
						search terms; views and interactions with content; information about
						the interaction of your apps, browsers, and devices with our
						services (like IP address, crash reports, and system activity.
					</p>{" "}
					<p>
						Geolocation data, such as may be determined by IP address and
						location searches depending in part on your device and account
						settings. Depending on these settings, this may include precise
						location data.
					</p>
					<p>
						Professional, interest, skill information you provide in your
						profile.
					</p>
					<h2>
						Business purposes for which information may be used or disclosed
					</h2>
					<p>
						<u style={{ display: "block" }}>Auditing and measurement</u> We may
						use information for analytics and measurement to understand how our
						services are used. We may disclose non-personally identifiable
						information publicly to promote volunteerism and for auditing
						purposes.
					</p>{" "}
					<p>
						<u style={{ display: "block" }}>Maintaining our services</u>
						We may use information to ensure our services are working as
						intended, such as tracking outages or troubleshooting bugs and other
						issues that you report to us.
					</p>{" "}
					<p>
						<u style={{ display: "block" }}>
							Protecting against security threats, abuse, and illegal activity
						</u>{" "}
						We may use and disclose information to detect, prevent and respond
						to security incidents, and for protecting against other malicious,
						deceptive, fraudulent, or illegal activity. For example, we may
						receive or disclose information about IP addresses that malicious
						actors have compromised.
					</p>
					<p>
						<u style={{ display: "block" }}>Legal reasons</u> We may also use
						information to satisfy applicable laws or regulations, and disclose
						information in response to legal process or enforceable government
						requests, including to law enforcement. We will provide information
						about the number and type of requests we receive from governments in
						a publicly-available report accessible from our website each year.
					</p>{" "}
					<h2>Changes to this policy</h2>
					<p>
						{" "}
						We change this Privacy Policy from time to time. We will not reduce
						your rights under this Privacy Policy without your explicit consent.
						We always indicate the date the last changes were published and we
						offer access to archived versions for your review. If changes are
						significant, we’ll provide a more prominent notice (including, for
						certain services, email notification of Privacy Policy changes).
					</p>
				</div>
			</div>
		</>
	);
}

export default Privacy;
