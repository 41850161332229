import { useMediaQuery } from "@mui/material";
import MaterialTheme from "../components/MaterialTheme";
import MetaDataHeader from "../components/MetaDataHeader";

function Terms() {
	const isMobile = useMediaQuery(MaterialTheme.breakpoints.down("md"));
	return (
		<>
			<MetaDataHeader title= "Terms" description="Read the terms of services" />
			<div
				style={{
					display: "flex",
					justifyContent: "center",
					marginLeft: isMobile ? "1rem" : "0",
					marginRight: isMobile ? "1rem" : "0",
				}}
			>
				<div
					style={{
						maxWidth: isMobile ? "100%" : "50rem",
						marginBottom: "2rem",
					}}
				>
					<h1>
						<span>TERMS OF SERVICE</span>
					</h1>
					<p>
						<span>Welcome to Scout!</span>
					</p>
					<h2>
						<span>Introduction</span>
					</h2>
					<p>
						<span>
							Thank you for using the Samaritan Scout (&ldquo;Scout&rdquo;)
							platform and the products, services and features we make available
							to you as part of the platform (collectively, the
							&ldquo;Service&rdquo;). &nbsp;
						</span>
					</p>
					<p>
						<u>
							<span>Our Service</span>
						</u>
						<span>
							. The Service allows you to discover volunteer activities and
							related content, and acts as a search and match platform
							connecting individuals with volunteer activities offered by
							governmental and nonprofit organizations. Coming soon will be
							additional services including providing a forum for people to
							share, connect, inform, and inspire others across the globe.
						</span>
					</p>
					<p>
						<u>
							<span>Your Service Provider</span>
						</u>
						<span>
							. The entity providing the Service is Samaritan Scout, a
							501(c)(3), incorporated in the State of New Jersey, EIN
							92-3607846.
						</span>
					</p>
					<p>
						<u>
							<span>Applicable Terms</span>
						</u>
						<span>
							. Your use of the Service is subject to the terms
							(&ldquo;Agreement&rdquo;) outlined below, which may be updated
							from time to time.
						</span>
					</p>
					<p>
						<span>
							Please read this Agreement carefully and make sure you understand
							it. If you do not understand the Agreement, or do not accept any
							part of it, then you may not use the Service.
						</span>
					</p>
					<p>
						<span>
							Samaritan Scout (&ldquo;Scout,&rdquo; &ldquo;we,&rdquo; or
							&ldquo;us&rdquo;) provides the platform available at
							https://www.samaritanscout.org/ (&ldquo;Site&rdquo;). These Terms
							of Service and Use (the &ldquo;Terms&rdquo;) describe the terms
							and conditions that apply to your use of the Site and the services
							offered by Scout through use of the Site, including but not
							limited to the Scout&rsquo;s online volunteer matching service and
							other information and services related thereto (the Site and all
							such services are, collectively, the &ldquo;Services&rdquo;).
							&ldquo;You&rdquo; means the individual person entering into these
							Terms on his or her own behalf; or, if these Terms are being
							entered into on behalf of an entity, such as an employer,
							&ldquo;you&rdquo; means the entity on whose behalf of which these
							Terms are entered, and in the latter case, the person entering
							these Terms represents and warrants that he or she has the
							authority to do so on your behalf.
						</span>
					</p>
					<p>
						<span>
							By visiting, using, or accessing any Services, accepting these
							Terms by clicking on the &ldquo;I Accept&rdquo; button, or
							completing the Account registration process, you represent and
							warrant that (a) you have read, understand, and agree to be bound
							these Terms, (b) you are of legal age to form a binding contract
							with Scout, (c) you are not barred from using the Services under
							the laws of the United States, your place of residence, or any
							other applicable jurisdiction, and (d) you have the authority to
							enter into these Terms. If you do not agree with these Terms, you
							may not use the Services and you should leave the Site and
							discontinue use of the Services immediately. If you wish to create
							an account to make use of the Services reserved for account
							holders, you must read these Terms and indicate your acceptance
							during the registration process. Note, however, that these Terms
							apply to your access to and use of the Services regardless of
							whether you register an Account. We reserve the right to terminate
							your use or access to the Services at any time for any reason,
							including, without limitation, if we learn that you have provided
							false or misleading information or have violated the Terms.
						</span>
					</p>
					<h2>
						<span>Who may use the Service?</span>
					</h2>
					<p>
						<u>
							<span>Age Requirements</span>
						</u>
						<span>
							. You must be at least 13 years old to use the Service; however,
							children of all ages may use the Service if enabled by a parent or
							legal guardian.
						</span>
					</p>
					<p>
						<u>
							<span>Permission by Parent or Guardian</span>
						</u>
						<span>
							. If you are under 18, you represent that you have your parent or
							guardian&rsquo;s permission to use the Service. Please have them
							read this Agreement with you.
						</span>
					</p>
					<p>
						<span>
							If you are a parent or legal guardian of a user under the age of
							18, by allowing your child to use the Service, you are subject to
							the terms of this Agreement and responsible for your child&rsquo;s
							activity on the Service.&nbsp;
						</span>
					</p>
					<p>
						<u>
							<span>Businesses/Organization</span>
						</u>
						<span>.&nbsp;</span>
						<span>
							If you are using the Service on behalf of a company or
							organization, you represent that you have authority to act on
							behalf of that entity, and that such entity accepts this
							Agreement.
						</span>
					</p>
					<h2>
						<span>Your Use of the Service</span>
					</h2>
					<p>
						<u>
							<span>Content on the Service</span>
						</u>
						<span>.&nbsp;</span>
						<span>
							The content on the Service includes graphics, photos, text,
							branding (including trade names, trademarks, service marks, or
							logos), interactive features, software, metrics, and other
							materials whether provided by you, Samaritan Scout or a
							third-party (collectively, &quot;Content&rdquo;).
						</span>
					</p>
					<p>
						<span>
							Content is the responsibility of the person or entity that
							provides it to the Service. If you see any Content you believe
							does not comply with this Agreement, including by violating the
							law, you can&nbsp;
						</span>
						<strong>
							<u>
								<a href={"/contact"}>report it to us</a>
							</u>
						</strong>
						<span>.</span>
					</p>
					<p>
						<u>
							<span>Third-Party Services and Links</span>
						</u>
						<span>.&nbsp;</span>
						<span>
							You acknowledge and agree that by using the Services, which
							include maps provided by Google, you are bound by the Google
							Maps/Google Earth Additional Terms of Service (including the
							Google Privacy Policy). We may also provide links to other
							third-party websites, applications, or advertisements. Our
							provision of a link to any other site or location is for your
							convenience and does not signify our endorsement of such other
							site or location or its contents. We have no control over, and
							cannot be responsible for, these outside sites or their content.
							We encourage you to review the terms of use and privacy policies
							for any such third-party links you visit on the Services. We will
							not be liable for any information, software, or links found at any
							other website, internet location, or source of information, or for
							your use of such information.
						</span>
					</p>
					<p>
						<u>
							<span>Scout Accounts</span>
						</u>
						<span>.&nbsp;</span>
						<span>
							You can use parts of the Service, such as browsing and searching
							for Content, without having a Scout account. However, you do need
							a Scout account to use some features. With a Scout account, you
							may be able to &lsquo;favorite&rsquo; search results, receive
							intelligent recommendations of volunteer opportunities relevant to
							you, &lsquo;flag&rsquo; search results, and more. You can
							follow&nbsp;
						</span>
						<strong>
							<a href={"/signup"}>
								<span>this link</span>
							</a>
						</strong>
						<span>&nbsp;to create a Scout account.</span>
					</p>
					<p>
						<span>
							To protect your account, keep your password confidential. You
							should not reuse your Scout account password on third-party
							applications.&nbsp;
						</span>
					</p>
					<p>
						<span>
							In creating an account, you will also be asked to provide an email
							address, choose a password for your Account and share a
							city/state/zip location. You shall not have more than one Account
							at any given time.
						</span>
					</p>
					<p>
						<span>
							By creating an account, you represent that: (a) all registration
							information you submit is truthful, accurate, current, and
							complete; (b) you will maintain the accuracy of such information;
							and (c) your use of the Services does not violate any applicable
							law or regulation. Scout has the right to suspend or terminate
							your Account and refuse any and all current or future use of the
							Services (or any portion thereof) if you (i) are not of legal age
							to form a binding contract with Scout; (ii) provide any
							information that is untrue, inaccurate, incomplete, or not current
							(or if Scout has reasonable grounds to suspect that any
							information you provide is untrue, inaccurate, incomplete, or not
							current); (iii) create an Account using a false identity or
							information, or on behalf of someone other than yourself; and/or
							(iv) do not meet Scout&rsquo;s eligibility requirements.
						</span>
					</p>
					<p>
						<u>
							<span>Your Information</span>
						</u>
						<span>.&nbsp;</span>
						<span>Our&nbsp;</span>
						<strong>
							<a href={"/privacy"}>
								<span>Privacy Policy</span>
							</a>
						</strong>
						<span>
							&nbsp;explains how we treat your personal data and protect your
							privacy when you use the Service.&nbsp;
						</span>
					</p>
					<p>
						<u>
							<span>Permissions and Restrictions</span>
						</u>
						<span>.&nbsp;</span>
						<span>
							You may access and use the Service as made available to you, as
							long as you comply with this Agreement and applicable law. You may
							view Content for your personal, non-commercial use. You may also
							show and link to Scout Content on your personal websites and/or
							social media accounts.
						</span>
					</p>
					<p>
						<span>
							The following restrictions apply to your use of the Service. You
							are not allowed to:
						</span>
					</p>
					<ol>
						<li>
							<p>
								<span>
									access, reproduce, download, distribute, transmit, broadcast,
									display, sell, license, alter, modify or otherwise use any
									part of the Service or any Content except: (a) as expressly
									authorized by the Service; or (b) with prior written
									permission from Scout and, if applicable, the respective
									rights holders;
								</span>
							</p>
						</li>
						<li>
							<p>
								<span>
									circumvent, disable, fraudulently engage with, or otherwise
									interfere with any part of the Service (or attempt to do any
									of these things), including security-related features or
									features that (a) prevent or restrict the copying or other use
									of Content or (b) limit the use of the Service or Content;
								</span>
							</p>
						</li>
						<li>
							<p>
								<span>
									access the Service using any automated means (such as robots,
									botnets or scrapers) except (a) in the case of public search
									engines; or (b) with Scout&rsquo;s prior written
									permission;&nbsp;
								</span>
							</p>
						</li>
						<li>
							<p>
								<span>
									collect or harvest any information that might identify a
									person (for example, usernames or faces), unless permitted by
									that person or allowed under section (3) above;
								</span>
							</p>
						</li>
						<li>
							<p>
								<span>
									use the Service to distribute unsolicited promotional or
									commercial content or other unwanted or mass solicitations;
								</span>
							</p>
						</li>
						<li>
							<p>
								<span>
									cause or encourage any inaccurate measurements of genuine user
									engagement with the Service, including by paying people or
									providing them with incentives to increase a search
									result&rsquo;s views, &lsquo;favorite-ing&rsquo;,
									&lsquo;flagging&rsquo;, or otherwise manipulate metrics in any
									manner;
								</span>
							</p>
						</li>
						<li>
							<p>
								<span>
									misuse any reporting, flagging, complaint, dispute, or appeals
									process, including by making groundless, vexatious, or
									frivolous submissions;
								</span>
							</p>
						</li>
						<li>
							<p>
								<span>
									use the Service to view Content other than for personal,
									non-commercial use (for example, you may not display search
									results from the Service through another organization/business
									website); or
								</span>
							</p>
						</li>
						<li>
							<p>
								<span>
									use the Service to (a) raise funds or sell any advertising,
									sponsorships, or promotions placed on, around, or within the
									Service or Content; or (b) raise funds or sell advertising,
									sponsorships, or promotions on any page of any website or
									application that primarily contains Content from the Service
									or where Content from the Service is the primary basis for
									such sales.
								</span>
							</p>
						</li>
					</ol>
					<p>
						<u>
							<span>Reservation</span>
						</u>
						<span>.&nbsp;</span>
						<span>
							Using the Service does not give you ownership of or rights to any
							aspect of the Service, including user names or any other Content
							posted by others or Scout.
						</span>
					</p>
					<p>
						<u>
							<span>Release</span>
						</u>
						<span>.&nbsp;</span>
						<span>
							You hereby release Scout, our officers, employees, agents and
							successors from claims, demands, and any and all losses, damages,
							rights, claims, and actions of any kind including, without
							limitation, personal injuries, death, and property damage, that is
							either directly or indirectly related to or arises from your
							participation in any Volunteer Opportunities or activities arising
							from or related to your use of the Services.
						</span>
					</p>
					<h2>
						<span>Develop, Improve and Update the Service</span>
					</h2>
					<p>
						<span>
							Scout is constantly changing and improving the Service. As part of
							this continual evolution, we may make modifications or changes (to
							all or part of the Service) such as adding or removing features
							and functionalities, offering new digital content or services or
							discontinuing old ones. We may also need to alter or discontinue
							the Service, or any part of it, in order to make performance or
							security improvements, make changes to comply with law, or prevent
							illegal activities on or abuse of our systems. These changes may
							affect all users, some users or even an individual user. If we
							make material changes that negatively impact your use of the
							Service, we&rsquo;ll provide you with reasonable advance notice,
							except in urgent situations such as preventing abuse, responding
							to legal requirements, or addressing security and operability
							issues. We&rsquo;ll also make best efforts to provide you with an
							opportunity to export your content from your Scout Account,
							subject to applicable law and policies.&nbsp;
						</span>
					</p>
					<h2>
						<span>INTELLECTUAL PROPERTY</span>
					</h2>
					<p>
						<span>
							You acknowledge that Scout and its suppliers own all right, title
							and interest in and to the Services, including without limitation,
							the Site, the Syndication Services, the Code, any Content
							available through or on any of the foregoing (excluding Your
							Content and Member Content), and all underlying software and
							technology, including without limitation all Intellectual Property
							Rights. The provision of the Services does not transfer to you or
							any third party any rights, title, or interest in or to such
							Intellectual Property Rights. VolunteerMatch and its suppliers
							reserve all rights not granted in these Terms. &ldquo;
						</span>
						<strong>
							<span>Intellectual Property Rights</span>
						</strong>
						<span>
							&rdquo; means any and all rights existing from time to time under
							patent law, copyright law, trade secret law, trademark law, unfair
							competition law, and any and all other proprietary rights, and any
							and all applications, renewals, extensions and restorations
							thereof, now or hereafter in force and effect worldwide.
						</span>
					</p>
					<h2>
						<span>TERM AND TERMINATION</span>
					</h2>
					<p>
						<u>
							<span>Term</span>
						</u>
						<span>
							. These Terms commence on the date when you accept them (as
							described in the preamble above) and remain in full force and
							effect while you use the Services, unless terminated earlier in
							accordance with these Terms.
						</span>
					</p>
					<p>
						<span>
							Termination of Services by Scout. Scout has the right to,
							immediately and without notice, suspend or terminate any Services
							provided to you at its sole discretion for any or no reason,
							including but not limited to (a) if you have (or if Scout has any
							reason to believe you may have) breached any provision of these
							Terms, or (b) if Scout is required to do so by law (for example,
							if provision of the Services is, or becomes, unlawful). You agree
							that all terminations shall be made at Scout&rsquo;s sole
							discretion and that Scout shall not be liable to you or any third
							party for any termination of your Account.
						</span>
					</p>
					<p>
						<u>
							<span>Termination of Services by You</span>
						</u>
						<span>
							. If you want to terminate the Services provided by Scout, you may
							do so by closing your Account for all of the Services that you
							use.&nbsp;
						</span>
					</p>
					<p>
						<u>
							<span>Effect of Termination</span>
						</u>
						<span>
							. Termination of any Service includes removal of access to such
							Service and barring of further use of the Service. Termination of
							all Services also includes deletion of your password and all
							related information, files, and Content associated with or inside
							your Account (or any part thereof), including Your Content. Upon
							termination of any Service, your right to use such Service will
							automatically terminate immediately. You understand that any
							termination of Services may involve deletion of Your Content
							associated therewith from our live databases. Scout will not have
							any liability whatsoever to you for any suspension or termination,
							including for deletion of Your Content. All provisions of these
							Terms which by their nature should survive, shall survive
							termination of Services and these Terms, including without
							limitation, ownership provisions, indemnification provisions,
							warranty disclaimers, and limitation of liability.
						</span>
					</p>
					<p>
						<u>
							<span>No Subsequent Registration</span>
						</u>
						<span>
							. If your registration(s) with, or ability to access, the Services
							or any other Scout community is discontinued by Scout due to your
							violation of any portion of the Terms or for conduct otherwise
							inappropriate for the community (including but not limited to your
							conduct at any Volunteer Opportunity, if applicable), then you
							agree that you shall not attempt to create an Account or
							re-register with or access the Services or any Scout community
							through use of a different member name or otherwise. In the event
							that you violate the immediately preceding sentence, Scout
							reserves the right, in its sole discretion, to immediately take
							any or all of the actions set forth herein without any notice or
							warning to you.
						</span>
					</p>
					<h2>
						<span>INDEMNIFICATION</span>
					</h2>
					<p>
						<span>
							You shall indemnify and hold Scout, its parents, subsidiaries,
							affiliates, officers, employees, agents, partners, suppliers, and
							licensors harmless from any and all damages, judgments, losses,
							costs, liabilities and expenses (including, but not limited to,
							reasonable attorneys&rsquo; fees) relating to or arising out of
							any and all of the following: (a) Your Content; (b) your use of
							the Services; (c) your violation of these terms; (d) your
							violation of any rights of another party, including any user; (e)
							your interactions, transactions, communications, or dealings with
							any other user of the Services, including but not limited to those
							related to Volunteer Opportunities; and/or (f) your violation of
							any applicable laws, rules or regulations. Scout reserves the
							right to assume the exclusive defense and control of any matter
							subject to indemnification by you, in which event you will fully
							cooperate with Scout in asserting any available defenses. This
							provision does not require you to indemnify any of the Scout
							Parties for any unconscionable commercial practice by such party
							or for such party&rsquo;s fraud, deception, false promise,
							misrepresentation or concealment, or suppression or omission of
							any material fact in connection with the Services provided
							hereunder. You agree that the provisions in this section will
							survive any termination of your Account, these Terms, and/or your
							access to the Services.
						</span>
					</p>
					<h2>
						<span>DISCLAIMER OF WARRANTIES</span>
					</h2>
					<p>
						<u>
							<span>As is</span>
						</u>
						<span>
							. You expressly agree that use of the services is at your sole
							risk. The services are all provided on an &ldquo;as is&rdquo; and
							&ldquo;as available&rdquo; basis. Scout expressly disclaims all
							warranties of any kind, whether express, implied, or statutory,
							including, but not limited to the implied warranties of
							merchantability, fitness for a particular use or purpose,
							accuracy, and non-infringement. Scout makes no warranty that the
							services will meet your requirements, or that the services will be
							uninterrupted, timely, secure, or error free; nor does Scout make
							any warranty as to the results that may be obtained from the use
							of the services or as to the accuracy or reliability of any
							information obtained through the services, or that defects in the
							services will be corrected. You understand and agree that any
							material and/or information downloaded or otherwise obtained
							through the use of the services is done at your own discretion and
							risk and that you will be solely responsible for any damage to
							your computer services or loss of data that results from the
							download of such material and/or information. Scout makes no
							warranty regarding any dealings with or transactions entered into
							with any other parties (including volunteer opportunities) through
							the services. The entire risk as to satisfactory quality,
							performance, accuracy, effort and results to be obtained through
							the use of the services is with you. No advice or information,
							whether oral or written, obtained by you from Scout or through the
							services shall create any warranty not expressly made herein.
						</span>
					</p>
					<p>
						<u>
							<span>No Liability for Conduct of Third Parties</span>
						</u>
						<span>
							. You are solely responsible for all of your communications and
							interactions with third parties on the service. You acknowledge
							and agree that Scout parties are not liable, and you agree not to
							seek to hold the Scout parties liable, for the conduct of third
							parties, including other users of the services and operators of
							external sites, and that the risk of injury from such third
							parties rests entirely with you. You understand that Scout does
							not make any attempt to verify the statements of users, including
							any Scout members. Scout makes no warranty that the goods or
							services provided by third parties will meet your requirements or
							be available on an uninterrupted, secure, or error-free
							basis.&nbsp;
						</span>
					</p>
					<p>
						<u>
							<span>Disclaimer of Certain Damages</span>
						</u>
						<span>
							. You understand that to the extent permitted under applicable
							law, in no event will Scout or its officers, employees, directors,
							parents, subsidiaries, affiliates, agents or licensors be liable
							for any indirect, incidental, special, consequential, punitive, or
							exemplary damages, including but not limited to, damages for loss
							of revenues, profits, goodwill, use, data or other intangible
							losses (even if such parties were advised of, knew of or should
							have known of the possibility of such damages, and notwithstanding
							the failure of essential purpose of any limited remedy), arising
							out of or related to your use of the services, regardless of
							whether such damages are based on contract, tort (including
							negligence and strict liability), warranty, statute, or otherwise.
							If you are dissatisfied with any portion of the services, your
							sole and exclusive remedy is to discontinue use of the site.&nbsp;
						</span>
					</p>
					<p>
						<u>
							<span>Exclusions And Limitations</span>
						</u>
						<span>
							. Some jurisdictions do not allow the exclusion of certain
							warranties or the limitation or exclusion of liability for
							incidental or consequential damages. Accordingly, some of the
							above limitations and disclaimers may not apply to you. To the
							extent that we may not, as a matter of applicable law, disclaim
							any implied warranty or limit its liabilities, the scope and
							duration of such warranty and the extent of our liability shall be
							the minimum permitted under such applicable law.
						</span>
					</p>
					<h2>
						<span>ARBITRATION AGREEMENT</span>
					</h2>
					<p>
						<span>
							Please read this section (the &ldquo;Arbitration Agreement&rdquo;)
							carefully. It is part of your contract with Scout and affects your
							rights. It contains procedures for mandatory binding arbitration
							and a class action waiver.&nbsp;
						</span>
					</p>
					<p>
						<span>
							Unless you opt out of the arbitration agreement (as defined below)
							within thirty (30) days in accordance with the 30-day right to opt
							out (described below): (1) you will only be permitted to pursue
							disputes or claims and seek relief against us on an individual
							basis, not as a plaintiff or class member in any class or
							representative action or proceeding, and you waive your right to
							participate in a class action lawsuit or class-wide arbitration;
							and (2) you are waiving your right to pursue disputes or claims
							and seek relief in a court of law and to have a jury trial.&nbsp;
						</span>
					</p>
					<p>
						<u>
							<span>Applicability of Arbitration Agreement</span>
						</u>
						<span>
							. Subject to the terms of this Arbitration Agreement, you and
							Scout agree that any dispute, claim, disagreements arising out of
							or relating in any way to your access to or use of the Services,
							any communications you receive, any services distributed or
							received through these Terms and prior versions of these Terms,
							including claims and disputes that arose between you and us before
							the effective date of these Terms (each, a &ldquo;Dispute&rdquo;)
							will be resolved by binding arbitration, rather than in court,
							except that: (a) you and Scout may assert claims or seek relief in
							small claims court if such claims qualify and remain in small
							claims court; and (b) you or Scout may seek equitable relief in
							court for infringement or other misuse of intellectual property
							rights (such as trademarks, trade dress, domain names, trade
							secrets, copyrights, and patents). For purposes of this
							Arbitration Agreement, &ldquo;Dispute&rdquo; will also include
							disputes that arose or involve facts occurring before the
							existence of this or any prior versions of these Terms as well as
							claims that may arise after the termination of these Terms.&nbsp;
						</span>
					</p>
					<p>
						<u>
							<span>Informal Dispute Resolution</span>
						</u>
						<span>
							. There might be instances when a Dispute arises between you and
							Scout. If that occurs, Scout is committed to working with you to
							reach a reasonable resolution. You and Scout agree that good faith
							informal efforts to resolve Disputes can result in a prompt,
							low‐cost and mutually beneficial outcome (&ldquo;Informal Dispute
							Resolution&rdquo;). You and Scout therefore agree that before
							either party commences arbitration against the other (or initiates
							an action in small claims court if a party so elects), we will
							personally meet and confer telephonically or via videoconference,
							in a good faith effort to resolve informally any Dispute covered
							by this Arbitration Agreement (&ldquo;Informal Dispute Resolution
							Conference&rdquo;). If you are represented by counsel, your
							counsel may participate in the conference, but you will also
							participate in the conference.
						</span>
					</p>
					<p>
						<span>
							The party initiating a Dispute must give notice to the other party
							in writing of its intent to initiate an Informal Dispute
							Resolution Conference (&ldquo;Notice&rdquo;), which shall occur
							within forty-five (45) days after the other party receives such
							Notice, unless an extension is mutually agreed upon by the
							parties. Notice to Scout that you intend to initiate an Informal
							Dispute Resolution Conference by contacting us at the&nbsp;
						</span>
						<a href="mailto:info@samaritanscout.org">
							<u>
								<span>info@samaritanscout.org</span>
							</u>
						</a>
						<span>
							&nbsp;email. The Notice must include: (1) your name, telephone
							number, mailing address, email address associated with your
							Account (if you have one); (2) the name, telephone number, mailing
							address and e‐mail address of your counsel, if any; and (3) a
							description of your Dispute.
						</span>
					</p>
					<p>
						<span>
							The Informal Dispute Resolution Conference shall be individualized
							such that a separate conference must be held each time either
							party initiates a Dispute, even if the same law firm or group of
							law firms represents multiple users in similar cases, unless all
							parties agree; multiple individuals initiating a Dispute cannot
							participate in the same Informal Dispute Resolution Conference
							unless all parties agree. In the time between a party receiving
							the Notice and the Informal Dispute Resolution Conference, nothing
							in this Arbitration Agreement shall prohibit the parties from
							engaging in informal communications to resolve the initiating
							party&rsquo;s Dispute. Engaging in the Informal Dispute Resolution
							Conference is a condition precedent and requirement that must be
							fulfilled before commencing arbitration. The statute of
							limitations and any filing fee deadlines shall be tolled while the
							parties engage in the Informal Dispute Resolution Conference
							process required by this section.
						</span>
					</p>
					<p>
						<u>
							<span>Waiver of Jury Trial</span>
						</u>
						<span>
							. You and Scout hereby waive any constitutional and statutory
							rights to sue in court and have a trial in front of a judge or a
							jury. You and Scout are instead electing that all Disputes shall
							be resolved by arbitration under this Arbitration Agreement. There
							is no judge or jury in arbitration, and court review of an
							arbitration award is subject to very limited review.&nbsp;
						</span>
					</p>
					<p>
						<u>
							<span>Waiver of Class and Other Non-Individualized Relief</span>
						</u>
						<span>
							. You and Scout agree that, except as specified in Batch
							Arbitration (described below), each of us may bring claims against
							the other only on an individual basis and not on a class,
							representative, or collective basis, and the parties hereby waive
							all rights to have any dispute be brought, heard, administered,
							resolved, or arbitrated on a class, collective, representative, or
							mass action basis. Only individual relief is available, and
							disputes of more than one customer or user cannot be arbitrated or
							consolidated with those of any other customer or user. Subject to
							this Arbitration Agreement, the arbitrator may award declaratory
							or injunctive relief only in favor of the individual party seeking
							relief and only to the extent necessary to provide relief
							warranted by the party&rsquo;s individual claim. Nothing in this
							paragraph is intended to, nor shall it, affect the terms and
							conditions under Batch Arbitration (described below).
							Notwithstanding anything to the contrary in this Arbitration
							Agreement, if a court decides by means of a final decision, not
							subject to any further appeal or recourse, that the limitations of
							this section are invalid or unenforceable as to a particular claim
							or request for relief (such as a request for public injunctive
							relief), you and Scout agree that that particular claim or request
							for relief (and only that particular claim or request for relief)
							shall be severed from the arbitration and may be litigated in the
							state or federal courts located in the State of New Jersey. All
							other Disputes shall be arbitrated or litigated in small claims
							court. This section does not prevent you or Scout from
							participating in a class-wide settlement of claims.
						</span>
					</p>
					<p>
						<u>
							<span>Rules and Forum</span>
						</u>
						<span>
							. These Terms evidence a transaction involving interstate
							commerce; and notwithstanding any other provision herein with
							respect to the applicable substantive law, the Federal Arbitration
							Act, 9 U.S.C. &sect; 1 et seq., will govern the interpretation and
							enforcement of this Arbitration Agreement and any arbitration
							proceedings. If the Informal Dispute Resolution process described
							above does not resolve satisfactorily within sixty (60) days after
							receipt of your Notice, you and VolunteerMatch agree that either
							party shall have the right to finally resolve the Dispute through
							binding arbitration. The arbitration will be administered by the
							American Arbitration Association (&ldquo;AAA&rdquo;), in
							accordance with the Consumer Arbitration Rules (the &ldquo;AAA
							Rules&rdquo;) then in effect, except as modified by this section
							of this Arbitration Agreement. The AAA Rules are currently
							available at&nbsp;
						</span>
						<a href="https://www.adr.org/sites/default/files/Consumer%20Rules.pdf">
							<u>
								<span>Consumer Arbitration Rules</span>
							</u>
						</a>
						<span>.</span>
					</p>
					<p>
						<span>
							A party who wishes to initiate arbitration must provide the other
							party with a request for arbitration (the &ldquo;Request&rdquo;).
							The Request must include: (a) the name, telephone number, mailing
							address, email address of the party seeking arbitration and the
							account username (if applicable) as well as the email address
							associated with any applicable Account; (b) a statement of the
							legal claims being asserted and the factual bases of those claims;
							(c) a description of the remedy sought and an accurate, good‐faith
							calculation of the amount in controversy in United States dollars;
							(d) a statement certifying completion of the Informal Dispute
							Resolution process as described above; and (e) evidence that the
							requesting party has paid any necessary filing fees in connection
							with such arbitration.&nbsp;
						</span>
					</p>
					<p>
						<span>
							If the party requesting arbitration is represented by counsel, the
							Request shall also include counsel&rsquo;s name, telephone number,
							mailing address, and email address. Such counsel must also sign
							the Request. By signing the Request, counsel certifies to the best
							of counsel&rsquo;s knowledge, information, and belief, formed
							after an inquiry reasonable under the circumstances, that: (1) the
							Request is not being presented for any improper purpose, such as
							to harass, cause unnecessary delay, or needlessly increase the
							cost of dispute resolution; (2) the claims, defenses and other
							legal contentions are warranted by existing law or by a
							nonfrivolous argument for extending, modifying, or reversing
							existing law or for establishing new law; and (3) the factual and
							damages contentions have evidentiary support or, if specifically
							so identified, will likely have evidentiary support after a
							reasonable opportunity for further investigation or
							discovery.&nbsp;
						</span>
					</p>
					<p>
						<span>
							Unless you and Scout otherwise agree, or the Batch Arbitration
							process discussed below (Batch Arbitration) is triggered, the
							arbitration will be conducted in the county where you reside.
							Subject to the AAA Rules, the arbitrator may direct a limited and
							reasonable exchange of information between the parties, consistent
							with the expedited nature of the arbitration. If the AAA is not
							available to arbitrate, the parties will select an alternative
							arbitral forum. Your responsibility to pay any AAA fees and costs
							will be solely set forth in the applicable AAA Rules.&nbsp;
						</span>
					</p>
					<p>
						<span>
							You and Scout agree that all materials and documents exchanged
							during the arbitration proceedings shall be kept confidential and
							shall not be shared with anyone except the parties&rsquo;
							attorneys, accountants, or business advisors, and shall be subject
							to the condition that they agree to keep all materials and
							documents exchanged during the arbitration proceedings
							confidential.&nbsp;
						</span>
					</p>
					<p>
						<u>
							<span>Arbitrator</span>
						</u>
						<span>
							. The arbitrator will be either a retired judge or an attorney
							licensed to practice law in the state of New Jersey and will be
							selected by the parties from the AAA&rsquo;s roster of consumer
							dispute arbitrators. If the parties are unable to agree upon an
							arbitrator within thirty-five (35) days of delivery of the
							Request, then the AAA will appoint the arbitrator in accordance
							with the AAA Rules, provided that if the Batch Arbitration process
							below (Batch Arbitration) is triggered, the AAA will appoint the
							arbitrator for each batch.
						</span>
					</p>
					<p>
						<u>
							<span>Authority of Arbitrator</span>
						</u>
						<span>
							. The arbitrator shall have exclusive authority to resolve any
							Dispute, including, without limitation, disputes arising out of or
							related to the interpretation or application of the Arbitration
							Agreement, including the enforceability, revocability, scope, or
							validity of the Arbitration Agreement or any portion of the
							Arbitration Agreement, except for the following: (a) all Disputes
							arising out of or relating to above (Waiver of Class and Other
							Non-Individualized Relief), including any claim that all or part
							is unenforceable, illegal, void or voidable, or has been breached,
							shall be decided by a court of competent jurisdiction and not by
							an arbitrator; (b) except as expressly contemplated in below
							(Batch Arbitration), all Disputes about the payment of arbitration
							fees shall be decided only by a court of competent jurisdiction
							and not by an arbitrator; (c) all Disputes about whether either
							party has satisfied any condition precedent to arbitration shall
							be decided only by a court of competent jurisdiction and not by an
							arbitrator; and (d) all Disputes about which version of the
							Arbitration Agreement applies shall be decided only by a court of
							competent jurisdiction and not by an arbitrator. The arbitration
							proceeding will not be consolidated with any other matters or
							joined with any other cases or parties, except as expressly
							provided below (Batch Arbitration). The arbitrator shall have the
							authority to grant motions dispositive of all or part of any
							Dispute. The arbitrator shall issue a written award and statement
							of decision describing the essential findings and conclusions on
							which the award is based, including the calculation of any damages
							awarded. The award of the arbitrator is final and binding upon you
							and us. Judgment on the arbitration award may be entered in any
							court having jurisdiction.
						</span>
					</p>
					<p>
						<u>
							<span>Attorneys&rsquo; Fees and Costs</span>
						</u>
						<span>
							. The parties shall bear their own attorneys&rsquo; fees and costs
							in arbitration unless the arbitrator finds that either the
							substance of the Dispute or the relief sought in the Request was
							frivolous or was brought for an improper purpose (as measured by
							the standards set forth in Federal Rule of Civil Procedure 11(b)).
							If you or Scout need to invoke the authority of a court of
							competent jurisdiction to compel arbitration, then the party that
							obtains an order compelling arbitration in such action shall have
							the right to collect from the other party its reasonable costs,
							necessary disbursements, and reasonable attorneys&rsquo; fees
							incurred in securing an order compelling arbitration. The
							prevailing party in any court action relating to whether either
							party has satisfied any condition precedent to arbitration,
							including the Informal Dispute Resolution process, is entitled to
							recover their reasonable costs, necessary disbursements, and
							reasonable attorneys&rsquo; fees and costs.&nbsp;
						</span>
					</p>
					<p>
						<u>
							<span>Batch Arbitration</span>
						</u>
						<span>
							. To increase the efficiency of administration and resolution of
							arbitrations, you and Scout agree that in the event that there are
							one hundred (100) or more individual Requests of a substantially
							similar nature filed against Scout by or with the assistance of
							the same law firm, group of law firms, or organizations, within a
							thirty (30) day period (or as soon as possible thereafter), the
							AAA shall (a) administer the arbitration demands in batches of 100
							Requests per batch (plus, to the extent there are less than 100
							Requests left over after the batching described above, a final
							batch consisting of the remaining Requests); (b) appoint one
							arbitrator for each batch; and (c) provide for the resolution of
							each batch as a single consolidated arbitration with one set of
							filing and administrative fees due per side per batch, one
							procedural calendar, one hearing (if any) in a place to be
							determined by the arbitrator, and one final award (&ldquo;Batch
							Arbitration&rdquo;).
						</span>
					</p>
					<p>
						<span>
							All parties agree that Requests are of a &ldquo;substantially
							similar nature&rdquo; if they arise out of or relate to the same
							event or factual scenario and raise the same or similar legal
							issues and seek the same or similar relief. To the extent the
							parties disagree on the application of the Batch Arbitration
							process, the disagreeing party shall advise the AAA, and the AAA
							shall appoint a sole standing arbitrator to determine the
							applicability of the Batch Arbitration process
							(&ldquo;Administrative Arbitrator&rdquo;). In an effort to
							expedite resolution of any such dispute by the Administrative
							Arbitrator, the parties agree the Administrative Arbitrator may
							set forth such procedures as are necessary to resolve any disputes
							promptly.&nbsp;
						</span>
					</p>
					<p>
						<span>
							You and Scout agree to cooperate in good faith with the AAA to
							implement the Batch Arbitration process including the payment of
							single filing and administrative fees for batches of Requests, as
							well as any steps to minimize the time and costs of arbitration,
							which may include: (1) the appointment of a discovery special
							master to assist the arbitrator in the resolution of discovery
							disputes; and (2) the adoption of an expedited calendar of the
							arbitration proceedings.
						</span>
					</p>
					<p>
						<span>
							This Batch Arbitration provision shall in no way be interpreted as
							authorizing a class, collective and/or mass arbitration or action
							of any kind, or arbitration involving joint or consolidated claims
							under any circumstances, except as expressly set forth in this
							provision.
						</span>
					</p>
					<p>
						<u>
							<span>Invalidity, Expiration</span>
						</u>
						<span>
							. Except as provided above (Waiver of Class or Other
							Non-Individualized Relief), if any part or parts of this
							Arbitration Agreement are found under the law to be invalid or
							unenforceable, then such specific part or parts shall be of no
							force and effect and shall be severed and the remainder of the
							Arbitration Agreement shall continue in full force and effect. You
							further agree that any Dispute that you have with Scout as
							detailed in this Arbitration Agreement must be initiated via
							arbitration within the applicable statute of limitation for that
							claim or controversy, or it will be forever time barred. Likewise,
							you agree that all applicable statutes of limitation will apply to
							such arbitration in the same manner as those statutes of
							limitation would apply in the applicable court of competent
							jurisdiction.
						</span>
					</p>
					<h2>
						<span>MODIFICATIONS TO TERMS</span>
					</h2>
					<p>
						<span>
							We may change the Terms from time to time. When changes are made,
							Scout will make a new copy of the Terms available on the Site, and
							we will also update the &ldquo;Last Update&rdquo; date at the top
							of the Terms. If we make any material changes and you have
							registered an Account with us, we will also send an email with an
							updated copy of these Terms to you at the email address associated
							with your Account. Scout may require you to provide consent to the
							updated Terms in a specified manner before further use of the
							Services is permitted. If you do not agree to any change(s) after
							receiving a notice of such change(s), you shall stop using the
							services. Unless otherwise stated in such update, any changes to
							these Terms will be effective immediately for users without an
							Account, and thirty (30) days after posted on the Site, unless
							subject to earlier consent or acceptance, in which case, changes
							shall be effective upon such consent or acceptance. Your continued
							access to the Site or use of the Services after that time shall
							constitute your acceptance of the amended Terms. You are
							responsible for providing us with your most current email address.
							In the event that the last email address you have provided us is
							not valid, or for any reason is not capable of delivering to you
							the notice described above, our dispatch of the email containing
							such notice will nonetheless constitute effective notice of the
							changes described in the notice. If you object to any such
							changes, your sole and exclusive remedy shall be to cease using
							the Services and terminate your Account.
						</span>
					</p>
					<h2>
						<span>GENERAL PROVISIONS</span>
					</h2>
					<p>
						<u>
							<span>Electronic Communications</span>
						</u>
						<span>
							. The communications between you and Scout may take place via
							electronic means, whether you visit the Service or send Scout
							emails, or whether Scout posts notices on the Services or
							communicates with you via email. For contractual purposes, you (a)
							consent to receive communications from Scout in an electronic
							form; and (b) agree that all terms and conditions, agreements,
							notices, disclosures, and other communications that Scout
							electronically provides to you satisfy any legal requirement that
							such communications would satisfy if it were to be in writing. The
							foregoing does not affect your statutory rights, including but not
							limited to the Electronic Signatures in Global and National
							Commerce Act at 15 U.S.C. &sect;7001 et seq.
						</span>
					</p>
					<p>
						<u>
							<span>Questions, Complaints, Claims</span>
						</u>
						<span>
							. If you have any questions, complaints or claims with respect to
							the Services, please{" "}
						</span>
						<a href={"/contact"}>
							<span>contact us.</span>
						</a>
						<span>
							{" "}
							If you feel that your concerns have been addressed incompletely,
							we invite you to let us know for further investigation.&nbsp;
						</span>
					</p>
				</div>
			</div>
		</>
	);
}

export default Terms;
