import {
  Typography,
  Stack,
  Paper,
  ButtonBase,
  Box,
  Link,
  Chip,
  useMediaQuery,
  Button,
  Tooltip,
  IconButton,
  Divider,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  NativeSelect,
  OutlinedInput,
  Checkbox,
  ListItemText,
  FormControlLabel,
  FormGroup,
  DialogContentText,
} from "@mui/material";
import theme from "../components/MaterialTheme";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { Fragment, useState } from "react";
import MaterialTheme from "../components/MaterialTheme";
import { useFilterMaps } from "../hooks/useFilterMaps";
import { Image } from "mui-image";
import { fetcher, VO } from "../utils/fetchData";
import { Icons } from "./Icons";
import {
  amber,
  blue,
  cyan,
  deepOrange,
  deepPurple,
  green,
  indigo,
  lightBlue,
  lightGreen,
  lime,
  orange,
  pink,
  purple,
  red,
  teal,
  yellow,
} from "@mui/material/colors";
import {
  Favorite,
  FavoriteBorder,
  Flag,
  FlagOutlined,
} from "@mui/icons-material";
import { useMe } from "../hooks/useResults";
import { mutate } from "swr";
import { User } from "../utils/auth";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
/**
 * Because `new Date(null)` returns the epoch, we need to
 * specifically check for null values and return null.
 */
function normalizeDate(date: string | null): number | null {
  if (date === null) return null; // Maintain null tolerance
  return Number(date); // Convert string to number
}

const stringToColor = (str: string): string => {
  const themes = [
    red,
    pink,
    purple,
    deepPurple,
    indigo,
    blue,
    lightBlue,
    cyan,
    teal,
    green,
    lightGreen,
    amber,
    orange,
    deepOrange,
  ];

  // Get hash code of the string
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }

  // Calculate color index
  const index = Math.abs(hash % themes.length);
  return themes[index][500];
};

function convertTimestamp(timestamp: number): Date {
  const dateCheck = new Date(timestamp);
  if (dateCheck.getFullYear() < 1970) {
    timestamp /= 1000;
  }
  return new Date(timestamp * 1000); // Convert seconds to milliseconds for JavaScript Date
}

function formatDate(date1: number | null, date2: number | null): string {
  // Convert timestamps to Date objects using convertTimestamp
  const convertedDate1 = date1 ? convertTimestamp(date1) : null;
  const convertedDate2 = date2 ? convertTimestamp(date2) : null;

  function formatDatePart(date: Date | null): string {
    if (!date) return "";
    return date.toLocaleDateString("en-US", {
      month: "short",
      day: "numeric",
      year: "numeric",
    });
  }

  if (!convertedDate1 && !convertedDate2) {
    return ""; // If no dates are provided, return an empty string
  }

  if (convertedDate1 && !convertedDate2) {
    return `Starts: ${formatDatePart(convertedDate1)}`;
  }

  if (!convertedDate1 && convertedDate2) {
    return `Ends: ${formatDatePart(convertedDate2)}`;
  }

  if (convertedDate1 && convertedDate2) {
    function isSameDay(d1: Date, d2: Date): boolean {
      return (
        d1.getDate() === d2.getDate() &&
        d1.getMonth() === d2.getMonth() &&
        d1.getFullYear() === d2.getFullYear()
      );
    }

    if (isSameDay(convertedDate1, convertedDate2)) {
      return formatDatePart(convertedDate1);
    } else {
      return `${formatDatePart(convertedDate1)} - ${formatDatePart(convertedDate2)}`;
    }
  }

  return ""; // Default return statement to satisfy TypeScript's requirement for all paths to return a value
}

const grayText = MaterialTheme.palette.grey.A400;
export const grayBgColor = MaterialTheme.palette.grey.A100;

// Define the props interface for your SearchResult component
function SearchResult(props: { vo: VO | User["saved"][0] }) {
  const { data: me, mutate } = useMe();
  const [imgError, setImgError] = useState(false);
  const [expanded, setExpanded] = useState(false);

  const vo = props.vo;

  const title = vo.vo_title || "";
  const causes = vo.org_causes || [];
  const link = vo.vo_url || "";
  const imageUrl = vo.logo_url || "";
  const skills = vo.vo_skills || [];
  const minAge = vo.vo_min_age;
  const description = vo.vo_description || "";
  const orgName = vo.org_name || "";

  const isSaved = me ? me.saved.some((v) => v.id === vo.id) : false;

  const [showMore, setShowMore] = useState(false);

  const [showPreview, setShowPreview] = useState(false);

  const { filterMaps } = useFilterMaps();

  const handleToggleMoreInfo = () => {
    setShowMore(!showMore);
  };

  const handleTogglePreview = () => {
    setShowPreview(!showPreview);
  };

  /**
   *  Get the long form of causes from the filterMaps. Use the enum key as backup.
   */
  const getCauseValues = () => {
    return causes.join(", ");
  };

  /**
   *  Get the long form of skills from the filterMaps. Use the enum key as backup.
   */
  const getSkillValues = () => {
    return skills.join(", ");
  };

  const updateSaved = async (save: boolean) => {
    if (!me) return;

    await mutate(
      (v) => {
        return {
          ...(v ?? me),
          saved: save
            ? [...(v ?? me).saved, { ...vo, created_at: dayjs().format() }]
            : (v ?? me).saved.filter((v) => v.id !== vo.id),
        };
      },
      {
        revalidate: false,
      },
    );
    await fetcher(save ? "POST" : "DELETE", "/save", {
      id: vo.id,
    }).then(async () => {
      await mutate();
    });
  };

  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const isLarge = useMediaQuery(theme.breakpoints.up("lg"));

  return (
    <Paper
      sx={{
        flexGrow: 1,
        backgroundColor: grayBgColor,
        borderRadius: "12px",
        transition: "all 0.3s ease-in-out", // Add transition for smooth expand/collapse
        p: isMobile ? 2 : 3,
        boxShadow:
          "0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1)",
        marginBottom: "16px",
        width: "100%",
        boxSizing: "border-box",
      }}
    >
      <Stack
        direction={{ md: "row", sm: "column" }}
        spacing={{ md: 4, sm: 1, xs: 1 }}
        width="100%"
      >
        {/* Left column: logo image, 'Visit Ste' link.  And also somehow, the card title, at top of right column. */}
        <Stack
          direction={{ xs: "row", md: "column" }}
          spacing={2}
          alignItems="center"
        >
          {/* Logo image */}
          <Link href={link} target="_blank" rel="noreferrer">
            <Box
              display="flex"
              sx={{
                borderRadius: "16px",
                overflow: "hidden",
                backgroundColor: "#EEE",
              }}
              width={isMobile ? 60 : 160}
              height={isMobile ? 60 : 160}
              alignItems="center"
              justifyContent="center"
            >
              <Image
                alt="Opportunity image"
                src={imgError ? "/images/placeholder.jpg" : imageUrl}
                duration={500} // fade in ms
                fit="contain"
                onError={() => setImgError(true)}
              />
            </Box>
          </Link>
          {/* 'Visit Site' button for desktop */}
          <Button
            component={Link}
            variant="contained"
            sx={{
              width: "100%",
              backgroundColor: theme.palette.primary.light,
              border: `1px solid ${theme.palette.primary.light}`,
              color: "white",
              "&:hover, &:focus": {
                backgroundColor: theme.palette.primary.main,
                border: `1px solid ${theme.palette.primary.main}`,
                boxShadow: "none",
              },
              [theme.breakpoints.down("md")]: { display: "none" },
              textTransform: "none",
              boxShadow: "none",
            }}
            endIcon={<OpenInNewIcon />}
            href={link}
            target="_blank"
            rel="noreferrer"
          >
            {"View Site"}
          </Button>
          {/* Card title */}
          <Typography
            gutterBottom
            variant="h2"
            component="h2"
            fontWeight={600}
            sx={{
              [theme.breakpoints.up("md")]: { display: "none" },
              mt: "auto",
              mb: "auto",
              width: "auto",
              flexGrow: 1,
            }}
            overflow={"hidden"}
            textOverflow={"ellipsis"}
          >
            {title}
          </Typography>

          {isMobile && (
            <ActionButtons
              isSaved={isSaved}
              updateSaved={updateSaved}
              voId={vo.id}
            />
          )}
        </Stack>
        {/* Right column: location, causes, skills, date/time, description, and 'More Info' button */}
        <Stack
          direction="column"
          sx={{
            alignItems: "flex-start",
            width: "100%",
          }}
          spacing={{ md: 1, sm: 1, xs: 1 }}
        >
          {/* Title (only visible on desktop) */}
          <Stack
            direction={"row"}
            sx={{
              width: "100%",
            }}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Typography
              gutterBottom
              variant="h2"
              component="h2"
              fontWeight={600}
              sx={{
                [theme.breakpoints.down("md")]: { display: "none" },
              }}
              overflow={"hidden"}
              textOverflow={"ellipsis"}
            >
              {title}
            </Typography>
            {!isMobile && (
              <ActionButtons
                isSaved={isSaved}
                updateSaved={updateSaved}
                voId={vo.id}
              />
            )}
          </Stack>
          <Stack
            direction="column"
            spacing={{ md: 1.5, sm: 1.5, xs: 1.5 }}
            sx={{ width: "100%" }}
          >
            {/* Organization Name row */}
            <Stack
              direction={{ md: "column", sm: "column" }}
              spacing={{ md: 1, sm: 1.5, xs: 1.5 }}
            >
              <Stack
                direction={{ lg: "row", xs: "column" }}
                alignItems={{ lg: "center", xs: "unset" }}
                sx={{
                  flexFlow: "wrap",
                }}
              >
                <Typography
                  fontSize={"1.25rem"}
                  fontWeight={400}
                  color={theme.palette.grey["800"]}
                >
                  {orgName}
                </Typography>
                <Divider
                  orientation={isLarge ? "vertical" : undefined}
                  flexItem
                  sx={{
                    marginX: 1,
                    marginY: { xs: 1, lg: 0 },
                  }}
                />
                <Stack
                  direction={"row"}
                  sx={{
                    flexFlow: "wrap",
                    alignItems: "center",
                  }}
                >
                  {vo.locations.length > 0 && (
                    <>
                      <Typography
                        sx={{
                          color: "rgb(88, 88, 88)",
                          textTransform: "capitalize",
                        }}
                        fontWeight={600}
                        variant={"h4"}
                        whiteSpace="break-spaces"
                        marginRight={
                          vo.locations.length > 1 && expanded ? 1 : 0
                        }
                      >
                        {[
                          vo.locations[0].street_address,
                          vo.locations[0].city,
                          vo.locations[0].county,
                          vo.locations[0].state,
                        ]
                          .filter((v) => v && v.length)
                          .join(", ")}
                        {vo.locations[0].distance
                          ? ` (${Math.round((vo.locations[0].distance * 10) / 1609.34) / 10}mi)`
                          : ""}
                      </Typography>
                      {!vo.locations[0].street_address &&
                        !vo.locations[0].city && (
                          <Tooltip
                            title={
                              "We don't know the precise address, please check the organization website"
                            }
                            placement={"right"}
                          >
                            <Icons.Warning
                              fontSize={"small"}
                              htmlColor={grayText}
                              sx={{ marginLeft: 0.5 }}
                            />
                          </Tooltip>
                        )}
                      {vo.locations.length > 1 && (
                        <>
                          {expanded && (
                            <>
                              {vo.locations.slice(1).map((location, index) => (
                                <Fragment key={index}>
                                  <Typography
                                    sx={{
                                      color: "rgb(88, 88, 88)",
                                      textTransform: "capitalize",
                                    }}
                                    fontWeight={600}
                                    variant={"h4"}
                                    whiteSpace={{
                                      xs: "break-spaces",
                                      md: "nowrap",
                                    }}
                                    marginRight={1}
                                  >
                                    {[
                                      location.street_address,
                                      location.city,
                                      location.county,
                                      location.state,
                                    ]
                                      .filter((v) => v && v.length)
                                      .join(", ")}
                                    {location.distance
                                      ? ` (${Math.round((location.distance * 10) / 1609.34) / 10}mi)`
                                      : ""}
                                  </Typography>
                                  {!location.street_address &&
                                    !location.city && (
                                      <Tooltip
                                        title={
                                          "We don't know the precise address, please check the organization website"
                                        }
                                        placement={"right"}
                                      >
                                        <Icons.Warning
                                          fontSize={"small"}
                                          htmlColor={grayText}
                                          sx={{ marginLeft: 0.5 }}
                                        />
                                      </Tooltip>
                                    )}
                                </Fragment>
                              ))}
                            </>
                          )}{" "}
                          <Typography
                            component="span"
                            sx={{
                              color: theme.palette.primary.main,
                              cursor: "pointer",
                              marginLeft: expanded ? 0 : 0.5,
                              textDecoration: "underline",
                              "&:hover": {
                                textDecoration: "underline",
                              },
                            }}
                            onClick={() => setExpanded(!expanded)}
                          >
                            {expanded ? "Less" : "..."}
                          </Typography>
                        </>
                      )}
                    </>
                  )}
                </Stack>
              </Stack>
            </Stack>
          </Stack>
          {/* Causes and skills rows */}
          <Stack direction="column" spacing={{ md: 1, xs: 0.5 }}>
            <Stack
              direction="row"
              sx={{ opacity: getSkillValues().length > 0 ? 1 : 0 }}
              spacing={1}
            >
              <Typography variant="h4" gutterBottom>
                {"Skills:"}
              </Typography>
              {/*{skills.map((skill, index) => (*/}
              {/*  <Chip*/}
              {/*    key={index}*/}
              {/*    label={skill}*/}
              {/*    size={"small"}*/}
              {/*    sx={{*/}
              {/*      backgroundColor: stringToColor(skill),*/}
              {/*      color: "white",*/}
              {/*      borderRadius: "4px",*/}
              {/*      textTransform: "capitalize",*/}
              {/*      whiteSpace: "pre-wrap",*/}
              {/*    }}*/}
              {/*  />*/}
              {/*))}*/}
              <Typography
                variant="h4"
                sx={{
                  marginLeft: 1,
                  color: grayText,
                  textTransform: "capitalize",
                }}
                gutterBottom
              >
                {getSkillValues()}
              </Typography>
            </Stack>
          </Stack>
          <Stack
            sx={{
              display: "flex",
            }}
            direction={"row"}
            spacing={1}
          >
            <Typography
              variant="h4"
              sx={{
                borderRadius: "4px",
                whiteSpace: "pre-wrap",
              }}
              gutterBottom
            >
              {"Causes:"}
            </Typography>
            {/*{causes.map((cause, index) => (*/}
            {/*  <Chip*/}
            {/*    key={index}*/}
            {/*    label={cause}*/}
            {/*    size={"small"}*/}
            {/*    sx={{*/}
            {/*      backgroundColor: stringToColor(cause),*/}
            {/*      color: "white",*/}
            {/*      borderRadius: "4px",*/}
            {/*      textTransform: "capitalize",*/}
            {/*      whiteSpace: "pre-wrap",*/}
            {/*    }}*/}
            {/*  />*/}
            {/*))}*/}
            <Typography
              variant="h4"
              sx={{
                marginLeft: 1,
                color: grayText,
                textTransform: "capitalize",
              }}
              gutterBottom
            >
              {getCauseValues()}
            </Typography>
          </Stack>

          <Stack
            direction="column"
            spacing={{ md: 2.25, sm: 1.5, xs: 1.5 }}
            sx={{ width: "100%", paddingTop: 1 }}
          >
            {/* More Info content: includes date, minAge if present, and full description */}
            {showMore && (
              <>
                {/* Minimum age if present */}
                {minAge > 0 && (
                  <Typography
                    variant="h4"
                    sx={{
                      whiteSpace: "pre-wrap",
                      marginTop: "15px",
                      color: grayText,
                    }}
                    gutterBottom
                  >
                    {"Minimum Age: " + minAge}
                  </Typography>
                )}
                {/* Full description */}
                <Typography
                  variant="body1"
                  sx={{
                    whiteSpace: "pre-wrap",
                    marginTop: "10px",
                    color: grayText,
                    overflow: "auto",
                  }}
                  gutterBottom
                >
                  {description}
                </Typography>
              </>
            )}

            {/* Description preview and More Info link */}
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "1fr 120px", // Use 1fr for the first column to take all available space
                gap: "16px", // Space between the two columns
                width: "100%", // Ensure the container fits within the screen boundaries
                alignItems: "center",
                paddingRight: "16px", // Padding to ensure it doesn't touch the screen edges
              }}
            >
              {!showMore && (
                <Typography
                  variant="body1"
                  sx={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    color: grayText,
                  }}
                >
                  {description}
                </Typography>
              )}
              <ButtonBase
                sx={{
                  width: "120px", // Fixed width for the More Info link
                  textAlign: "right", // Aligns the text to the right
                  flexShrink: 0, // Prevents shrinking
                  paddingLeft: "0px", // Remove any default padding that might indent the button
                  justifyContent: showMore ? "start" : "flex-end", // Ensure button text is aligned right
                }}
                onClick={handleToggleMoreInfo}
              >
                <Typography
                  sx={{
                    cursor: "pointer",
                    color: "#367DD1",
                    textAlign: "left",
                  }}
                  variant="body2"
                >
                  {showMore ? "Less Info" : "More Info"}
                </Typography>
                <ArrowDropDownIcon
                  fontSize="small"
                  sx={{
                    color: "#367DD1",
                    transform: showMore ? "rotate(180deg)" : "rotate(0deg)",
                  }}
                />
              </ButtonBase>
            </Box>
            {/* Visit Site link for mobile */}
            <Stack
              sx={{
                m: "auto",
                [theme.breakpoints.up("md")]: { display: "none" },
              }}
            >
              <ButtonBase
                sx={{
                  alignSelf: "center",
                  width: 178,
                  height: 40,
                  backgroundColor: theme.palette.primary.light,
                  borderRadius: "8px",
                }}
                onClick={() => window.open(link, "_blank")}
              >
                <Typography
                  sx={{ cursor: "pointer", whiteSpace: "pre-wrap" }}
                  variant="body2"
                >
                  {"View Site"}
                </Typography>
                <OpenInNewIcon sx={{ marginLeft: 1 }} fontSize="small" />
              </ButtonBase>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </Paper>
  );
}

const ActionButtons = (props: {
  isSaved: boolean;
  updateSaved: (save: boolean) => Promise<void>;
  voId: string;
}) => {
  const { data: me } = useMe();
  const { isSaved, updateSaved, voId } = props;
  const [openDialog, setOpenDialog] = useState(false);
  const [openLoginDialog, setOpenLoginDialog] = useState(false);
  const [flagReasons, setFlagReasons] = useState<string[]>([]);
  const [additionalInfo, setAdditionalInfo] = useState("");
  const navigate = useNavigate();

  const handleFlagClick = () => {
    if (!me) {
      setOpenLoginDialog(true);
    } else {
      setOpenDialog(true);
    }
  };

  const handleClose = () => {
    setOpenDialog(false);
    setFlagReasons([]);
    setAdditionalInfo("");
  };

  const handleLoginDialogClose = () => {
    setOpenLoginDialog(false);
  };

  const handleSubmit = async () => {
    try {
      await fetcher("POST", "/flag", {
        id: voId,
        flag_reasons: flagReasons,
        flag_details: additionalInfo,
      });
      handleClose();
    } catch (error) {
      console.error("Error submitting flag:", error);
    }
  };

  const reasonsText = [
    { value: "not_available", label: "Volunteer opportunity not available" },
    {
      value: "website_unavailable",
      label: "Organization website not available",
    },
    {
      value: "requires_money",
      label:
        "Opportunity requires volunteers to spend (or ask others to spend) money",
    },
    {
      value: "no_volunteer_process",
      label: "Organization does not provide way to become a volunteer",
    },
    { value: "not_doing_good", label: "Organization is not 'doing good'" },
    { value: "other", label: "Other (please specify below)" },
  ];

  return (
    <Stack direction="row">
      <Tooltip
        title={
          me?.flags.includes(voId)
            ? "You've already flagged this opportunity"
            : ""
        }
      >
        <span>
          <IconButton
            onClick={handleFlagClick}
            disabled={me?.flags.includes(voId)}
          >
            {me?.flags.includes(voId) ? (
              <Flag sx={{ color: theme.palette.grey[400] }} />
            ) : (
              <FlagOutlined sx={{ color: theme.palette.grey[400] }} />
            )}
          </IconButton>
        </span>
      </Tooltip>
      <IconButton
        onClick={async () => {
          if (!me) {
            setOpenLoginDialog(true);
          } else {
            await updateSaved(!isSaved);
          }
        }}
      >
        {isSaved ? (
          <Favorite
            htmlColor={MaterialTheme.palette.primary.light}
            sx={{
              fill: MaterialTheme.palette.primary.light,
            }}
          />
        ) : (
          <FavoriteBorder />
        )}
      </IconButton>
      <Dialog open={openDialog} onClose={handleClose} fullWidth maxWidth="sm">
        <DialogTitle variant="h2">Submit a report</DialogTitle>
        <DialogContent>
          <Typography>
            To help us understand the problem, please choose which of these
            options describe the issue (check all that apply):
          </Typography>
          <FormControl
            component="fieldset"
            margin="normal"
            size="small"
            sx={{
              mx: 1,
              mb: 0,
              mt: 1,
            }}
          >
            <FormGroup>
              {reasonsText.map((reason) => (
                <FormControlLabel
                  key={reason.value}
                  control={
                    <Checkbox
                      checked={flagReasons.includes(reason.value)}
                      onChange={(e) => {
                        if (e.target.checked) {
                          setFlagReasons([...flagReasons, reason.value]);
                        } else {
                          setFlagReasons(
                            flagReasons.filter((r) => r !== reason.value),
                          );
                        }
                      }}
                      size="small"
                    />
                  }
                  label={
                    <Typography variant="body2">{reason.label}</Typography>
                  }
                />
              ))}
            </FormGroup>
          </FormControl>
          <TextField
            fullWidth
            margin="normal"
            label="Additional Information"
            multiline
            rows={4}
            value={additionalInfo}
            onChange={(e) => setAdditionalInfo(e.target.value)}
            required={flagReasons.includes("other")}
          />
          <Typography mt={1} color={theme.palette.grey[600]}>
            We will review your report and get in touch if necessary.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            onClick={handleSubmit}
            variant="contained"
            color="primary"
            disabled={
              flagReasons.length === 0 ||
              (flagReasons.includes("other") && additionalInfo.trim() === "")
            }
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openLoginDialog}
        onClose={handleLoginDialogClose}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle variant="h3" align="center" mt={4}>
          Join Us to Continue
        </DialogTitle>
        <DialogContent>
          <DialogContentText variant="body1" align="center">
            To unlock all features and save your progress, please log in or
            create an account. It only takes a moment!
          </DialogContentText>
        </DialogContent>
        <DialogActions
          sx={{
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            px: 3,
            pb: 3,
          }}
        >
          <Stack direction="row" spacing={2} sx={{ mb: 2, width: "100%" }}>
            <Button
              onClick={() => {
                navigate("/login");
              }}
              color="secondary"
              sx={{
                "&:hover": {
                  filter: "brightness(0.9)",
                  transition: "all 0.2s ease",
                  backgroundColor: theme.palette.secondary.light,
                },
              }}
              variant="contained"
              size="large"
              fullWidth
            >
              Log In
            </Button>
            <Button
              onClick={() => {
                navigate("/signup");
              }}
              variant="contained"
              size="large"
              fullWidth
              sx={{
                backgroundColor: theme.palette.primary.light,
                "&:hover": {
                  filter: "brightness(0.9)",
                  transition: "all 0.2s ease",
                  backgroundColor: theme.palette.primary.light,
                },
              }}
            >
              Sign Up
            </Button>
          </Stack>
          <Button
            onClick={handleLoginDialogClose}
            color="inherit"
            sx={{ mt: 1, width: "fit-content" }}
          >
            Maybe Later
          </Button>
        </DialogActions>
      </Dialog>
    </Stack>
  );
};

export default SearchResult;
