import { useEffect, useRef, useState } from "react";
import Box from "@mui/material/Box";
import {
  Typography,
  Grid,
  useMediaQuery,
  useTheme,
  Link,
  Stack,
  TextField,
  IconButton,
  styled,
  Input,
} from "@mui/material";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";

import color_theme from "../components/Theme";
import Separator from "../components/Separator";
import Button from "../components/button";
import CustomSection from "../components/custom-section";
import MetaDataHeader from "../components/MetaDataHeader";
import { createSearchParams, useNavigate } from "react-router-dom";
import { DEFAULT_DISTANCE } from "../components/filter";
import Autocomplete from "react-google-autocomplete";
import { SearchBar } from "../components/SearchBar";

function Home() {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "instant" as ScrollBehavior });
  }, []);

  return (
    <div className="Home">
      <MetaDataHeader />
      <HomeSearch />
      <Box sx={{ height: { xs: "10vh", backgroundColor: "white" } }} />
      <CustomSection bgStyle="white" paddingY="none" marginStyle="normal">
        <Typography
          variant="h2"
          color={color_theme.primaryFontColor}
          align="center"
        >
          We're making it easier for people and organizations to DO GOOD.
        </Typography>
        <Separator primary={true} />
      </CustomSection>
      <OtherStuff primary={false} />
      <Box sx={{ height: { xs: "10vh", backgroundColor: "white" } }} />
    </div>
  );
}

const ProductHeroLayoutRoot = styled("section")(({ theme }) => ({
  backgroundColor: color_theme.primaryBgColor,
  color: color_theme.primaryFontColor,
  position: "relative",
  display: "flex",
  alignItems: "center",
  [theme.breakpoints.up("sm")]: {
    height: "calc(100vh - 300px)",
    minHeight: 500,
    maxHeight: 1300,
  },
}));

const HomeSearch = () => {
  const targetRef = useRef<HTMLDivElement | null>(null);
  const handleScrollToElement = () => {
    // Scroll to the target element
    if (targetRef.current) {
      targetRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  return (
    <>
      <ProductHeroLayoutRoot>
        <Grid
          container
          spacing={1}
          paddingTop={"35px"}
          justifyContent="center"
          alignItems="center"
          sx={{ height: "100%" }}
        >
          <Grid item xs={12} textAlign={"center"}>
            <Box sx={{ height: 35 }}></Box>
          </Grid>
          <Grid
            item
            xs={10}
            sm={10}
            md={11}
            lg={11}
            xl={11}
            textAlign={"center"}
          >
            <Typography variant="h2" color="secondary.main" gutterBottom>
              The web’s first search engine to
            </Typography>
          </Grid>
          <Grid
            item
            xs={10}
            sm={10}
            md={11}
            lg={11}
            xl={11}
            textAlign={"center"}
          >
            <Typography variant="subtitle2" component="h1" gutterBottom>
              DISCOVER VOLUNTEER OPPORTUNITIES
            </Typography>
          </Grid>
          <SearchBar />
          <Grid item xs={12} textAlign={"center"}>
            <Box sx={{ height: 35 }}></Box>
          </Grid>
          <Grid item xs={12} textAlign={"center"}>
            <IconButton
              aria-label="delete"
              size="large"
              onClick={handleScrollToElement}
            >
              <ArrowDownwardIcon fontSize="inherit" style={{}} />
            </IconButton>
          </Grid>
          <Grid item xs={12} textAlign={"center"}>
            <Box sx={{ height: 35 }}></Box>
          </Grid>
        </Grid>
      </ProductHeroLayoutRoot>
      <div ref={targetRef}></div>
    </>
  );
};

function OtherStuff({ primary }: { primary: boolean }) {
  const fontColor = primary
    ? color_theme.primaryFontColor
    : color_theme.fontColor;
  const theme1 = useTheme();
  const isXs = useMediaQuery(theme1.breakpoints.only("xs"));
  const isSm = useMediaQuery(theme1.breakpoints.only("sm"));

  const imageWidth = isSm || isXs ? "90vw" : "45vw";

  return (
    <>
      <CustomSection bgStyle="white" paddingY="small" marginStyle="wide">
        <Grid container alignItems="center">
          <Grid item xs={12} sm={6} justifyContent={"left"}>
            <Typography
              variant="h2"
              style={{ lineHeight: 1.5 }}
              align="left"
              color={fontColor}
            >
              Harnessing the latest technology…
            </Typography>
            <Typography
              variant="h3"
              style={{ lineHeight: 1.5 }}
              align="left"
              gutterBottom
              color="text.secondary"
            >
              to connect individuals with meaningful and impactful service
              activities.
            </Typography>
          </Grid>
          <Grid item xs={0} sm={1}></Grid>
          <Grid item xs={0} sm={5}>
            <img
              src="/images/home-page/harnessing-technology.png"
              alt=""
              style={{ maxWidth: imageWidth, alignSelf: "center" }}
              width={"100%"}
            />
          </Grid>
        </Grid>
        <Grid container alignItems="center">
          {isXs ? (
            <>
              <Grid item xs={12} sm={6} justifyContent={"left"}>
                <Typography
                  variant="h2"
                  style={{ lineHeight: 1.5 }}
                  align="left"
                  color={fontColor}
                >
                  Saving time and money for nonprofits…
                </Typography>
                <Typography
                  variant="h3"
                  style={{ lineHeight: 1.5 }}
                  align="left"
                  gutterBottom
                  color="text.secondary"
                >
                  so they no longer have to post volunteer activities on other
                  websites.
                </Typography>
              </Grid>
              <Grid item xs={0} sm={1}></Grid>
              <Grid item xs={0} sm={5}>
                <img
                  src="/images/home-page/saving.png"
                  alt=""
                  style={{ maxWidth: imageWidth, alignSelf: "center" }}
                  width={"100%"}
                />
              </Grid>
            </>
          ) : (
            <>
              <Grid item xs={0} sm={5}>
                <img
                  src="/images/home-page/saving.png"
                  alt=""
                  style={{ maxWidth: imageWidth, alignSelf: "center" }}
                  width={"100%"}
                />
              </Grid>
              <Grid item xs={0} sm={1}></Grid>
              <Grid item xs={12} sm={6} justifyContent={"left"}>
                <Typography
                  variant="h2"
                  style={{ lineHeight: 1.5 }}
                  align="left"
                  color={fontColor}
                >
                  Saving time and money for nonprofits…
                </Typography>
                <Typography
                  variant="h3"
                  style={{ lineHeight: 1.5 }}
                  align="left"
                  gutterBottom
                  color="text.secondary"
                >
                  by removing the need to post on other websites.
                </Typography>
              </Grid>
            </>
          )}
        </Grid>
        <Grid container alignItems="center">
          <Grid item xs={12} sm={6} justifyContent={"right"}>
            <Typography
              variant="h2"
              style={{ lineHeight: 1.5 }}
              align="left"
              color={fontColor}
            >
              Building community for volunteers…
            </Typography>
            <Typography
              variant="h3"
              style={{ lineHeight: 1.5 }}
              align="left"
              gutterBottom
              color="text.secondary"
            >
              rooted in kindness and collaboration, both on-line and off.
            </Typography>
            {!isXs ? (
              <>
                <Box sx={{ height: "2rem" }}></Box>
                <Stack alignItems={"start"}>
                  <Link href="/about">
                    <Button
                      size="medium"
                      bgStyle="secondary"
                      hoverStyle="light"
                    >
                      <Typography fontWeight={"bold"}>Learn More</Typography>
                    </Button>
                  </Link>
                </Stack>
              </>
            ) : (
              <></>
            )}
          </Grid>
          <Grid item xs={0} sm={1}></Grid>
          <Grid item xs={0} sm={5}>
            <img
              src="/images/home-page/saving-time.png"
              alt=""
              style={{ maxWidth: imageWidth, alignSelf: "center" }}
              width={"100%"}
            />
          </Grid>
        </Grid>
        {isXs ? (
          <>
            <Box sx={{ height: "2rem" }}></Box>
            <Link href="/about">
              <Button size="medium" bgStyle="secondary" hoverStyle="light">
                <Typography fontWeight={"bold"}>Learn More</Typography>
              </Button>
            </Link>
          </>
        ) : (
          <></>
        )}
      </CustomSection>
    </>
  );
}

export default Home;
