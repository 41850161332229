import React from 'react';
import { Helmet } from 'react-helmet';

interface MetaDataHeaderProps {
  title?: string;
  description?: string;
  image?: string;
  author?: string;
  keywords?: string;
}

const defaultImage = `${process.env.PUBLIC_URL}/images/dog_silhouette.png`;

const MetaDataHeader: React.FC<MetaDataHeaderProps> = ({ title = "Samaritan Scout", description = "The web’s first search engine focused exclusively on finding ways we can help nonprofit organizations we care about", image = defaultImage, author = "Samaritan Scout", keywords = "Samaritans, Volunteer, Opportunities" }) => {
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta property="og:image" content={image} />
      {/* Additional meta tags */}
      <meta charSet="utf-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <meta name="author" content={author} />
      <meta name="keywords" content= {keywords} />
      {/* May need to change this in the future */}
      <meta name="robots" content="index, follow" />
      <meta name="theme-color" content="#000000" />
      <link rel="manifest" href={`${process.env.PUBLIC_URL}/manifest.json`} />
      {/* Open Graph Protocol (OGP) for social media */}
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:url" content="https://www.samaritanscout.org" />
      {/* Optimize Twitter Cards down the line */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={image} />
      <link rel="preconnect" href="https://fonts.googleapis.com" />
      <link rel="preconnect" href="https://fonts.gstatic.com" />
      <link href="https://fonts.googleapis.com/css2?family=Lexend:wght@100..900&display=swap" rel="stylesheet" />
    </Helmet>
  );
};

export default MetaDataHeader;
